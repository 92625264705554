import { useEffect } from 'react';
import {
  deleteOMSFilter,
  getOrders,
  OmsFilterNames,
  setOrders,
  setOrdersPagination,
  setOrdersSearch,
  updateOMSCreatedOnFilters,
  updateOMSFilters,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useDebounce } from '@point-of-sale/hooks';
import { IOption } from '@point-of-sale/components';
import { FulfillmentModeSchema } from '@point-of-sale/schemas';
import Discovery, { IFilter } from '../../components/Discovery';
import SelectedFilters from './SelectedFilters';
import { IDateRange } from '@point-of-sale/types';

const OrderDiscovery = () => {
  const search = useAppSelector(store => store.oms.search);
  const filters = useAppSelector(store => store.oms.filters);
  const createdOn = useAppSelector(store => store.oms.createdOn);

  const selectedSalesChannelId = useAppSelector(store => store.identity.selectedSalesChannel?.id);
  const salesChannels = useAppSelector(store => store.common.salesChannels);

  const dispatch = useAppDispatch();

  function onChangeFilter(field: OmsFilterNames, options: Array<IOption> | IDateRange) {
    dispatch(
      setOrdersPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    if (field === 'createdOn') {
      const selectedRange = options as IDateRange;
      dispatch(updateOMSCreatedOnFilters(selectedRange));
      // dispatch(
      //   updateOMSFilters({
      //     filter: {
      //       field,
      //       value: selectedRange.to?.getTime() ?? 0,
      //       filterType: 'LTE',
      //     },
      //     name: field,
      //   })
      // );
      return;
    }

    dispatch(
      updateOMSFilters({
        filter: {
          field,
          value: (options as Array<IOption>).map(option => option.value).join(','),
          filterType: field === 'salesChannelId' ? 'IN' : 'IN',
        },
        name: field,
      })
    );
  }

  function onSearchChange(searchQuery: string) {
    dispatch(
      setOrdersPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    dispatch(
      setOrders({
        data: {
          ids: [],
          records: {},
        },
      })
    );

    dispatch(
      setOrdersSearch({
        query: searchQuery,
      })
    );

    dispatch(getOrders());
  }

  const debouncedApiCall = useDebounce(() => dispatch(getOrders()), 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [filters, createdOn, debouncedApiCall]);

  useEffect(() => {
    if (selectedSalesChannelId) {
      dispatch(
        updateOMSFilters({
          filter: {
            field: 'salesChannelId',
            value: selectedSalesChannelId,
            filterType: 'EQ',
          },
          name: 'salesChannelId',
        })
      );
    }
  }, [selectedSalesChannelId]);

  const discoveryFilters: Array<IFilter> = [
    {
      name: 'salesChannelId',
      placeholder: 'Sales Channel',
      options: salesChannels.data.ids.map(id => ({
        label: salesChannels.data.records[id].name,
        value: id,
      })),
      values: String(filters.salesChannelId?.value).split(',').map(Number) ?? [],
      type: 'MULTISELECT',
    },
    {
      name: 'lineItems.fulfillmentMode',
      placeholder: 'Fulfillment Mode',
      options: Object.values(FulfillmentModeSchema.Enum).map(value => ({
        label: value,
        value,
      })),
      values: String(filters['lineItems.fulfillmentMode']?.value).split(',') ?? [],
      type: 'MULTISELECT',
    },
    {
      name: 'createdOn',
      placeholder: 'Date',
      options: [],
      values: {
        from: createdOn.from,
        to: createdOn.to,
      },
      type: 'DATE_RANGE',
    },
  ];

  return (
    <Discovery
      filters={discoveryFilters}
      // onFilterChange={emptyFunction}
      placeholder="Search by Customer Name, Customer Phone, Order Number"
      // onSearchChange={onSearchChange}
      onFilterChange={onChangeFilter}
      onFilterClear={filterName => dispatch(deleteOMSFilter(filterName as OmsFilterNames))}
      onPressEnter={onSearchChange}
      searchQuery={search.query}
      renderSelectedFilters={() => <SelectedFilters onChangeFilter={onChangeFilter} />}
    />
  );
};

export default OrderDiscovery;
