import { ForwardedRef, forwardRef, Fragment, InputHTMLAttributes } from 'react';
import Text from '../Typography/Text';
import { InputSizeVariantType, InputVariantType } from './types';
import { IconographyNameType } from '../icons/types';
import iconMap from '../icons/iconMap';
import { FONT_SIZES, FONT_WEIGHTS, ICON_DIMENSIONS } from './constants';
import * as Styles from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  rightLabel?: string;
  leftIcon?: IconographyNameType;
  sizeVariant?: InputSizeVariantType;
  variant?: InputVariantType;
  isFullWidth?: boolean;
}

const Input = forwardRef(
  (
    {
      label,
      rightLabel,
      leftIcon,
      value,
      placeholder,
      variant = 'solid',
      sizeVariant = 'medium',
      isFullWidth = false,
      className = '',
      onChange,
      ...inputProps
    }: IInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const LeftIcon = leftIcon ? iconMap[leftIcon] : null;

    const Top = label || rightLabel ? Styles.Top : Fragment;

    return (
      <Styles.Wrapper
        ref={ref}
        $isFullWidth={isFullWidth}
        className={className}
        $sizeVariant={sizeVariant}
      >
        <Top>
          {label && (
            <Text
              as="label"
              weight={FONT_WEIGHTS[sizeVariant]}
              fontSize={FONT_SIZES[sizeVariant]}
              width="100%"
              color="var(--dove-gray)"
            >
              {label}
            </Text>
          )}
          {rightLabel && (
            <Text
              as="label"
              textAlign="right"
              display="inline-block"
              width="100%"
              weight={FONT_WEIGHTS[sizeVariant]}
              fontSize={FONT_SIZES[sizeVariant]}
              color="var(--dove-gray)"
            >
              {rightLabel}
            </Text>
          )}
        </Top>
        <Styles.InputWrapper $variant={variant}>
          {LeftIcon && (
            <Styles.IconWrapper $sizeVariant={sizeVariant}>
              <LeftIcon size={ICON_DIMENSIONS[sizeVariant]} />
            </Styles.IconWrapper>
          )}
          <Styles.Input
            value={value}
            placeholder={placeholder}
            $sizeVariant={sizeVariant}
            onChange={onChange}
            {...inputProps}
            {...(inputProps.type === 'number'
              ? {
                  onKeyPress: e => {
                    // if (e.key === 'e' || e.key === '-' || e.key === '+') {
                    //   e.preventDefault();
                    // }
                  },
                  onWheel: e => {
                    e.target?.blur();
                  },
                }
              : {})}
          />
        </Styles.InputWrapper>
      </Styles.Wrapper>
    );
  }
);

export default Input;
