import ProductDiscovery from '../../components/ProductDiscovery';
import ProductList from '../../components/ProductList';
import Cart from '../../components/Cart';
import useCartParams from '../../hooks/useCartParams';
import * as Styles from './styles';

const PointOfSale = () => {
  useCartParams();

  return (
    <Styles.Wrapper>
      <ProductDiscovery usage="POS" />
      <Styles.Content>
        <Styles.Left>
          <ProductList />
        </Styles.Left>
        <Styles.Right>
          <Cart usage="ADD_PRODUCTS" />
        </Styles.Right>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default PointOfSale;
