import { ThemeType } from '@point-of-sale/components';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $width: string }>`
  border: 1px solid var(--athens-gray);
  height: 30px;
  min-width: ${props => props.$width};
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const InputWrapper = styled.div`
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 4px;

  input {
    border: none;
    outline: none;
    width: 100%;

    &:disabled {
      background: var(--white);
    }
  }
`;

export const PopupWrapper = styled(motion.div)<{ $isLoading: boolean }>`
  background: var(--white);
  min-width: 20vw;
  border-radius: 1px;
  box-shadow: 0px 24px 34px 0px #00000040;
  padding: 8px 16px;
  overflow: auto;
  z-index: 2;

  ${props =>
    props.$isLoading &&
    css`
      cursor: progress;

      tr {
        cursor: progress !important;
      }
    `}
`;

export const SourcingOption = styled.div`
  padding: 10px 0;
  border-bottom: 0.5px solid var(--alto);
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tr = styled.tr<{ $isSelected: boolean; theme: ThemeType }>`
  cursor: pointer;

  &:hover {
    td {
      font-weight: ${props => props.theme.typography.fonts.weight.bold};
    }
  }

  ${props =>
    props.$isSelected &&
    css`
      td {
        font-weight: ${props.theme.typography.fonts.weight.bold};
      }
    `}

  :nth-child(2) {
    ${props =>
      props.$isSelected &&
      css`
        color: var(--royal-blue);
      `}
  }
`;

export const Td = styled.td<{ theme: ThemeType }>`
  padding: 8px;
  vertical-align: middle;
  border-bottom: 1px solid var(--alto);
  font-size: ${props => props.theme.typography.fonts.size[12]};
  color: var(--dove-gray);
  font-weight: ${props => props.theme.typography.fonts.weight.regular};
`;
