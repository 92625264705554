import { IconPropsType } from '../types';

const CrossIcon = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  strokeWidth = 1.5,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.334 9.334 13.333 13.333m-13.333 0L22.667 9.334"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CrossIcon;
