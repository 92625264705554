import { IconPropsType } from '@point-of-sale/components';
import { FlavorType } from '../types';

interface EmptyPaperBagProps extends IconPropsType {
  flavor: FlavorType;
}

const EmptyPaperBag = ({ flavor, ...props }: EmptyPaperBagProps) => (
  <svg
    width={169}
    height={182}
    viewBox="0 0 169 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={84.086} cy={83.386} rx={68.797} ry={67.595} fill="#F1F3FF" />
    <ellipse cx={19.601} cy={37.746} rx={4.312} ry={4.237} fill="#F1F3FF" />
    <ellipse cx={107.999} cy={7.896} rx={8.036} ry={7.896} fill="#F1F3FF" />
    <ellipse cx={26.069} cy={137.309} rx={7.644} ry={7.511} fill="#F1F3FF" />
    <ellipse cx={157.783} cy={119.591} rx={7.644} ry={7.511} fill="#F1F3FF" />
    <ellipse cx={142.887} cy={128.065} rx={3.724} ry={3.659} fill="#F1F3FF" />
    <path
      d="M88.403 19.161c-.311-.065-.311-.51 0-.575l3-.627a.29.29 0 0 0 .226-.224l.652-2.953c.068-.307.506-.307.574 0l.651 2.953c.025.113.114.2.228.224l2.998.627c.312.065.312.51 0 .575l-2.998.627a.29.29 0 0 0-.227.224l-.652 2.953c-.068.307-.506.307-.574 0l-.652-2.953a.29.29 0 0 0-.227-.224zM9.356 88.706c.121-.564.925-.564 1.047 0l1.15 5.337a.54.54 0 0 0 .411.411l5.347 1.145c.565.12.565.926 0 1.047l-5.347 1.145a.54.54 0 0 0-.411.41l-1.15 5.338c-.122.564-.926.564-1.047 0l-1.15-5.337a.54.54 0 0 0-.412-.411l-5.347-1.145c-.565-.12-.565-.926 0-1.047l5.347-1.145a.54.54 0 0 0 .412-.41zm136.074-48.53c.122-.563.926-.563 1.047 0l.604 2.804a.54.54 0 0 0 .412.41l2.805.601c.565.121.565.927 0 1.048l-2.805.6a.54.54 0 0 0-.412.41l-.604 2.804c-.121.564-.925.564-1.047 0l-.604-2.803a.54.54 0 0 0-.411-.411l-2.805-.6c-.565-.121-.565-.927 0-1.048l2.805-.6a.54.54 0 0 0 .411-.411z"
      fill="#C1C7FF"
    />
    <path
      d="m161.374 96.416 1.2 5.572 5.591 1.197-5.591 1.196-1.2 5.573-1.201-5.573-5.59-1.196 5.59-1.197z"
      fill="#fff"
    />
    <path
      d="m123.091 52.768-92.71 34.856 19.796 54.115 11.957 5.199 14.896 3.082 15.092-.578 8.82-1.541 7.841-2.311 12.936-6.547 10.976-8.474.867-1.101a133 133 0 0 0 12.657-19.312z"
      fill="url(#a)"
    />
    <path
      d="m37.437 72.217-9.8-5.392 85.065-31.583-4.508 10.977 3.332 10.207-70.365 26.383z"
      fill="#DDE2FF"
    />
    <path
      d="M49.785 141.546a82.6 82.6 0 0 0 27.402 8.216l.267.032h6.832c5.1 0 10.187-.534 15.175-1.592l1.727-.366a60 60 0 0 0 15.279-5.484 59.8 59.8 0 0 0 10.59-7.043l1.052-.875a66 66 0 0 0 7.808-7.672 65.6 65.6 0 0 0 8.582-12.71l2.112-4.089M29.628 86.82l20.549 55.098m73.73-89.184 22.543 57.873M26.1 66.986l87.808-32.418M29.174 87.813l93.497-35.427-13.497-6.159a1.07 1.07 0 0 1-.527-1.44l4.664-9.589M26.888 66.72l9.615 5.064c.523.276.728.916.46 1.436l-7.334 14.179m-3.136-21.377 6.368 15.64m4.379-8.867 3.92 10.784m67.426-37.745 3.921 11.17m.873-21.627 5.626 15.238"
      stroke="#C1C7FF"
      strokeWidth={2.142}
    />
    <ellipse cx={66.446} cy={82.616} rx={4.116} ry={4.044} fill="#DDE2FF" />
    <ellipse cx={60.565} cy={61.818} rx={2.94} ry={2.889} fill="#F1F3FF" />
    <ellipse cx={92.318} cy={72.987} rx={4.116} ry={4.044} fill="#DDE2FF" />
    <ellipse cx={78.598} cy={55.27} rx={2.94} ry={2.889} fill="#F1F3FF" />
    <defs>
      <linearGradient
        id="a"
        x1={71.738}
        y1={52.768}
        x2={102.876}
        y2={148.038}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1F3FF" />
        <stop offset={1} stopColor="#DDE2FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default EmptyPaperBag;
