import { useFetchOnListEnd } from '@point-of-sale/hooks';
import { searchProducts, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import TableRow from './TableRow';
import { NoResults } from '@runway/illustrations';
import { SpinnerIcon } from '@point-of-sale/components';
import { LoaderWrapper } from '../ProductList/styles';
import * as TableStyles from '../Table/styles';
import { useMemo } from 'react';

const ProductsTable = () => {
  const isLoading = useAppSelector(store => store.pointOfSale.products.isLoading);
  const ids = useAppSelector(store => store.pointOfSale.products.data.ids);

  const isFiltersApplied = useAppSelector(
    store =>
      Object.keys(store.pointOfSale.filters).length > 0 || store.pointOfSale.search.query.length > 0
  );

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  const renderedItemIds = useMemo(() => (isFiltersApplied ? ids : []), [isFiltersApplied, ids]);

  return (
    <TableStyles.Wrapper
      style={{
        width: '100%',
      }}
    >
      <TableStyles.Table
        style={{
          width: '100%',
        }}
      >
        <TableStyles.Thead>
          <TableStyles.TableHeadingRow>
            <TableStyles.TableHeader>Images</TableStyles.TableHeader>
            <TableStyles.TableHeader>Product</TableStyles.TableHeader>
            <TableStyles.TableHeader>MRP</TableStyles.TableHeader>
            <TableStyles.TableHeader>Pack Contains</TableStyles.TableHeader>
            <TableStyles.TableHeader>Category</TableStyles.TableHeader>
            <TableStyles.TableHeader>MTO</TableStyles.TableHeader>
            <TableStyles.TableHeader>MTC</TableStyles.TableHeader>
            <TableStyles.TableHeader>Colors</TableStyles.TableHeader>
            <TableStyles.TableHeader>Sizes</TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
          </TableStyles.TableHeadingRow>
        </TableStyles.Thead>
        <tbody>
          {renderedItemIds.map((id, index) => {
            return (
              <TableRow
                key={id}
                id={id}
                lastItemRef={index === ids.length - 3 ? lastItemRef : undefined}
              />
            );
          })}
        </tbody>
      </TableStyles.Table>
      {!isLoading && renderedItemIds.length === 0 && <NoResults />}
      {isLoading && renderedItemIds.length === 0 && (
        <LoaderWrapper>
          <SpinnerIcon size={48} />
        </LoaderWrapper>
      )}
    </TableStyles.Wrapper>
  );
};

export default ProductsTable;
