import styled from 'styled-components';
import { LineItemDTOType } from '@point-of-sale/schemas';
import { removeUnderscoresAndCapitalize } from '@point-of-sale/utils';

export const StyledTable = styled.table`
  width: 100%;
  /* table-layout: fixed; */
`;

interface ThProps {
  $textAlign?: 'left' | 'right';
}

export const Th = styled.th<ThProps>`
  font-size: 12px;
  font-weight: 700;
  padding: 2px 0;
  line-height: 16px;
  padding: 4px 0;
  border-bottom: 1px dashed var(--dusty-gray);

  text-align: ${props => props.$textAlign ?? 'left'};
`;

Th.defaultProps = {
  $textAlign: 'left',
};

export const Td = styled.td`
  font-size: 10px;
  line-height: 16px;

  word-wrap: break-word;
  white-space: normal;

  padding: 4px 4px 8px 0;
`;

export const Tr = styled.tr``;

interface ITableProps {
  data: Array<LineItemDTOType>;
  currency: string;
}

const ReturnedLineItems = ({ data, currency }: ITableProps) => {
  return (
    <StyledTable>
      <thead>
        <Th>Item</Th>
        <Th>Refund Mode</Th>
        <Th $textAlign="right">Amount</Th>
      </thead>
      <tbody>
        {data.map(item => (
          <Tr key={item.id}>
            <Td width="40%">{item.productName}</Td>
            <Td width="30%">
              {item.refundRequest?.refundMode
                ? removeUnderscoresAndCapitalize(item.refundRequest?.refundMode)
                : ''}
            </Td>
            <Td width="30%" align="right">
              {currency} {item.refundRequest?.refundAmount}
            </Td>
          </Tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default ReturnedLineItems;
