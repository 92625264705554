import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { getCartById, useAppDispatch, useAppSelector } from '@point-of-sale/store';

const useCartParams = () => {
  const [searchParams] = useSearchParams();
  const cartIdFromParams = searchParams.get('id');
  const cartIdFromStore = useAppSelector(state => state.pointOfSale.cart.data.id);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cartIdFromParams) {
      navigate('/point-of-sale/add-customer');
    }

    if (cartIdFromParams && cartIdFromStore === -1) {
      dispatch(getCartById(cartIdFromParams));
    }
  }, [cartIdFromParams, cartIdFromStore]);
};

export default useCartParams;
