import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 32px;
`;

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleLeftBottom = styled.div`
  display: flex;
  gap: 4px;

  span {
    width: unset;
  }
`;

export const TitleRight = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
`;

export const TitleRightTop = styled.div`
  display: flex;
  gap: 24px;
`;

export const TitleRightBottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PriceAndActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;
