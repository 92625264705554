import { useEffect, useState } from 'react';
import { useBoolean } from '@point-of-sale/hooks';
import type { IProductListItem } from '@point-of-sale/store';
import {
  getInventoryStatus,
  getWarehouseAndStoreInventoryOfVariantByCurrentStore,
  offlineFacilitiesSelector,
  useAppSelector,
} from '@point-of-sale/store';
import { IMannerOfPurchase, ProductContext } from './context/ProductContext';
import Inventory from './Inventory';
import ProductImage from './ProductImage';
import VariantSelection from './VariantSelection';
import Nomenclature from './Nomenclature';
import Footer from './Footer';
import { formatToReadableDate } from '@point-of-sale/utils';
import useGetServiceability from './useGetServiceability';
import * as Styles from './styles';

interface IProductProps {
  data: IProductListItem;
  componentRef?: (node: HTMLElement | null) => void;
}

const Product = ({ data, componentRef }: IProductProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const isSearchBarcodish = useAppSelector(store => store.pointOfSale.isBarcodish);

  const cartItemEditCache = useAppSelector(store => store.pointOfSale.cartItemEditCache);
  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);
  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);
  // const offlineFacilities = useAppSelector(offlineFacilitiesSelector);
  // const facilityRecords = offlineFacilities.data.records;
  const cartItem = useAppSelector(
    store => store.pointOfSale.cart.data.cartItems.records[cartItemEditCache.id]
  );
  const customerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const [isExpanded, expandActions] = useBoolean();

  const [selectedVariant, setSelectedVariant] = useState<
    IProductProps['data']['variants'][number] | null
  >(null);

  const [quantity, setQuantity] = useState<number>(1);

  const [fulfillmentFacilityId, setFulfillmentFacilityId] = useState<number | null>(null);

  const [mannerOfPurchase, setMannerOfPurchase] = useState<IMannerOfPurchase>({
    shouldOverride: false,
    deliveryMode: 'STORE_PURCHASE',
    fulfillmentMode: 'ONHAND',
  });

  const [demandedPincode, setDemandedPincode] = useState('');

  function onChangeDemandedPincode(newPincode: string) {
    setDemandedPincode(newPincode);
  }

  useEffect(() => {
    if (!data.selectedSize || !data.selectedColor) {
      setSelectedVariant(null);
      return;
    }

    const variant =
      data.variants.find(
        variant => variant.size === data.selectedSize && variant.color === data.selectedColor
      ) ?? null;

    setSelectedVariant(variant);
  }, [data.selectedColor, data.selectedSize, data.variants]);

  useEffect(() => {
    if (selectedSalesChannel && selectedVariant) {
      const { storeInventory, storeFacilityId, combinedWarehouseInventory, warehouseFacilityIds } =
        getWarehouseAndStoreInventoryOfVariantByCurrentStore(selectedVariant);

      let determinedFulfillmentFacilityId;

      if (storeInventory > 0) {
        determinedFulfillmentFacilityId = storeFacilityId;
      } else if (combinedWarehouseInventory > 0 && warehouseFacilityIds.length > 0) {
        determinedFulfillmentFacilityId = warehouseFacilityIds[0];
      }

      setFulfillmentFacilityId(
        Number(determinedFulfillmentFacilityId ?? selectedSalesChannel.facilityId)
      );
      const selectedFacility =
        facilityRecords[determinedFulfillmentFacilityId ?? selectedSalesChannel.facilityId];

      setMannerOfPurchase(prev => ({
        ...prev,
        fulfillmentFacility: {
          name: selectedFacility.name,
          city: selectedFacility.address.cityDisplayName ?? '',
          pincode: selectedFacility.address.pincode ?? '',
        },
      }));
    }
  }, [selectedSalesChannel, facilityRecords, selectedVariant]);

  useEffect(() => {
    if (mode === 'MADE_TO_ORDER') {
      setMannerOfPurchase(prev => ({
        ...prev,
        shouldOverride: true,
        deliveryMode: 'STORE_PICKUP',
        fulfillmentMode: 'MTO',
      }));
    } else {
      setMannerOfPurchase(prev => ({
        ...prev,
        shouldOverride: false,
        deliveryMode: 'STORE_PURCHASE',
        fulfillmentMode: 'ONHAND',
        estimatedDeliveryDate: formatToReadableDate(new Date()),
      }));
    }
  }, [mode]);

  // TODO: test if the flow works without this
  // useEffect(() => {
  //   if (data.selectedSize === CUSTOM_TAILORED.key) {
  //     setMannerOfPurchase(prev => ({
  //       ...prev,
  //       shouldOverride: true,
  //       deliveryMode: 'STORE_PICKUP',
  //       fulfillmentMode: 'MTC',
  //     }));
  //   } else {
  //     setMannerOfPurchase(prev => ({
  //       ...prev,
  //       shouldOverride: true,
  //       deliveryMode:
  //         mode === 'MADE_TO_ORDER' ? 'HOME_DELIVERY' : 'STORE_PURCHASE',
  //       fulfillmentMode: mode === 'MADE_TO_ORDER' ? 'MTO' : 'ONHAND',
  //     }));
  //   }
  // }, [data.selectedSize]);

  useEffect(() => {
    if (cartItemEditCache.isPopulated) {
      expandActions.on();

      if (cartItemEditCache.id && cartItem) {
        // setSelectedVariant(cartItem.variant);
        setQuantity(cartItem.quantity);
        setFulfillmentFacilityId(cartItem.fulfillmentFacilityId);
      }
    }
  }, [cartItemEditCache]);

  useEffect(() => {
    if (isSearchBarcodish) {
      expandActions.on();
    }
  }, [isSearchBarcodish]);

  const { fetchServiceabilityData, serviceabilityData, isFetchingServiceability } =
    useGetServiceability();

  useEffect(() => {
    if (!selectedVariant) {
      return;
    }

    const { hasInventoryInCurrentStoreOrWarehouse, hasInventoryInOtherStoreOrWarehouse } =
      getInventoryStatus(selectedVariant);

    if (!hasInventoryInCurrentStoreOrWarehouse && !hasInventoryInOtherStoreOrWarehouse) {
      return;
    }

    const doesAnyVariantHaveInventory = data.variants
      .map(variant => {
        const { hasInventoryInOtherStoreOrWarehouse } = getInventoryStatus(variant);

        return hasInventoryInOtherStoreOrWarehouse;
      })
      .some(item => item);

    if (isExpanded || doesAnyVariantHaveInventory) {
      const skuCodes = data.variants.map(variant => variant.skuCode);
      const destinationPincodes = Object.values(facilityRecords)
        .filter(item => !item.isOnline)
        .filter(item => item.facilityType === 'STORE')
        .map(item => item.address.pincode)
        .filter(item => item !== null);

      if (customerAddress) {
        destinationPincodes.push(customerAddress.pincode);
      } else if (demandedPincode) {
        destinationPincodes.push(demandedPincode);
      }

      fetchServiceabilityData(
        skuCodes,
        // @ts-expect-error destinationPincodes will not have undefined
        Array.from(new Set(destinationPincodes)),
        Object.values(facilityRecords)
      );
    }
  }, [isExpanded, customerAddress, facilityRecords, selectedVariant, demandedPincode]);

  return (
    <ProductContext.Provider
      value={{
        data,
        isExpanded,
        selectedVariant,
        quantity,
        setQuantity,
        fulfillmentFacilityId,
        setFulfillmentFacilityId,
        mannerOfPurchase,
        setMannerOfPurchase,
        expandActions,
      }}
    >
      <Styles.Wrapper animate={isExpanded ? 'expanded' : 'collapsed'} ref={componentRef}>
        <Styles.Left>
          <ProductImage />
        </Styles.Left>
        <Styles.Right>
          <Nomenclature
            serviceabilityData={serviceabilityData}
            isFetchingServiceability={isFetchingServiceability}
          />
          <VariantSelection />
          <Inventory
            serviceabilityData={serviceabilityData}
            demandedPincode={demandedPincode}
            onChangeDemandedPincode={onChangeDemandedPincode}
          />

          <Footer
            serviceabilityData={serviceabilityData}
            isFetchingServiceability={isFetchingServiceability}
          />
        </Styles.Right>
      </Styles.Wrapper>
    </ProductContext.Provider>
  );
};

export default Product;
