import { fromUnixTime } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import {
  deleteOMSCreatedOnFilters,
  OmsFilterNames,
  setOrdersPagination,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { IconButton, IOption, Text } from '@point-of-sale/components';
import { IFilter } from '@point-of-sale/services';
import * as Styles from './styles';
import { formatToReadableDate } from '@point-of-sale/utils';

interface ISelectedFiltersProps {
  onChangeFilter: (field: OmsFilterNames, options: Array<IOption>) => void;
}

const SelectedFilters = ({ onChangeFilter }: ISelectedFiltersProps) => {
  const filters = useAppSelector(store => store.oms.filters);
  const createdOn = useAppSelector(store => store.oms.createdOn);

  const salesChannels = useAppSelector(store => store.common.salesChannels);

  const dispatch = useAppDispatch();

  function getDisplayableValue(filter: IFilter, filterValue: string) {
    const filterName = filter.field as OmsFilterNames;

    if (filterName === 'lineItems.fulfillmentMode') {
      return filterValue;
    }

    return Object.values(salesChannels.data.records).find(
      channel => String(channel.id) === String(filterValue)
    )?.name;
  }

  if (Object.values(filters).length === 0) {
    return null;
  }

  return (
    <Styles.Wrapper>
      <AnimatePresence mode="popLayout">
        {Object.values(filters).map(filter =>
          String(filter.value)
            .split(',')
            .map(value => {
              const displayableValue = getDisplayableValue(filter, value);

              if (!displayableValue || displayableValue?.length === 0) {
                return null;
              }

              return (
                <Styles.Filter
                  layout
                  key={value}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                  exit={{
                    scale: 0,
                    opacity: 0,
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 900,
                    damping: 40,
                    duration: 0.3,
                  }}
                >
                  <Text fontSize={12} color="var(--cod-gray)">
                    {displayableValue}
                  </Text>
                  <IconButton
                    icon="CrossIcon"
                    onClick={() => {
                      onChangeFilter(
                        filter.field,
                        String(filter.value)
                          .split(',')
                          .filter(v => v !== value)
                          .map(v => ({ label: '', value: v }))
                      );
                    }}
                    shapeVariant="circle"
                    sizeVariant="small"
                  />
                </Styles.Filter>
              );
            })
        )}

        {createdOn.from && createdOn.to && (
          <Styles.Filter
            layout
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            exit={{
              scale: 0,
              opacity: 0,
            }}
            transition={{
              type: 'spring',
              stiffness: 900,
              damping: 40,
              duration: 0.3,
            }}
          >
            <Text fontSize={12} color="var(--cod-gray)">
              {formatToReadableDate(fromUnixTime(createdOn.from))} -{' '}
              {formatToReadableDate(fromUnixTime(createdOn.to))}
            </Text>
            <IconButton
              icon="CrossIcon"
              onClick={() => {
                dispatch(
                  setOrdersPagination({
                    page: 0,
                    shouldFetchMore: true,
                  })
                );
                dispatch(deleteOMSCreatedOnFilters());
              }}
              shapeVariant="circle"
              sizeVariant="small"
            />
          </Styles.Filter>
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default SelectedFilters;
