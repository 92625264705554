import * as React from 'react';
import { IconPropsType } from '../types';

const TicketIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.751 4.583v1.833m0 3.667v1.833m0 3.667v1.833M4.583 4.583h12.834c1.012 0 1.833.82 1.833 1.833v2.75a1.833 1.833 0 0 0 0 3.667v2.75c0 1.013-.82 1.833-1.833 1.833H4.583a1.833 1.833 0 0 1-1.833-1.833v-2.75a1.833 1.833 0 1 0 0-3.667v-2.75c0-1.012.82-1.833 1.833-1.833"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TicketIcon;
