import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootStateType } from '../store';

const selectFacilities = (state: RootStateType) => state.common.facilities;

export const offlineFacilitiesSelector = createDraftSafeSelector(selectFacilities, facilities => {
  const offlineFacilityIds = facilities.data.ids.filter(
    id => !facilities.data.records[id].isOnline
  );

  const newFacilities = structuredClone(facilities);

  newFacilities.data.ids = offlineFacilityIds;
  newFacilities.data.records = offlineFacilityIds.reduce((acc, id) => {
    acc[id] = newFacilities.data.records[id];
    return acc;
  }, {} as typeof newFacilities.data.records);

  return newFacilities;
});

export const onlineFacilitiesSelector = createDraftSafeSelector(selectFacilities, facilities => {
  const onlineFacilityIds = facilities.data.ids.filter(id => facilities.data.records[id].isOnline);

  const newFacilities = structuredClone(facilities);

  newFacilities.data.ids = onlineFacilityIds;
  newFacilities.data.records = onlineFacilityIds.reduce((acc, id) => {
    acc[id] = newFacilities.data.records[id];
    return acc;
  }, {} as typeof newFacilities.data.records);

  return newFacilities;
});
