import { OrderDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';

interface IAddressProps {
  data: OrderDTOType['sellerInfo'];
}

const Address = ({ data }: IAddressProps) => {
  return (
    <Styles.Text>
      {data?.name}
      <br />
      {data?.address.addressLine}
      <br />
      {data?.address.locality}
      <br />
      {data?.address.cityDisplayName} - {data?.address.pincode}
      <br />
      {data?.address.stateDisplayName}
    </Styles.Text>
  );
};

export default Address;
