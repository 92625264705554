import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AddressDTOType } from '@point-of-sale/schemas';
import { Button, CheckBox, Input, SelectDropdown, Text } from '@point-of-sale/components';
import usePinCodeDetails from '../../../hooks/usePinCodeDetails';
import {
  addAddressToCustomer,
  assignCustomerDeliveryAddressToCart,
  updateAddressOfCustomer,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { concatenateWithCommas } from '@point-of-sale/utils';
import * as Styles from './styles';
import { INDIAN_STATES, PHONE_REGEX } from '@point-of-sale/constants';

type AddressPropsType =
  | {
      data: AddressDTOType;
      isAddMode?: false;
    }
  | {
      isAddMode: true;
      customerId: number;
      isFirst: boolean;
    };

const Address = (props: AddressPropsType) => {
  const dispatch = useAppDispatch();

  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const [formData, setFormData] = useState<Partial<AddressDTOType>>({});

  const [modeOfOperation, setModeOfOperation] = useState<'CREATE' | 'READ' | 'EDIT'>('READ');

  useEffect(() => {
    if (props.isAddMode) {
      setModeOfOperation('CREATE');
    }
  }, [props.isAddMode]);

  const { data: pinCodeData, isFetching } = usePinCodeDetails(formData.pincode);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      pincode: formData.pincode,
      cityDisplayName: pinCodeData?.cityDisplayName,
      stateDisplayName: pinCodeData?.stateDisplayName,
      countryDisplayName: pinCodeData?.countryDisplayName,
      cityCode: pinCodeData?.cityCode,
      stateCode: pinCodeData?.stateCode,
      countryCode: pinCodeData?.countryCode,
    }));
  }, [pinCodeData]);

  useEffect(() => {
    if (props.isAddMode && props.isFirst) {
      setModeOfOperation('EDIT');
    }
  }, [props]);

  if (props.isAddMode && modeOfOperation === 'CREATE') {
    return (
      <Styles.ViewMode>
        <Button
          sizeVariant="medium"
          colorScheme="dark"
          variant="outline"
          onClick={() => {
            setFormData({});
            setModeOfOperation('EDIT');
          }}
        >
          Add New Address
        </Button>
      </Styles.ViewMode>
    );
  }

  return (
    <Styles.Wrapper
      $isEditMode={modeOfOperation !== 'READ'}
      layout
      key={props.isAddMode ? 'new-address' : props.data.id}
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0,
        opacity: 0,
      }}
      transition={{
        type: 'spring',
        stiffness: 900,
        damping: 40,
        duration: 0.3,
      }}
    >
      {modeOfOperation === 'EDIT' && (
        <Styles.EditMode $isLoading={isFetching}>
          <Styles.Row>
            <Input
              label="Pincode *"
              placeholder="ex. 110023"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.pincode}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  pincode: event.target.value,
                }));
              }}
            />
            <Input
              label="Name"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.name}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <Input
              label="Phone"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.phone}
              type="number"
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  phone: event.target.value,
                }));
              }}
            />
          </Styles.Row>

          <Styles.Row>
            <Input
              label="Label"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.label}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  label: event.target.value,
                }));
              }}
            />
            <Input
              label="Landmark"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.landmark}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  landmark: event.target.value,
                }));
              }}
            />
            <Input
              label="Locality"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.locality}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  locality: event.target.value,
                }));
              }}
            />
          </Styles.Row>
          <Input
            label="Address Line *"
            sizeVariant="extraSmall"
            variant="outline"
            className="half"
            value={formData.addressLine}
            onChange={event => {
              setFormData(prev => ({
                ...prev,
                addressLine: event.target.value,
              }));
            }}
          />
          <Styles.Row>
            <Input
              // disabled
              label="City"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.cityDisplayName}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  cityCode: event.target.value,
                  cityDisplayName: event.target.value,
                }));
              }}
            />
            {/* <Input
              // disabled
              label="State"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.stateDisplayName}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  stateDisplayName: event.target.value,
                  stateCode: event.target.value,
                }));
              }}
            /> */}
            <SelectDropdown<(typeof INDIAN_STATES)[number]>
              className="half"
              sizeVariant="small"
              options={INDIAN_STATES}
              labelField="stateName"
              valueField="iso2Code"
              // value={formData?.stateCode ?? ''}
              onChange={selectedValues => {
                const newValue = selectedValues[0];

                if (!newValue) {
                  setFormData(prev => ({
                    ...prev,
                    stateDisplayName: '',
                    stateCode: '',
                  }));
                  return;
                }

                setFormData(prev => ({
                  ...prev,
                  stateDisplayName: newValue.stateName,
                  stateCode: newValue.iso2Code,
                }));
              }}
              values={[INDIAN_STATES.find(item => item.iso2Code === formData.stateCode) ?? {}]}
              dropdownHeight="200px"
            />
            <Input
              // disabled
              label="Country"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.countryDisplayName}
              onChange={event => {
                setFormData(prev => ({
                  ...prev,
                  countryCode: event.target.value,
                  countryDisplayName: event.target.value,
                }));
              }}
            />
          </Styles.Row>

          <Styles.EditModeCTAs>
            <Button
              sizeVariant="xs"
              colorScheme="dark"
              variant="outline"
              onClick={() => {
                if (props.isAddMode) {
                  setModeOfOperation('CREATE');
                  setFormData({});
                } else {
                  setModeOfOperation('READ');
                  setFormData(props.data);
                }
              }}
            >
              Cancel
            </Button>

            <Button
              sizeVariant="xs"
              colorScheme="dark"
              onClick={() => {
                if (!formData.addressLine) {
                  toast.error('Address Line is required');
                  return;
                }

                if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
                  toast.error('Please Enter a valid phone number');
                  return;
                }

                if (formData.pincode?.length !== 6) {
                  toast.error('pincode is invalid');
                  return;
                }

                if (props.isAddMode) {
                  dispatch(
                    addAddressToCustomer(props.customerId, formData, () => {
                      setFormData({});
                      setModeOfOperation('CREATE');
                    }),
                    {
                      shouldMakeDeliveryAddress: true,
                    }
                  );
                  return;
                }

                dispatch(
                  updateAddressOfCustomer(props.data.id, formData, () => {
                    dispatch(assignCustomerDeliveryAddressToCart(props.data.id));
                    setModeOfOperation('READ');
                  })
                );
              }}
            >
              Save & Select
            </Button>
          </Styles.EditModeCTAs>
        </Styles.EditMode>
      )}

      {!props.isAddMode && modeOfOperation === 'READ' && (
        <Styles.ViewMode>
          <CheckBox
            name="selectedAddressId"
            isChecked={props.data.id === selectedAddressId}
            onChange={() => dispatch(assignCustomerDeliveryAddressToCart(props.data.id))}
            label={
              <Styles.ViewModeContent>
                <Text fontSize={12} weight="semibold" color="var(--cod-gray)">
                  {props.data.label} {props.data.isDefault && '(Default)'}
                </Text>
                <Text fontSize={14} color="var(--cod-gray)">
                  {props.data.addressLine}
                </Text>
                <Text fontSize={14}>
                  {concatenateWithCommas([
                    props.data.cityDisplayName,
                    props.data.pincode,
                    props.data.stateDisplayName,
                    props.data.countryDisplayName,
                  ])}
                </Text>
              </Styles.ViewModeContent>
            }
          />

          <Button
            sizeVariant="xs"
            colorScheme="dark"
            variant="outline"
            onClick={() => {
              setFormData(props.data);
              setModeOfOperation('EDIT');
            }}
            leftIcon="EditIcon"
          >
            Edit
          </Button>
        </Styles.ViewMode>
      )}
    </Styles.Wrapper>
  );
};

export default Address;
