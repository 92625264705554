import { z } from 'zod';
import type {
  SalesChannelDTOType,
  StoreStaffDTOType,
  FacilityDTOType,
} from '@point-of-sale/schemas';
import {
  SalesChannelDTOSchema,
  StoreStaffDTOSchema,
  FacilityDTOSchema,
} from '@point-of-sale/schemas';
import { setStoreStaff, setSalesChannels, setFacilities, setAnalyticsDashboard } from './actions';
import { createListFetchingThunk } from '../utils/createListFetchingThunk';
import {
  getSalesChannelsApi,
  getFacilitiesApi,
  getStoreStaffApi,
  getAnalyticsDashboardUrlApi,
} from './api';
import { ThunkActionType } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import toast from 'react-hot-toast';

export const getStoreStaff = () =>
  createListFetchingThunk<StoreStaffDTOType>({
    dispatchable: setStoreStaff,
    dataFetcher: getStoreStaffApi,
    zodSchema: z.array(StoreStaffDTOSchema),
  });

export const getSalesChannels = () =>
  createListFetchingThunk<SalesChannelDTOType>({
    dispatchable: setSalesChannels,
    dataFetcher: getSalesChannelsApi,
    zodSchema: z.array(SalesChannelDTOSchema),
  });

export const getFacilities = () =>
  createListFetchingThunk<FacilityDTOType>({
    dispatchable: setFacilities,
    dataFetcher: getFacilitiesApi,
    zodSchema: z.array(FacilityDTOSchema),
  });

export const getAnalyticsDashboardUrl = (): ThunkActionType => async (dispatch, getState) => {
  const name = getState().identity.selectedSalesChannel?.name;

  if (!name) {
    return;
  }

  dispatch(
    setAnalyticsDashboard({
      isLoading: true,
      isSuccess: false,
      isError: false,
    })
  );

  const promise = getAnalyticsDashboardUrlApi(name);

  const response = await apiResponseHandler<{
    url: string;
  }>(promise);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setAnalyticsDashboard({
        isLoading: false,
        isError: true,
        isSuccess: false,
      })
    );
    return;
  }

  dispatch(
    setAnalyticsDashboard({
      isLoading: false,
      isSuccess: true,
      isError: false,
      data: response.data,
    })
  );
};
