import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import {
  DesignSystemProvider,
  ErrorFallback,
  MainErrorBoundary,
  RouteProtector,
  posThemeTokens,
} from '@point-of-sale/components';
import { persistor, store } from '@point-of-sale/store';
import Login from '../pages/Login';
import { useIsOnline } from '@point-of-sale/hooks';
import { SentryService } from '@point-of-sale/services';

import BaseRoutes from '../routes';

// const BaseRoutes = lazy(() => import('../routes'));

export function App() {
  useIsOnline();

  useEffect(() => {
    SentryService.init();
  }, []);

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DesignSystemProvider themeTokens={posThemeTokens}>
          <Toaster
            position="top-center"
            toastOptions={{
              style: {
                wordBreak: 'break-all',
              },
            }}
          />
          <MainErrorBoundary FallbackComponent={ErrorFallback}>
            <Routes>
              <Route
                path="*"
                element={
                  <RouteProtector>
                    <BaseRoutes />
                  </RouteProtector>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </MainErrorBoundary>
        </DesignSystemProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
