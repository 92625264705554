import styled from 'styled-components';
import { Text } from './styles';
import { ReactNode } from 'react';

export const Wrapper = styled.div`
  /* border-bottom: 1px dashed var(--dusty-gray); */
  padding: 8px 0;
`;

export const Heading = styled(Text)`
  text-transform: uppercase;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Td = styled.td`
  font-size: 12px;
  padding: 2px 0;
  line-height: 16px;
`;

interface ISectionProps {
  heading: string;
  data: Array<{
    key: string | ReactNode;
    value: string | number | undefined | ReactNode;
  }>;
}

const Section = ({ heading, data }: ISectionProps) => {
  return (
    <Wrapper>
      <Heading align="center" weight={700}>
        {heading}
      </Heading>
      <Table>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <Td width={50}>{item.key}</Td>
              <Td width={50} align="right">
                {item.value}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default Section;
