import { BaseSyntheticEvent } from 'react';

export function swallowEvent(event: BaseSyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export function goodbye(e: Event | null) {
  if (!e) e = window.event as Event;

  (e as any).cancelBubble = true;

  // e.stopPropagation works in Firefox.
  if (e.stopPropagation) {
    e.stopPropagation();
    e.preventDefault();
  }
}
