import { CheckoutActionsTypes } from './constants';
import { ICheckoutState } from './types';

export const setSalesPoc = (payload: ICheckoutState['salesPoc']) => ({
  type: CheckoutActionsTypes.SET_SALES_POC,
  payload,
});

export const setPaymentOptions = (payload: Partial<ICheckoutState['paymentOptions']>) => ({
  type: CheckoutActionsTypes.SET_PAYMENT_OPTIONS,
  payload,
});

export const setPaymentTerminals = (payload: Partial<ICheckoutState['paymentTerminals']>) => ({
  type: CheckoutActionsTypes.SET_PAYMENT_TERMINALS,
  payload,
});

export const setCartTransactions = (payload: Partial<ICheckoutState['cartTransactions']>) => ({
  type: CheckoutActionsTypes.SET_CART_TRANSACTIONS,
  payload,
});

export const setCartMinAmountToBePaid = (payload: number) => ({
  type: CheckoutActionsTypes.SET_CART_MIN_AMOUNT_TO_BE_PAID,
  payload,
});

export const setCartTotalAmountToBePaid = (payload: number) => ({
  type: CheckoutActionsTypes.SET_CART_TOTAL_AMOUNT_TO_BE_PAID,
  payload,
});

export const setCartTotalAmountPaid = (payload: number) => ({
  type: CheckoutActionsTypes.SET_CART_TOTAL_AMOUNT_PAID,
  payload,
});

export const setProcessedOrder = (payload: Partial<ICheckoutState['processedOrder']>) => ({
  type: CheckoutActionsTypes.SET_PROCESSED_ORDER,
  payload,
});

export const resetCheckoutState = () => ({
  type: CheckoutActionsTypes.RESET,
});
