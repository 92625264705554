import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface IWrapperProps {
  $backgroundColor: string;
  $borderRadius: string | number;
  $borderColor?: string;
}

export const Wrapper = styled(motion.div)<IWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 52px;
  padding: 10px 24px;

  ${props => props.$borderColor && `border: 1px solid ${props.$borderColor};`}

  ${({ $backgroundColor, $borderRadius }) =>
    css`
      background-color: ${$backgroundColor};
      border-radius: ${$borderRadius};
    `}
`;

export const ScoopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
