import { z } from 'zod';

const EnvSchema = z.object({
  DEV: z.boolean(),
  VITE_TOKEN_KEY: z.string(),
  VITE_BASE_URL: z.string(),
  VITE_DEVICE_ID: z.string(),
  VITE_ENVIRONMENT: z.enum(['staging', 'production', 'local']),
  VITE_SENTRY_DSN: z.string().optional(),
});

type EnvType = z.infer<typeof EnvSchema>;

const ENV: EnvType = {} as EnvType;

// export const ENV = EnvSchema.parse(import.meta.env);

const envParsingResult = EnvSchema.safeParse(import.meta.env);

if (envParsingResult.success) {
  Object.assign(ENV, envParsingResult.data);
} else {
  const errorStyles = ['color: red', 'background: yellow', 'font-size: 24px'].join(';');

  envParsingResult.error.errors.forEach((error, index) => {
    console.log(
      `%c[ENV] Error #${index + 1}: ${error.path.toString()} => ${error.message}`,
      errorStyles
    );
  });

  throw new Error('ENV variables not found');
}

if (
  ENV.VITE_ENVIRONMENT === 'production' &&
  ENV.VITE_BASE_URL !== 'https://gateway.lyskraft.in/api'
) {
  throw new Error('Invalid base url for production');
}

export { ENV };
