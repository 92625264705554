import { useState } from 'react';
import {
  Button,
  DiscountIcon,
  Input,
  Modal,
  PercentageIcon,
  RadioGroup,
  Text,
  Toggle,
} from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { applyUnApplyCouponCodeToCart, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { DiscountDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';

const Discount = () => {
  const dispatch = useAppDispatch();

  const { couponDiscount, couponCode } = useAppSelector(store => store.pointOfSale.cart.data);

  const isLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  const [isModalOpen, modalOpenActions] = useBoolean();

  const [formData, setFormData] = useState<DiscountDTOType>({
    couponCode: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const isCouponApplied = Boolean(couponCode) && Boolean(couponDiscount);

  const [customDiscountMode, setCustomDiscountMode] = useState<'PERC' | 'FLAT'>('FLAT');

  const [manualFormData, setManualFormData] = useState<{
    couponCode: string;
  }>({
    couponCode: '',
  });

  // TODO: Move this up the component hierarchy
  const cartTotalAmountToBePaid = useAppSelector(store => store.checkout.cartTotalAmountToBePaid);
  const cartTotalAmountPaid = useAppSelector(store => store.checkout.cartTotalAmountPaid);
  const remainingAmount = cartTotalAmountToBePaid - cartTotalAmountPaid;
  const halfRemainingAmount = remainingAmount / 2;

  return (
    <>
      <Styles.Wrapper>
        <DiscountIcon size={24} />
        <Text color="var(--dove-gray)" fontSize={12} weight="bold">
          {isCouponApplied ? `Code ${couponCode} Applied` : 'Apply Discount'}
        </Text>

        {isCouponApplied ? (
          <Button
            variant="outline"
            colorScheme="dark"
            leftIcon="DeleteIcon"
            sizeVariant="xs"
            isLoading={isLoading}
            isDisabled={hasPaymentsStarted}
            onClick={() => {
              dispatch(applyUnApplyCouponCodeToCart(''));
            }}
          >
            Remove
          </Button>
        ) : (
          <Button
            variant="outline"
            sizeVariant="xs"
            onClick={modalOpenActions.on}
            isDisabled={hasPaymentsStarted}
          >
            Apply
          </Button>
        )}
      </Styles.Wrapper>
      <Modal
        size="small"
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        heading={
          <Styles.Heading fontSize={20} weight="bold">
            Apply Discount <PercentageIcon />
          </Styles.Heading>
        }
      >
        <Styles.ModalContent>
          <Styles.Row>
            <Input
              label="Coupon Code"
              placeholder="Coupon Code"
              sizeVariant="small"
              variant="outline"
              value={formData.couponCode}
              isFullWidth
              disabled={Boolean(manualFormData.couponCode)}
              onChange={e =>
                setFormData(prev => ({
                  ...prev,
                  couponCode: e.target.value.toUpperCase(),
                }))
              }
            />
            <Button
              isLoading={isLoading}
              isDisabled={hasPaymentsStarted || Boolean(manualFormData.couponCode)}
              onClick={() => {
                function successCallback() {
                  setFormData({
                    couponCode: '',
                  });
                  modalOpenActions.off();
                }
                function failureCallback() {
                  setErrorMessage(`${formData.couponCode} is an invalid or disabled coupon.`);
                  setFormData({
                    couponCode: '',
                  });
                }
                dispatch(
                  applyUnApplyCouponCodeToCart(
                    formData.couponCode,
                    successCallback,
                    failureCallback
                  )
                );
              }}
            >
              Apply
            </Button>
          </Styles.Row>

          {errorMessage && (
            <Text fontSize={16} color="var(--alizarin-crimson)">
              {errorMessage}
            </Text>
          )}

          <Text fontSize={24} weight="semibold" textAlign="center">
            OR
          </Text>

          <RadioGroup
            name="discount-mode"
            horizontal
            options={[
              {
                label: 'Percent Off',
                value: 'PERC',
              },
              {
                label: 'Flat Discount',
                value: 'FLAT',
              },
            ]}
            selectedValue={customDiscountMode ?? ''}
            onChange={selectedValue => {
              setCustomDiscountMode(selectedValue as typeof customDiscountMode);
            }}
          />

          <Styles.Row>
            <Input
              label={`Custom Discount ${
                customDiscountMode === 'PERC'
                  ? 'Percentage (Max 50%)'
                  : `Flat (Max ${halfRemainingAmount})`
              }`}
              placeholder={`Custom Discount ${
                customDiscountMode === 'PERC'
                  ? 'Percentage (Max 50%)'
                  : `Flat (Max ${halfRemainingAmount})`
              }`}
              disabled={Boolean(formData.couponCode)}
              sizeVariant="small"
              variant="outline"
              isFullWidth
              type="number"
              value={manualFormData.couponCode ?? ''}
              onChange={e => {
                if (customDiscountMode === 'PERC' && Number(e.target.value) > 50) {
                  return;
                }

                if (customDiscountMode === 'FLAT' && Number(e.target.value) > halfRemainingAmount) {
                  return;
                }

                setManualFormData(prev => ({
                  ...prev,
                  couponCode: e.target.value,
                }));
              }}
            />
            <Button
              isLoading={isLoading}
              isDisabled={hasPaymentsStarted || Boolean(formData.couponCode)}
              onClick={() => {
                function successCallback() {
                  setManualFormData({
                    couponCode: '',
                  });
                  modalOpenActions.off();
                }

                // if (customDiscountMode === 'PERC') {
                //   if (
                //     manualFormData.couponCode &&
                //     Number(manualFormData.couponCode) > 100
                //   ) {
                //     toast('Percentage should be less than 100');
                //     return;
                //   }
                // }

                dispatch(
                  applyUnApplyCouponCodeToCart(
                    `${customDiscountMode}${manualFormData.couponCode}`,
                    successCallback
                  )
                );
              }}
            >
              Apply
            </Button>
          </Styles.Row>
        </Styles.ModalContent>
      </Modal>
    </>
  );
};

export default Discount;
