import styled, { css } from 'styled-components';
import {
  IconButtonColorVariantType,
  IconButtonShapeVariantType,
  IconButtonSizeVariantType,
} from './types';
import {
  IconButtonShapes,
  IconButtonSizes,
  IconButtonBorderStyles,
  IconButtonDisabledStyles,
  IconButtonHoverStyles,
  IconButtonActiveStyles,
} from './constants';

export interface IStyledIconButtonProps {
  $sizeVariant: IconButtonSizeVariantType;
  $colorVariant: IconButtonColorVariantType;
  $shapeVariant: IconButtonShapeVariantType;
}

function getDefiningStyles(props: IStyledIconButtonProps) {
  const borderStyles = IconButtonBorderStyles[props.$colorVariant];
  const disabledStyles = IconButtonDisabledStyles[props.$colorVariant];
  const hoverStyles = IconButtonHoverStyles[props.$colorVariant];
  const activeStyles = IconButtonActiveStyles[props.$colorVariant];

  return css`
    ${{ ...borderStyles }}

    &:disabled {
      ${{ ...disabledStyles }}
      box-shadow: none !important;
    }

    &:hover:enabled {
      ${{ ...hoverStyles }}
    }

    &:active:enabled {
      ${{ ...activeStyles }}
    }
  `;
}

export const IconButton = styled.button<IStyledIconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--white);
  padding: 0;
  margin: 0;
  transition: box-shadow 0.2s ease-in-out;
  flex-shrink: 0;

  ${({ $sizeVariant }) => ({ ...IconButtonSizes[$sizeVariant] })}

  ${({ $shapeVariant }) => ({ ...IconButtonShapes[$shapeVariant] })}

  ${getDefiningStyles}
`;
