import { IconPropsType } from '../types';

const PlusIcon = ({ size = 36, ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M232 280H64v-48h168V64h48v168h168v48H280v168h-48z" />
  </svg>
);

export default PlusIcon;
