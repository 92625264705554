import { Button, Input } from '@point-of-sale/components';
import { addTransactionToCart, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useEffect, useState } from 'react';
import { CartTransactionDTOType } from '@point-of-sale/schemas';
import { useBoolean } from '@point-of-sale/hooks';
import * as Styles from './styles';
import { PropsWithRemainingAmount } from '../types';
import ManualPaymentStatusIndicator from '../ManualPaymentStatusIndicator';

const Cash = ({ remainingAmount }: PropsWithRemainingAmount) => {
  const { data } = useAppSelector(store => store.checkout.cartTransactions);
  const salesPoc = useAppSelector(store => store.checkout.salesPoc);
  const cartTotalAmountToBePaid = useAppSelector(store => store.checkout.cartTotalAmountToBePaid);

  const [isManualPaymentStatusModalOpen, manualPaymentStatusModalOpen] = useBoolean();
  const [manualPaymentStatus, setManualPaymentStatus] = useState<'PENDING' | 'SUCCESS' | 'FAILED'>(
    'PENDING'
  );

  const [cashValue, setCashValue] = useState(String(remainingAmount));

  const [returnableAmount, setReturnableAmount] = useState('');

  const [isUpsertLoading, upsertLoadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  const onAddCash = () => {
    upsertLoadingActions.on();
    manualPaymentStatusModalOpen.on();
    setManualPaymentStatus('PENDING');
    dispatch(
      addTransactionToCart(
        {
          amount: Number(cashValue) - Number(returnableAmount),
          changeAmount: Number(returnableAmount),
          paymentGateway: 'CASH',
          paymentMode: 'CASH',
        },
        {
          successCallback: () => {
            upsertLoadingActions.off();
            setManualPaymentStatus('SUCCESS');
          },
          failCallback: () => {
            upsertLoadingActions.off();
            setManualPaymentStatus('FAILED');
          },
        }
      )
    );
  };

  const verifiedCashTransaction = Object.values(data.records).find(
    (t: CartTransactionDTOType) =>
      t.paymentMode === 'CASH' && t.status === 'VERIFIED' && t.paymentGateway === 'CASH'
  );

  useEffect(() => {
    if (verifiedCashTransaction?.amount) {
      setCashValue(String(verifiedCashTransaction.amount));
    }
  }, [verifiedCashTransaction]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Input
          label="Cash"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          placeholder="Enter cash amount"
          // isFullWidth
          value={cashValue}
          onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            setCashValue(e.target.value);

            const difference = Number(e.target.value) - cartTotalAmountToBePaid;
            if (difference > 0) {
              setReturnableAmount(String(difference));
            } else {
              setReturnableAmount('');
            }
          }}
        />
        <Input
          label="Return Amount"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          // isFullWidth
          value={returnableAmount}
          onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            setReturnableAmount(e.target.value);
          }}
        />
        <Button
          isLoading={isUpsertLoading}
          onClick={onAddCash}
          isDisabled={Number(cashValue) <= 0 || !salesPoc}
        >
          {verifiedCashTransaction?.amount ? 'Update Cash' : 'Add Cash'}
        </Button>
      </Styles.Row>

      <ManualPaymentStatusIndicator
        paymentMode="Cash"
        requestValue={cashValue}
        status={manualPaymentStatus}
        isOpen={isManualPaymentStatusModalOpen}
        onClose={() => {
          // setRequestValue(String(remainingAmount));
          // setRemarks('');
          setCashValue(String(remainingAmount));
          manualPaymentStatusModalOpen.off();
        }}
      />
    </Styles.Wrapper>
  );
};

export default Cash;
