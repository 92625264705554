import React, { ReactNode } from 'react';
// import { swallowEvent } from 'utils/events';
// import Text from '../Text';
// import ICONOGRAPHY from '../icons/strokeIcons';
// import FILLED_ICONOGRAPHY from '../icons/filledIcons';
// import { COLOR_VARIANTS } from '../colors';
// import { ICapsuleProps } from '../types/capsule';
// import { IconographyNameType, FilledIconographyNameType } from '../types/icons';
import * as Styles from './styles';
import { COMPLEXION_VARIANTS } from '../constants/complexion';
import iconMap from '../icons/iconMap';
import { IconographyNameType, IconPropsType } from '../icons/types';
import { CrossIcon } from '../icons';

import Text from '../Typography/Text';
import { ComplexionType } from '../types';
import { EssenceType, SizeType } from './types';
import { CSSProperties } from 'styled-components';

export interface ICapsuleProps {
  complexion: ComplexionType;
  essence?: EssenceType;
  size?: SizeType;
  content: ReactNode;
  isCloseVisible?: boolean;
  className?: string;
  iconName?: Exclude<IconographyNameType, 'CoyuLogo' | 'HamBurgerIcon'> | null;
  iconProps?: IconPropsType;
  handleClose?(): void;
  preventTruncate?: boolean;
  closeIconProps?: CSSProperties & IconPropsType;
  onClick?: () => void;
  onMouseOver?: () => void;
  isHoverEnabled?: boolean;
  style?: CSSProperties;
}

const Capsule = ({
  complexion,
  essence = 'light',
  size = 'small',
  content,
  isCloseVisible = false,
  preventTruncate = false,
  className = '',
  iconName,
  iconProps = {},
  handleClose,
  closeIconProps = {},
  onClick,
  onMouseOver,
  style = {},
  isHoverEnabled = true,
}: ICapsuleProps) => {
  let IconNode = null;
  const colorProperty = 'stroke';
  //   if (iconName) {
  //     if (Object.hasOwnProperty.call(ICONOGRAPHY, iconName)) {
  //       IconNode = ICONOGRAPHY[iconName as IconographyNameType];
  //     } else {
  //       IconNode = FILLED_ICONOGRAPHY[iconName as FilledIconographyNameType];
  //       colorProperty = 'fill';
  //     }
  //   }
  if (iconName) {
    IconNode = iconMap[iconName];
  }

  return (
    <Styles.Wrapper
      className={className}
      $size={size}
      $complexion={complexion}
      $essence={essence}
      onClick={onClick}
      onMouseOver={onMouseOver}
      $isHoverEnabled={isHoverEnabled}
      style={style}
    >
      {IconNode !== null && (
        <IconNode
          size={16}
          {...iconProps}
          {...{ [colorProperty]: COMPLEXION_VARIANTS[complexion].primaryColor }}
        />
      )}
      <Text
        textAlign="center"
        // className={preventTruncate ? 'no-wrap' : 'truncated'}
        // type={size === 'small' ? 'text-xs' : 'text-sm'}
        fontSize={size === 'small' ? 12 : 14}
        weight="semibold"
      >
        {content}
      </Text>

      {isCloseVisible && (
        <CrossIcon
          size={12}
          className="cursor-pointer ml-4"
          onClick={handleClose}
          {...closeIconProps}
        />
      )}
    </Styles.Wrapper>
  );
};

export default Capsule;
