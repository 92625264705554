import { Button, CheckBox } from '@point-of-sale/components';
import useProductContext from '../../Product/context/useProductContext';
import { TableDataType } from './useTableData';
import * as Styles from './styles';
import { ISource } from '..';
import { useBoolean } from '@point-of-sale/hooks';

interface ITableProps {
  data: TableDataType;
  source: ISource;
  setSource: (source: ISource) => void;
}

const Table = ({ data, setSource, source }: ITableProps) => {
  const { setFulfillmentFacilityId } = useProductContext();

  const [shouldShowZeroInventory, zeroInventoryActions] = useBoolean();

  const fulfillable = data.filter(item => item.inventory > 0);
  const unFulfillable = data.filter(item => item.inventory === 0);

  return (
    <Styles.Wrapper>
      <Styles.Table>
        <Styles.TableHead>
          <Styles.TableRow $isDisabled={false}>
            <Styles.TableHeader>Location</Styles.TableHeader>
            <Styles.TableHeader>Inventory</Styles.TableHeader>
            <Styles.TableHeader>Expected Delivery Date</Styles.TableHeader>
          </Styles.TableRow>
        </Styles.TableHead>
        <Styles.TableBody>
          {fulfillable.map(item => {
            const isSelected = item.id === source.fulfillmentFacility.id;

            if (item.isOnline) {
              return null;
            }

            return (
              <Styles.TableRow
                $isDisabled={item.isDisabled}
                key={item.id}
                onClick={() => {
                  if (item.isDisabled) {
                    return;
                  }

                  setSource({
                    fulfillmentFacility: {
                      id: item.id,
                      city: item.cityDisplayName,
                      name: item.name,
                      pincode: item.pincode,
                    },
                    inventory: item.inventory,
                    promiseDate: item.expectedDeliveryDate,
                  });

                  setFulfillmentFacilityId(item.id);
                }}
              >
                <Styles.TableData>
                  <CheckBox
                    shape="round"
                    isChecked={isSelected}
                    name={item.name}
                    label={
                      <>
                        {item.name}, {item.cityDisplayName}
                      </>
                    }
                  />
                </Styles.TableData>
                <Styles.TableData>{item.inventory}</Styles.TableData>
                <Styles.TableData>{item.expectedDeliveryDate}</Styles.TableData>
              </Styles.TableRow>
            );
          })}

          {/* <Styles.TableRow $isDisabled={false}>
            <Styles.TableData colSpan={3}>
              <Button
                onClick={zeroInventoryActions.toggle}
                sizeVariant="xs"
                colorScheme="dark"
                variant="outline"
              >
                {shouldShowZeroInventory ? 'Hide' : 'Show'} Non-fulfillable Inventory
              </Button>
            </Styles.TableData>
          </Styles.TableRow> */}

          {/* {shouldShowZeroInventory &&
            unFulfillable.map(item => {
              const isSelected = item.id === source.fulfillmentFacility.id;

              if (item.isOnline) {
                return null;
              }

              return (
                <Styles.TableRow
                  $isDisabled={item.isDisabled}
                  key={item.id}
                  onClick={() => {
                    if (item.isDisabled) {
                      return;
                    }

                    setSource({
                      fulfillmentFacility: {
                        id: item.id,
                        city: item.cityDisplayName,
                        name: item.name,
                        pincode: item.pincode,
                      },
                      inventory: item.inventory,
                      promiseDate: item.expectedDeliveryDate,
                    });

                    setFulfillmentFacilityId(item.id);
                  }}
                >
                  <Styles.TableData>
                    <CheckBox
                      shape="round"
                      isChecked={isSelected}
                      name={item.name}
                      label={
                        <>
                          {item.name}, {item.cityDisplayName}
                        </>
                      }
                    />
                  </Styles.TableData>
                  <Styles.TableData>{item.inventory}</Styles.TableData>
                  <Styles.TableData>{item.expectedDeliveryDate}</Styles.TableData>
                </Styles.TableRow>
              );
            })} */}
        </Styles.TableBody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default Table;
