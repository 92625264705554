import * as React from 'react';

import { IconPropsType } from '../types';

const StarIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11 16.27-5.658 2.975 1.081-6.3L1.84 8.484l6.325-.917 2.829-5.732 2.829 5.732 6.325.917-4.584 4.461 1.08 6.3z"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default StarIcon;
