import { CSSProperties, SVGProps } from 'react';
import {
  IconButtonColorVariantType,
  IconButtonShapeVariantType,
  IconButtonSizeVariantType,
} from './types';
import { IconPropsType } from '../icons/types';

export const IconButtonSizes: Record<IconButtonSizeVariantType, CSSProperties> = {
  extraSmall: {
    height: '24px',
    width: '24px',
  },
  small: {
    height: '32px',
    width: '32px',
  },
  medium: {
    height: '40px',
    width: '40px',
  },
  large: {
    height: '48px',
    width: '48px',
  },
};

export const IconButtonShapes: Record<IconButtonShapeVariantType, CSSProperties> = {
  square: {
    borderRadius: '1px',
    border: '1px solid',
  },
  circle: {
    borderRadius: '100%',
    // border: 'none !important',
  },
};

export const IconButtonHoverStyles: Record<IconButtonColorVariantType, CSSProperties> = {
  neutral: {
    background: 'var(--alabaster)',
  },
  primary: {
    background: 'var(--zumthor)',
  },
  destructive: {
    background: 'var(--chablis)',
  },
};

export const IconButtonBorderStyles: Record<IconButtonColorVariantType, CSSProperties> = {
  neutral: {
    borderColor: 'var(--gallery)',
  },
  primary: {
    borderColor: 'var(--selago)',
  },
  destructive: {
    borderColor: 'var(--geraldine)',
  },
};

export const IconButtonDisabledStyles: Record<IconButtonColorVariantType, CSSProperties> = {
  neutral: {
    borderColor: 'var(--royal-blue)',
    background: 'var(--white)',
    cursor: 'not-allowed',
  },
  primary: {
    borderColor: 'var(--chablis)',
    background: 'var(--white)',
    cursor: 'not-allowed',
  },
  destructive: {
    borderColor: 'var(--royal-blue)',
    background: 'var(--white)',
    cursor: 'not-allowed',
  },
};

export const IconButtonActiveStyles: Record<IconButtonColorVariantType, CSSProperties> = {
  neutral: {
    boxShadow: '0px 0px 0px 4px #E8EAEF, 0px 1px 2px 0px rgba(12, 16, 33, 0.05);',
  },
  primary: {
    boxShadow: '0px 0px 0px 4px #FFE7E8, 0px 1px 2px 0px rgba(196, 30, 46, 0.05);',
  },
  destructive: {
    boxShadow: '0px 0px 0px 3px rgba(222, 53, 11, 0.20);',
  },
};

export const IconButtonIconStyles: Record<
  IconButtonColorVariantType,
  {
    enabled: IconPropsType;
    disabled: IconPropsType;
  }
> = {
  neutral: {
    enabled: {
      stroke: 'var(--cod-gray)',
    },
    disabled: {
      stroke: 'var(--silver)',
    },
  },
  primary: {
    enabled: {
      stroke: 'var(--royal-blue)',
    },
    disabled: {
      stroke: 'var(--zumthor)',
    },
  },
  destructive: {
    enabled: { stroke: 'var(--tamarillo)' },
    disabled: { stroke: 'var(--cosmos)' },
  },
};

export const iconButtonIconSizes: Record<IconButtonSizeVariantType, number> = {
  large: 24,
  medium: 20,
  small: 16,
  extraSmall: 12,
};
