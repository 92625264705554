import { getOrders, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import { NoResults } from '@runway/illustrations';
import { SpinnerIcon } from '@point-of-sale/components';
import Row from './Row';
import * as TableStyles from '../Table/styles';
import * as Styles from './styles';

const OrdersTable = () => {
  const { data, isLoading } = useAppSelector(store => store.oms.orders);

  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(getOrders());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  return (
    <TableStyles.Wrapper
      style={{
        width: '100%',
      }}
    >
      <TableStyles.Table
        style={{
          width: '100%',
        }}
      >
        <TableStyles.Thead>
          <TableStyles.TableHeadingRow>
            <TableStyles.TableHeader>Items</TableStyles.TableHeader>
            <TableStyles.TableHeader>Customer Details</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Id</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Types</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Date</TableStyles.TableHeader>
            <TableStyles.TableHeader>Billing Location</TableStyles.TableHeader>
            <TableStyles.TableHeader>No. Of Items</TableStyles.TableHeader>
            <TableStyles.TableHeader>Total Amount</TableStyles.TableHeader>
            <TableStyles.TableHeader>Invoice</TableStyles.TableHeader>
          </TableStyles.TableHeadingRow>
        </TableStyles.Thead>
        <tbody>
          {data?.ids.map((id, index) => {
            const rowData = data.records[id];

            return (
              <Row
                key={id}
                data={rowData}
                lastItemRef={index === data.ids.length - 3 ? lastItemRef : undefined}
                salesFacility={
                  rowData.salesFacilityId ? facilityRecords[rowData.salesFacilityId] : 'SHOPIFY'
                }
              />
            );
          })}
        </tbody>
      </TableStyles.Table>
      {!isLoading && data?.ids.length === 0 && <NoResults text="No Orders" />}
      {isLoading && data?.ids.length === 0 && (
        <Styles.LoaderWrapper>
          <SpinnerIcon size={48} />
        </Styles.LoaderWrapper>
      )}
    </TableStyles.Wrapper>
  );
};

export default OrdersTable;
