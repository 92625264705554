import * as React from 'react';
import { IconPropsType } from '../types';
const UserCircleIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={11}
      cy={11}
      r={8.25}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={11}
      cy={9.167}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
      r={2.75}
    />
    <path
      d="M5.652 17.279a3.67 3.67 0 0 1 3.513-2.612h3.667a3.67 3.67 0 0 1 3.514 2.617"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UserCircleIcon;
