import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  height: calc(100vh - 52px);
  gap: 24px;

  overflow: hidden;
`;

export const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  overflow: scroll;

  background: var(--white);

  border-radius: 8px;
`;
