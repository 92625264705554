import { Button, Text } from '@point-of-sale/components';
// import Cart from './Cart';
import Discount from './Discount';
import ServiceCharges from './ServiceCharges';
import PriceDetails from './PriceDetails';
import { openInSameTab } from '@point-of-sale/utils';
import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';
import Cart from '../../../components/Cart';

const Summary = () => {
  const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  return (
    <Styles.Wrapper>
      <Styles.CartContainer>
        <Cart usage="CHECKOUT" />
      </Styles.CartContainer>
      <Styles.Sticky>
        <Styles.Section>
          {/* <Styles.SectionHeading>
            <Text fontSize={20} weight="bold">
              Discount
            </Text>
          </Styles.SectionHeading> */}
          <Discount />
        </Styles.Section>
        <Styles.Section>
          {/* <Styles.SectionHeading>
            <Text fontSize={20} weight="bold">
              Service Charge
            </Text>
          </Styles.SectionHeading> */}
          <ServiceCharges level="TOP" />
        </Styles.Section>
        <Styles.Section>
          {/* <Styles.SectionHeading>
            <Text fontSize={20} weight="bold">
              Price Details
            </Text>
          </Styles.SectionHeading> */}
          {/* <Styles.SectionBody> */}
          <PriceDetails />
          {/* </Styles.SectionBody> */}
        </Styles.Section>
        <Styles.Section>
          {/* <Styles.SectionBody>
            <Button
              isFullWidth
              onClick={() => {
                // process payment api
              }}
            >
              Complete Payment
            </Button>
          </Styles.SectionBody> */}
        </Styles.Section>
      </Styles.Sticky>
    </Styles.Wrapper>
  );
};

export default Summary;
