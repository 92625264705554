import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';

export const copyToClipboard = (textToCopy: string) => {
  const didCopy = copy(textToCopy);
  if (didCopy) {
    toast.success(`Copied ${textToCopy} to clipboard`);
  } else {
    toast.error(`Failed to copy ${textToCopy} to clipboard`);
  }
  return textToCopy;
};
