import { Text } from '@point-of-sale/components';
import { COUNTRIES } from '@point-of-sale/constants';
import * as Styles from './styles';
import CountrySelector from '../CountrySelector';

interface IPhoneOrEmailInputProps {
  // loginType: 'MOBILE' | 'EMAIL';
  inputValue: string;
  countryCode: string;
  onChange: (value: string) => void;
  setCountryCode: (countryCode: string) => void;
}

const PhoneOrEmailInput = ({
  countryCode,
  inputValue,
  // loginType,
  onChange,
  setCountryCode,
}: IPhoneOrEmailInputProps) => {
  return (
    <Styles.Wrapper>
      <Text fontSize={14} color="var(--dove-gray)">
        Enter phone
      </Text>
      <Styles.Bottom>
        <CountrySelector
          value={countryCode}
          onChange={newCountryCode => {
            setCountryCode(newCountryCode);
          }}
        />
        <Styles.StyledInput
          placeholder="ex 9999 8888 77"
          value={inputValue}
          onChange={e => onChange(e.target.value)}
          sizeVariant="small"
          variant="outline"
          type="number"
          isFullWidth
        />
      </Styles.Bottom>
    </Styles.Wrapper>
  );
};

export default PhoneOrEmailInput;
