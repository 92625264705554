import { IOption } from '@point-of-sale/components';
import { FacetDTOType } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';

export const useGetOptionsFromFacets = () => {
  const facets = useAppSelector(store => store.pointOfSale.facets);

  const getOptionsFromFacets = (filterName: FacetDTOType['key']['key']): Array<IOption> => {
    const rawOptions = facets.find(facet => facet.key.key === filterName)?.values ?? [];

    return rawOptions.map(o => ({
      value: o.id,
      label: `${o.value} (${o.count})`,
    }));
  };

  return { getOptionsFromFacets };
};
