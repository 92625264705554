import { useEffect } from 'react';

import {
  setCartMinAmountToBePaid,
  setCartTotalAmountPaid,
  setCartTotalAmountToBePaid,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import useCartParams from '../../hooks/useCartParams';
import Summary from './Summary';
import PaymentCollection from './PaymentCollection';
import Parties from './Parties';
import MinimumCollections from './MinimumCollections';
import * as Styles from './styles';

const Checkout = () => {
  useCartParams();

  const { chargeableAmount, amountPaid, minAmountToBePaid } = useAppSelector(
    store => store.pointOfSale.cart.data
  );

  const salesPoc = useAppSelector(store => store.checkout.salesPoc);

  /// change this to useAppSelector
  const hasSalesOrders = useAppSelector(store =>
    Object.values(store.pointOfSale.cart.data.cartItems.records).some(
      item => item.fulfillmentMode !== 'ONHAND'
    )
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCartMinAmountToBePaid(minAmountToBePaid ?? 0));
    dispatch(setCartTotalAmountPaid(amountPaid ?? 0));
    dispatch(setCartTotalAmountToBePaid(chargeableAmount ?? 0));
  }, [chargeableAmount, amountPaid, minAmountToBePaid]);

  return (
    <Styles.Wrapper>
      <Styles.Left>
        <PaymentCollection
          {...(salesPoc
            ? { isDisabled: false }
            : {
                isDisabled: true,
                disabledReason: 'Please Choose a Sales POC',
              })}
        />
        {hasSalesOrders && <MinimumCollections />}
      </Styles.Left>
      <Styles.Right>
        <Parties />
        <Summary />
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default Checkout;
