import { IconPropsType } from '../types';

const CreditCardIcon = ({ stroke = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.092 9v8c0 .53.212 1.04.59 1.414.376.375.888.586 1.422.586h14.085c.534 0 1.045-.21 1.423-.586.377-.375.589-.884.589-1.414V9M3.091 9V7c0-.53.213-1.04.59-1.414C4.058 5.21 4.571 5 5.104 5h14.085c.534 0 1.045.21 1.423.586.377.375.589.884.589 1.414v2M3.091 9h18.11M7.116 13h5.03"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CreditCardIcon;
