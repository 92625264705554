import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ExpandedRow = styled(motion.tr)`
  width: 100%;
  background: var(--soapstone);
`;

export const SkuInfoRow = styled.div`
  padding-top: 4px;
  display: flex;
  align-items: center;
`;

export const SkuInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
`;
