import { IconPropsType } from '../types';

const CheckMarkIcon = ({
  size = 24,
  stroke = 'var(--royal-blue)',
  strokeWidth = 2,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m5 12 5 5 9-9"
    />
  </svg>
);
export default CheckMarkIcon;
