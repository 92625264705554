import styled from 'styled-components';

export const Wrapper = styled.div`
  /* border-top: 1px solid var(--alto); */
  border-bottom: 1px solid var(--alto);
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 8px;
`;

export const Footer = styled.div`
  border-top: 1px solid var(--alto);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
