import { z } from 'zod';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, omit } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import type {
  StorePickupsDTOType,
  LineItemDTOType,
  AlterationEntryDTOType,
  PendingPaymentAddDTOType,
  PaymentOptionDTOType,
  OrderTransactionDTOType,
} from '@point-of-sale/schemas';
import {
  StorePickupsDTOSchema,
  LineItemDTOSchema,
  DeliveryModeSchema,
  PaymentOptionDTOSchema,
  OrderTransactionDTOSchema,
} from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { normalizeArray } from '../utils/normalizeArray';
import { ThunkActionType } from '../store';
import {
  setStorePickups,
  setStorePickupsPagination,
  setCustomizationRemarks,
  setCustomizationRemarksPagination,
  setPostOrderPaymentOptions,
} from './actions';
import {
  collectPendingPaymentApi,
  getPendingCustomizationRemarksApi,
  getPendingStorePickupsApi,
  markPackageDeliveredApi,
  updateLineItemAlterationEntryApi,
  getPostOrderPaymentOptionsApi,
  rejectStorePickupApi,
} from './api';
import { createListFetchingThunk } from '../utils/createListFetchingThunk';
import toast from 'react-hot-toast';

export const getPostOrderPaymentOptions = (salesChannelOrderId: string) =>
  createListFetchingThunk<PaymentOptionDTOType>({
    dispatchable: setPostOrderPaymentOptions,
    dataFetcher: getPostOrderPaymentOptionsApi.bind(null, salesChannelOrderId),
    zodSchema: z.array(PaymentOptionDTOSchema),
  });

export const getStorePickups = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().pendingTasks.storePickupsPagination;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setStorePickups({
      isLoading: true,
    })
  );

  const filterBuilder = new FilterBuilder();

  const currentFacilityId = getState().identity.selectedSalesChannel?.facilityId;

  filterBuilder.addFilter({
    field: 'customerDeliveryStoreId',
    filterType: 'EQ',
    // @ts-expect-error currentFacilityId will never be nullish
    value: currentFacilityId,
  });
  filterBuilder.addFilter({
    field: 'deliveryMode',
    filterType: 'EQ',
    value: DeliveryModeSchema.Enum.STORE_PICKUP,
  });
  // TODO: un-comment this
  filterBuilder.addFilter({
    field: 'currentStatus',
    filterType: 'IN',
    value: 'DELIVERED_AT_STORE,RECEIVED_AT_STORE,DISPATCHED,OUT_FOR_DELIVERY',
  });

  const newPage = page + 1;

  const promise = getPendingStorePickupsApi({
    page: newPage,
    size: size,
    filters: filterBuilder.build(),
  });

  const response = await apiResponseHandler<Array<StorePickupsDTOType>>(
    promise,
    z.array(StorePickupsDTOSchema)
  );

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setStorePickupsPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setStorePickups({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage !== 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().pendingTasks.storePickups.data.ids, ...ids];
    records = {
      ...getState().pendingTasks.storePickups.data.records,
      ...records,
    };
  }

  dispatch(
    setStorePickups({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setStorePickupsPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );
};

export const markPackageDelivered =
  (packageId: number, callback: CallableFunction): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = markPackageDeliveredApi({
      packageId,
    });

    const response = await apiResponseHandler<StorePickupsDTOType>(promise, StorePickupsDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      callback();
      return;
    }

    callback();

    dispatch(
      setStorePickupsPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );
    dispatch(getStorePickups());

    // dispatch(
    //   setStorePickups({
    //     data: {
    //       ids: getState().pendingTasks.storePickups.data.ids.filter(
    //         id => id !== packageId
    //       ),
    //       records: omit(getState().pendingTasks.storePickups.data.records, [
    //         packageId,
    //       ]),
    //     },
    //     isLoading: false,
    //     isSuccess: true,
    //   })
    // );
  };

export const getCustomizationRemarks = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().pendingTasks.customizationRemarksPagination;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setCustomizationRemarks({
      isLoading: true,
    })
  );

  const filterBuilder = new FilterBuilder();

  filterBuilder.addFilter({
    field: 'fulfillmentMode',
    filterType: 'EQ',
    value: 'MTC',
  });
  filterBuilder.addFilter({
    field: 'status',
    filterType: 'IN',
    value: 'CREATED',
  });
  filterBuilder.addFilter({
    field: 'brandStatus',
    filterType: 'ISNULL',
    value: '',
  });

  filterBuilder.addFilter({
    field: 'salesChannelId',
    filterType: 'EQ',
    value: getState().identity?.selectedSalesChannel?.id ?? '',
  });

  const newPage = page + 1;

  const promise = getPendingCustomizationRemarksApi({
    page: newPage,
    size: size,
    filters: filterBuilder.build(),
  });

  const response = await apiResponseHandler<Array<LineItemDTOType>>(
    promise,
    z.array(LineItemDTOSchema)
  );

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setCustomizationRemarksPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setCustomizationRemarks({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage !== 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().pendingTasks.customizationRemarks.data.ids, ...ids];
    records = {
      ...getState().pendingTasks.customizationRemarks.data.records,
      ...records,
    };
  }

  dispatch(
    setCustomizationRemarks({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setCustomizationRemarksPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );
};

export const updateLineItemAlterationEntry =
  (
    lineItemId: number,
    updatedAlterationEntry: AlterationEntryDTOType,
    callback: (newLineItemData?: LineItemDTOType) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    // dispatch(
    //   setCustomizationRemarks({
    //     isLoading: true,
    //   })
    // );

    const promise = updateLineItemAlterationEntryApi({
      lineItemId,
      data: updatedAlterationEntry,
    });

    const response = await apiResponseHandler<LineItemDTOType>(promise, LineItemDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      callback();
      return;
    }

    dispatch(
      setCustomizationRemarks({
        data: {
          ids: getState().pendingTasks.customizationRemarks.data.ids,
          records: {
            ...getState().pendingTasks.customizationRemarks.data.records,
            [response.data.id]: response.data,
          },
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    callback(response.data);
  };

export const collectPendingPayment =
  (
    salesChannelOrderId: string,
    item: PendingPaymentAddDTOType,
    {
      successCallback,
      failCallback,
    }: {
      successCallback?: (id: number) => void;
      failCallback?: () => void;
    }
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = collectPendingPaymentApi({
      salesChannelOrderId,
      data: item,
    });

    const response = await apiResponseHandler<OrderTransactionDTOType>(
      promise,
      OrderTransactionDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      failCallback?.();
      return;
    }

    console.log({ response: response.data });

    successCallback?.(response.data.id);

    const oldItem = getState().pendingTasks.storePickups.data.records[response.data.packageId];

    dispatch(
      setStorePickups({
        data: {
          ids: getState().pendingTasks.storePickups.data.ids,
          records: {
            ...getState().pendingTasks.storePickups.data.records,
            [response.data.packageId]: {
              ...oldItem,
              costBreakup: {
                ...response.data.order?.costBreakup,
              },
            },
          },
        },
        isLoading: false,
        isSuccess: true,
      })
    );
  };

export const rejectStorePickup =
  (
    packageId: number,
    data: {
      rtoReason: string;
      refundMode: string;
      salesStaffId: number;
      bankAccount?: {
        accountNumber: string;
        ifsc: string;
        bankName: string;
        accountName: string;
      };
    },
    {
      successCallback,
      failCallback,
    }: {
      successCallback?: () => void;
      failCallback?: () => void;
    }
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = rejectStorePickupApi(packageId, data);

    const response = await apiResponseHandler<StorePickupsDTOType>(promise, StorePickupsDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      failCallback?.();
      return;
    }

    successCallback?.();
  };
