import { useEffect } from 'react';
import { Button, IOption } from '@point-of-sale/components';
import {
  deletePosFilter,
  updatePosFilters,
  searchProducts,
  setMode,
  setSearch,
  useAppDispatch,
  useAppSelector,
  PosFilterNames,
  setPosPagination,
  setProducts,
  offlineFacilitiesSelector,
} from '@point-of-sale/store';

import { useGetOptionsFromFacets } from './useGetOptionsFromFacets';
import CustomerInfo from './CustomerInfo';
import SelectedFilters from './SelectedFilters';
import { getRootNode } from '../../main';
import Discovery, { IFilter } from '../Discovery';
import { useDebounce } from '@point-of-sale/hooks';
import * as Styles from './styles';

interface IProductDiscoveryProps {
  usage: 'POS' | 'EXPLORE_PRODUCTS';
}

const ProductDiscovery = ({ usage }: IProductDiscoveryProps) => {
  const pointOfSaleMode = useAppSelector(store => store.pointOfSale.mode);
  const search = useAppSelector(store => store.pointOfSale.search);
  const filters = useAppSelector(store => store.pointOfSale.filters);
  const facilities = useAppSelector(offlineFacilitiesSelector);
  const isLoadingProducts = useAppSelector(store => store.pointOfSale.products.isLoading);

  const dispatch = useAppDispatch();

  const { getOptionsFromFacets } = useGetOptionsFromFacets();

  function onChangeFilter(field: PosFilterNames, options: Array<IOption>) {
    dispatch(
      setPosPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );
    dispatch(
      updatePosFilters({
        filter: {
          field,
          value: options.map(option => option.value).join(','),
          filterType: 'IN',
        },
        name: field,
      })
    );
  }

  // function onChangeSearch(event: ChangeEvent<HTMLInputElement>) {
  function onChangeSearch(searchQuery: string) {
    dispatch(
      setPosPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );
    dispatch(
      setProducts({
        data: {
          ids: [],
          records: {},
        },
      })
    );
    dispatch(
      setSearch({
        query: searchQuery,
      })
    );
    dispatch(searchProducts(usage));
  }

  const debouncedApiCall = useDebounce(() => dispatch(searchProducts(usage)), 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [filters, debouncedApiCall]);

  useEffect(() => {
    return () => {
      dispatch(
        setPosPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );
      dispatch(
        setSearch({
          query: '',
        })
      );
    };
  }, []);

  const mode = useAppSelector(store => store.pointOfSale.mode);

  useEffect(() => {
    if (usage !== 'POS') {
      return;
    }

    function setRootBackground(
      color: 'var(--alice-blue)' | 'var(--athens-gray)' | 'var(--white-lilac)'
    ) {
      getRootNode().style.background = color;
    }

    if (mode === 'BUY_NOW') {
      setRootBackground('var(--alice-blue)');
    } else if (mode === 'MADE_TO_ORDER') {
      setRootBackground('var(--white-lilac)');
    } else {
      setRootBackground('var(--athens-gray)');
    }

    return () => {
      setRootBackground('var(--athens-gray)');
    };
  }, [mode]);

  const discoveryFilters: Array<IFilter> = [
    {
      name: 'brandName',
      placeholder: 'Brand',
      options: getOptionsFromFacets('brandName'),
      values: String(filters.brandName?.value).split(',') ?? [],
      type: 'MULTISELECT',
    },
    {
      name: 'variants.inStockFacilities',
      placeholder: 'Availability',
      options: facilities.data.ids.map(id => ({
        label: facilities.data.records[id].name,
        value: id,
      })),

      values: String(filters['variants.inStockFacilities']?.value).split(',').map(Number) ?? [],
      type: 'MULTISELECT',
    },
    {
      name: 'variants.size',
      placeholder: 'Size',
      options: getOptionsFromFacets('variants.size'),
      values: String(filters['variants.size']?.value).split(',') ?? [],
      type: 'MULTISELECT',
    },
    {
      name: 'variants.color',
      placeholder: 'Color',
      options: getOptionsFromFacets('variants.color'),
      values: String(filters['variants.color']?.value).split(',') ?? [],
      type: 'MULTISELECT',
    },
  ];

  return (
    <Styles.Wrapper>
      {usage === 'POS' && (
        <Styles.Row>
          <Styles.ModeSwitchContainer>
            <Button
              sizeVariant="large"
              onClick={() => dispatch(setMode('BUY_NOW'))}
              colorScheme={pointOfSaleMode === 'BUY_NOW' ? 'primary' : 'tertiary'}
            >
              Buy Now
            </Button>
            <Button
              sizeVariant="large"
              onClick={() => dispatch(setMode('MADE_TO_ORDER'))}
              colorScheme={pointOfSaleMode === 'MADE_TO_ORDER' ? 'secondary' : 'tertiary'}
            >
              Made To Order
            </Button>
          </Styles.ModeSwitchContainer>
          <Styles.Right>
            <CustomerInfo />
          </Styles.Right>
        </Styles.Row>
      )}
      <Discovery
        filters={discoveryFilters}
        isLoading={isLoadingProducts}
        searchQuery={search.query}
        // onSearchChange={onChangeSearch}
        onPressEnter={onChangeSearch}
        onFilterChange={onChangeFilter}
        onFilterClear={filterName => dispatch(deletePosFilter(filterName as PosFilterNames))}
        renderSelectedFilters={() => <SelectedFilters onChangeFilter={onChangeFilter} />}
        placeholder="Search by name or scan"
      />
    </Styles.Wrapper>
  );
};

export default ProductDiscovery;
