import { ProductVariantDTOType } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';
import { omit } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';
import { Button } from '@point-of-sale/components';
import * as Styles from './styles';

interface IInventoryTableProps {
  inventory: ProductVariantDTOType['facilityInventory'];
}

const InventoryTable = ({ inventory }: IInventoryTableProps) => {
  const [shouldShowZeroInventory, zeroInventoryActions] = useBoolean();

  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const facilities = useAppSelector(store => store.common.facilities.data);

  const inventoryToShow = omit(inventory ?? {}, [currentFacilityId as unknown as string]);

  const inventoryArray = Object.entries(inventoryToShow).map(([id, details]) => ({
    id,
    ...(details as { availableInventory: number }),
  }));

  inventoryArray.sort((a, b) => b.availableInventory - a.availableInventory);

  const filteredInventory = inventoryArray.filter(
    inventory => shouldShowZeroInventory || inventory.availableInventory > 0
  );

  return (
    <Styles.Wrapper>
      <Styles.Table>
        <thead>
          <Styles.TableRow>
            <Styles.TableHeader>Facility ID</Styles.TableHeader>
            <Styles.TableHeader>Inventory</Styles.TableHeader>
          </Styles.TableRow>
        </thead>
        <tbody>
          {filteredInventory.map(inventory => {
            const facility = facilities.records[inventory.id as unknown as number];

            if (!facility) {
              return null;
            }

            return (
              <Styles.TableRow key={inventory.id}>
                <Styles.TableData>
                  {facility?.name}, &nbsp;
                  {facility?.address?.cityDisplayName}
                </Styles.TableData>
                <Styles.TableData>{inventory?.availableInventory}</Styles.TableData>
              </Styles.TableRow>
            );
          })}

          {/* <Styles.TableRow>
            <Styles.TableData colSpan={2}>
              <Button
                onClick={zeroInventoryActions.toggle}
                sizeVariant="xs"
                colorScheme="dark"
                variant="outline"
              >
                {shouldShowZeroInventory ? 'Hide' : 'Show'} Non-fulfillable Inventory
              </Button>
            </Styles.TableData>
          </Styles.TableRow> */}
        </tbody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default InventoryTable;
