import { createGlobalStyle, css } from 'styled-components';
import { IPointOfSaleState } from '@point-of-sale/store';
import { PALETTE } from './Colors/palette';
import CssReset from './CssReset';

const GlobalStyles = createGlobalStyle<{ $mode: IPointOfSaleState['mode'] }>`

    ${CssReset}
    
    :root {
        ${Object.entries(PALETTE)
          .map(([variable, value]) => `${variable}: ${value};`)
          .join('\n')}
    }

    * {
        font-family: 'Hanken Grotesk';
    }
    
    body {
        height: 100vh;
        width: 100vw;
   
    }

    a {
        text-decoration: none;
    }

    #root {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
        background: var(--athens-gray);

        transition: background 0.3s;

    }


    .online {
        filter: none;
        transition: filter 1s;
    }

    .offline {
        filter: grayscale(100%);
    }

 
`;

export default GlobalStyles;
