import {
  getCustomizationRemarks,
  updateLineItemAlterationEntry,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';

import { Button, Image, Text } from '@point-of-sale/components';
import { useEffect, useState } from 'react';
import { useBoolean, useFetchOnListEnd } from '@point-of-sale/hooks';

import * as TableStyles from '../Table/styles';

import { AlterationEntryDTOType } from '@point-of-sale/schemas';
import PersonalizationRead from '../Cart/Personalization/PersonalizationRead';
import { PLACEHOLDERS } from '@point-of-sale/constants';

const PendingCustomizationRemarks = () => {
  const { data, isLoading: isCustomizationRemarksLoading } = useAppSelector(
    store => store.pendingTasks.customizationRemarks
  );

  const storeStaff = useAppSelector(store => store.common.storeStaff.data.records);

  const [isPersonalizationModalOpen, personalizationModalOpenActions] = useBoolean();

  const [isLoading, loadingActions] = useBoolean();

  const [customizationData, setCustomizationData] = useState<AlterationEntryDTOType | null>(null);
  const [skuInfo, setSkuInfo] = useState({
    color: '',
    size: '',
  });

  const [selectedLineItemId, setSelectedLineItemId] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isCustomizationRemarksLoading) {
      return;
    }

    dispatch(getCustomizationRemarks());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  useEffect(() => {
    dispatch(getCustomizationRemarks());
  }, []);

  return (
    <TableStyles.Wrapper>
      <TableStyles.Table>
        <TableStyles.Thead>
          <TableStyles.TableHeadingRow>
            <TableStyles.TableHeader>Items</TableStyles.TableHeader>
            <TableStyles.TableHeader>Product Name</TableStyles.TableHeader>
            <TableStyles.TableHeader>Size</TableStyles.TableHeader>
            <TableStyles.TableHeader>Color</TableStyles.TableHeader>
            <TableStyles.TableHeader>Order Number</TableStyles.TableHeader>
            <TableStyles.TableHeader>Customer Details</TableStyles.TableHeader>
            <TableStyles.TableHeader>POC</TableStyles.TableHeader>
            <TableStyles.TableHeader></TableStyles.TableHeader>
          </TableStyles.TableHeadingRow>
        </TableStyles.Thead>
        <tbody>
          {data?.ids.map((id, index) => {
            const record = data.records[id];

            return (
              <TableStyles.TableRow
                key={id}
                $isSelected={false}
                {...(index === data.ids.length - 3 ? { ref: lastItemRef } : {})}
              >
                <TableStyles.TableData>
                  <Image
                    height={60}
                    width={44}
                    src={record.productImage?.url}
                    alt={record.brandName}
                  />
                </TableStyles.TableData>
                <TableStyles.TableData>
                  <Text fontSize={10} color="var(--dove-gray)">
                    {record.brandName}
                  </Text>
                  <br />
                  <Text fontSize={12} color="var(--cod-gray)" weight="semibold">
                    {record.productName}
                  </Text>
                </TableStyles.TableData>
                <TableStyles.TableData>{record.size}</TableStyles.TableData>
                <TableStyles.TableData>{record.color}</TableStyles.TableData>
                <TableStyles.TableData>{record.salesChannelOrderId}</TableStyles.TableData>

                <TableStyles.TableData>
                  <Text fontSize={12} color="var(--cod-gray)">
                    {record.customerName}
                  </Text>
                  <br />
                  <Text fontSize={12} color="var(--cod-gray)">
                    {record.customerPhone}
                  </Text>
                </TableStyles.TableData>

                <TableStyles.TableData>
                  {record.salesStaffId
                    ? storeStaff[record.salesStaffId]?.name
                    : PLACEHOLDERS.DOUBLE_DASH}
                </TableStyles.TableData>
                <TableStyles.TableData>
                  <Button
                    sizeVariant="xs"
                    variant="outline"
                    onClick={() => {
                      console.log({ record });
                      if (record.alterationEntry) {
                        personalizationModalOpenActions.on();
                        setSelectedLineItemId(record.id);
                        setSkuInfo({
                          color: record.color,
                          size: record.size,
                        });
                        setCustomizationData(record.alterationEntry);
                      }
                    }}
                  >
                    Open Form
                  </Button>
                </TableStyles.TableData>
              </TableStyles.TableRow>
            );
          })}
        </tbody>
      </TableStyles.Table>

      {customizationData && selectedLineItemId && (
        <PersonalizationRead
          size={skuInfo.size}
          color={skuInfo.color}
          alterationEntry={customizationData}
          isOpen={isPersonalizationModalOpen}
          onClose={() => {
            personalizationModalOpenActions.off();
            setCustomizationData(null);
            setSelectedLineItemId(null);
            setSkuInfo({
              color: '',
              size: '',
            });
          }}
          mode="CUSTOMIZATION"
          isLoading={isLoading}
          onChangeRemarks={formData => {
            loadingActions.on();
            dispatch(
              updateLineItemAlterationEntry(selectedLineItemId, formData, () => {
                personalizationModalOpenActions.off();
                loadingActions.off();
                setCustomizationData(null);
                setSelectedLineItemId(null);
                setSkuInfo({
                  color: '',
                  size: '',
                });
              })
            );
          }}
        />
      )}
    </TableStyles.Wrapper>
  );
};

export default PendingCustomizationRemarks;
