export const PendingTasksActionsTypes = {
  SET_SEARCH: 'pending-tasks/SET_SEARCH',

  SET_STORE_PICKUPS: 'pending-tasks/SET_STORE_PICKUPS',
  SET_STORE_PICKUPS_PAGINATION: 'pending-tasks/SET_STORE_PICKUPS_PAGINATION',

  SET_CUSTOMIZATION_REMARKS: 'pending-tasks/SET_CUSTOMIZATION_REMARKS',
  SET_CUSTOMIZATION_REMARKS_PAGINATION: 'pending-tasks/SET_CUSTOMIZATION_REMARKS_PAGINATION',

  SET_LINE_ITEMS: 'pending-tasks/SET_LINE_ITEMS',

  RESET_STATE: 'pending-tasks/RESET_STATE',

  SET_PAYMENT_OPTIONS: 'pending-tasks/SET_PAYMENT_OPTIONS',
} as const;
