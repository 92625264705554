import { motion } from 'framer-motion';
import { IconPropsType } from '../types';

const NoImageIcon = ({ fill = 'var(--dove-gray)', size = 24, ...props }: IconPropsType) => (
  <motion.svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    animate={{ width: size, height: size }}
    transition={{ duration: 0.3 }}
  >
    <path
      fill={fill}
      d="m7.828 5-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.5.5 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.5 1.5 0 0 0 .677.163 1.4 1.4 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5m-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5m5.646 13.854.707-.707-20-20-.707.707z"
    />
  </motion.svg>
);
export default NoImageIcon;
