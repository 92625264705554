import React, { SyntheticEvent } from 'react';
import { DataAttributeType } from '@point-of-sale/types';
import { CheckMarkIcon } from '../icons';
import * as Styles from './styles';

export interface ICheckBoxProps {
  name: string;
  isChecked: boolean;
  onChange?(event: SyntheticEvent, item?: unknown): void;
  className?: string;
  label?: React.ReactNode | string;
  isIndeterminate?: boolean;
  isReversed?: boolean;
  isDisabled?: boolean;
  dataProps?: Record<DataAttributeType, string | number>;
  item?: unknown;
  shape?: 'round' | 'square';
}

const CheckBox = ({
  name,
  isChecked,
  onChange,
  label,
  item,
  className = '',
  isDisabled = false,
  dataProps = {},
  shape = 'square',
  isIndeterminate = false,
  isReversed = false,
}: ICheckBoxProps) => {
  const changeHandler = (event: SyntheticEvent) =>
    item ? onChange?.(event, item) : onChange?.(event);

  return (
    <Styles.Label
      htmlFor={name}
      className={className}
      onClick={isDisabled ? undefined : changeHandler}
    >
      <Styles.Input
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={changeHandler}
        {...dataProps}
      />
      <Styles.Span $shape={shape} $isChecked={isChecked} $isDisabled={isDisabled}>
        {isChecked && <CheckMarkIcon strokeWidth={3} stroke="var(--white)" />}
      </Styles.Span>
      {label && typeof label === 'string' ? (
        <Styles.LabelText fontSize={14} $isChecked={isChecked} $isDisabled={isDisabled}>
          {label}
        </Styles.LabelText>
      ) : (
        label
      )}
    </Styles.Label>
  );
};

export default CheckBox;
