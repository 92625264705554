import { Input } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Search = styled(Input)`
  /* border: 2px dotted red; */
  /* grid-row: 1 / 2;
  grid-column: 1 / 2; */
`;

export const Content = styled.div`
  overflow: hidden;

  flex: 1;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);

  grid-row-gap: 32px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  grid-column: 1 / 3;
  grid-row: 1 / 2;

  overflow: hidden;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  grid-column: 1 / 3;
  grid-row: 2 / 3;
`;
