import { IconPropsType } from '../types';

const SearchIcon = ({ stroke = 'var(--cod-gray)', size = 24, ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m21 21-4.343-4.343m0 0A8 8 0 1 0 5.344 5.344a8 8 0 0 0 11.313 11.313"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
