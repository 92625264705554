// eslint-disable-next-line @nx/enforce-module-boundaries
import { convertArrayToObject } from '@point-of-sale/utils';

export const normalizeArray = <T extends { id: number }>(array: Array<T>) => {
  const ids = array.map(cartItem => cartItem.id);
  const records = convertArrayToObject(array, 'id');

  return {
    ids,
    records,
  };
};
