export function areAllValuesEmptyStrings(obj: any): boolean {
  if (!obj) {
    return true;
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        if (!areAllValuesEmptyStrings(value)) {
          return false;
        }
      } else if (value !== '') {
        return false;
      }
    }
  }

  return true;
}
