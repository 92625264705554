import { Input, Select } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const CountrySelect = styled(Select)`
  border: 1px solid var(--alto);
  height: 46px;
  padding: 0 8px;

  input {
    max-width: 100px;
    padding: 0;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;
