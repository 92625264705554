import { PendingTasksActionsTypes } from './constants';
import { IPendingTasksState } from './types';

export const setPendingTasksSearch = (payload: Partial<IPendingTasksState['search']>) => ({
  type: PendingTasksActionsTypes.SET_SEARCH,
  payload,
});

export const setStorePickups = (payload: Partial<IPendingTasksState['storePickups']>) => ({
  type: PendingTasksActionsTypes.SET_STORE_PICKUPS,
  payload,
});

export const setStorePickupsPagination = (
  payload: Partial<IPendingTasksState['storePickupsPagination']>
) => ({
  type: PendingTasksActionsTypes.SET_STORE_PICKUPS_PAGINATION,
  payload,
});

export const setCustomizationRemarks = (
  payload: Partial<IPendingTasksState['customizationRemarks']>
) => ({
  type: PendingTasksActionsTypes.SET_CUSTOMIZATION_REMARKS,
  payload,
});

export const setCustomizationRemarksPagination = (
  payload: Partial<IPendingTasksState['customizationRemarksPagination']>
) => ({
  type: PendingTasksActionsTypes.SET_CUSTOMIZATION_REMARKS_PAGINATION,
  payload,
});

export const resetPendingTasksState = () => ({
  type: PendingTasksActionsTypes.RESET_STATE,
});

export const setPostOrderPaymentOptions = (
  payload: Partial<IPendingTasksState['paymentOptions']>
) => ({
  type: PendingTasksActionsTypes.SET_PAYMENT_OPTIONS,
  payload,
});
