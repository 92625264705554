import { IconPropsType } from '../types';

const ClipboardIcon = (props: IconPropsType) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 6.004c3.262.018 5.03.163 6.182 1.315C31.5 8.638 31.5 10.76 31.5 15.001v9c0 4.243 0 6.364-1.319 7.683C28.865 33 26.743 33 22.5 33h-9c-4.242 0-6.364 0-7.681-1.317C4.5 30.364 4.5 28.244 4.5 24v-9c0-4.242 0-6.363 1.319-7.682C6.97 6.167 8.738 6.022 12 6.004"
      stroke="var(--black)"
      strokeWidth={1.5}
    />
    <path
      d="M15.75 21h9.75m-15 0h.75m-.75-5.25h.75m-.75 10.5h.75m4.5-10.5h9.75m-9.75 10.5h9.75"
      stroke="var(--black)"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M12 5.25A2.25 2.25 0 0 1 14.25 3h7.5A2.25 2.25 0 0 1 24 5.25v1.5A2.25 2.25 0 0 1 21.75 9h-7.5A2.25 2.25 0 0 1 12 6.75z"
      stroke="var(--black)"
      strokeWidth={1.5}
    />
  </svg>
);
export default ClipboardIcon;
