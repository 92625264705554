import { IconPropsType } from '../types';

const CashIcon = ({
  fill = 'var(--white)',
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18.611 14.01.01-.011m-11.076.011.01-.011M4.023 18v-8c0-.53.212-1.04.59-1.414A2 2 0 0 1 6.036 8H20.12c.533 0 1.045.21 1.422.586.378.375.59.884.59 1.414v8c0 .53-.212 1.04-.59 1.414A2.02 2.02 0 0 1 20.12 20H6.035c-.534 0-1.046-.21-1.423-.586A2 2 0 0 1 4.023 18"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.024 16H3.018c-.534 0-1.046-.21-1.423-.586A2 2 0 0 1 1.005 14V6c0-.53.212-1.04.59-1.414A2 2 0 0 1 3.018 4h14.084c.534 0 1.046.21 1.423.586.378.375.59.884.59 1.414v2m-6.037 8c-.534 0-1.045-.21-1.423-.586a1.994 1.994 0 0 1 0-2.828 2.02 2.02 0 0 1 2.846 0 1.994 1.994 0 0 1 0 2.828 2.02 2.02 0 0 1-1.423.586"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CashIcon;
