import { InputHTMLAttributes } from 'react';
import { SearchIcon } from '../icons';
import * as Styles from './styles';

// interface ISearchBarProps extends  {}

const SearchBar = ({
  value = '',
  type = 'search',
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Styles.Wrapper>
      <SearchIcon />
      <Styles.Input autoFocus type="search" {...props} value={value} />
      {value?.toString().length > 0 && (
        <Styles.CloseButton
          icon="CrossIcon"
          sizeVariant="medium"
          shapeVariant="circle"
          onClick={() => props.onChange?.({ target: { value: '' } } as any)}
        />
      )}
    </Styles.Wrapper>
  );
};

export default SearchBar;
