import {
  CashIcon,
  CreditCardIcon,
  CreditNoteIcon,
  GiftVoucherIcon,
  UpiIcon,
} from '@point-of-sale/components';

export const PAYMENT_MODES = [
  {
    title: 'Cash',
    icon: <CashIcon />,
  },
  {
    gateway: 'PINE_LABS',
    title: 'Card',
    icon: <CreditCardIcon />,
  },
  {
    title: 'UPI',
    icon: <UpiIcon />,
  },
  {
    title: 'Credit Note',
    icon: <CreditNoteIcon />,
  },
  // {
  //   title: 'Gift Voucher',
  //   icon: <GiftVoucherIcon />,
  // },
] as const;

export type PaymentModeTypes = (typeof PAYMENT_MODES)[number]['title'];
