// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OMSActionsTypes } from './constants';
import { OmsFilterNames, IOrderManagementSystemState } from './types';
import { LineItemDTOType } from '@point-of-sale/schemas';
import { IDateRange } from '@point-of-sale/types';

export const setOrdersSearch = (payload: Partial<IOrderManagementSystemState['search']>) => ({
  type: OMSActionsTypes.SET_SEARCH,
  payload,
});

export const setOrders = (payload: Partial<IOrderManagementSystemState['orders']>) => ({
  type: OMSActionsTypes.SET_ORDERS,
  payload,
});

export const setOrdersPagination = (
  payload: Partial<IOrderManagementSystemState['pagination']>
) => ({
  type: OMSActionsTypes.SET_PAGINATION,
  payload,
});

export const setOrderDetails = (payload: Partial<IOrderManagementSystemState['details']>) => ({
  type: OMSActionsTypes.SET_ORDER_DETAILS,
  payload,
});

export const setLineItemDetailsById = (payload: { id: string; data: LineItemDTOType }) => ({
  type: OMSActionsTypes.SET_LINE_ITEM_DETAILS_BY_ID,
  payload,
});

export const resetOrdersState = () => ({
  type: OMSActionsTypes.RESET_STATE,
});

export const updateOMSFilters = (payload: { name: OmsFilterNames; filter: IFilter }) => ({
  type: OMSActionsTypes.UPDATE_FILTER,
  payload,
});

export const deleteOMSFilter = (payload: OmsFilterNames) => ({
  type: OMSActionsTypes.DELETE_FILTER,
  payload,
});

// TODO: Refactor
export const updateOMSCreatedOnFilters = (payload: IDateRange) => ({
  type: OMSActionsTypes.UPDATE_CREATED_ON_FILTER,
  payload,
});

// TODO: Refactor
export const deleteOMSCreatedOnFilters = () => ({
  type: OMSActionsTypes.DELETE_CREATED_ON_FILTER,
});
