import styled from 'styled-components';
import Skeleton from '../Skeleton';

const SkeletonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Skeletons = () => {
  return (
    <SkeletonContainer>
      {Array.from({ length: 7 }).map((_, index) => (
        <Row key={index + 1}>
          <Skeleton height={26} width={26} />
          <Skeleton height={26} width={150} />
        </Row>
      ))}
    </SkeletonContainer>
  );
};

export default Skeletons;
