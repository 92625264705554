import React, { ChangeEvent, MouseEvent } from 'react';
import styled from 'styled-components';

interface RadioButtonProps {
  label: string;
  value: string | number;
  name: string;
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  className?: string;
}

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const CustomRadio = styled.span<{ checked?: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--royal-blue);
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => (props.checked ? 'var(--royal-blue)' : 'transparent')};
  transition: all 0.2s;

  flex-shrink: 0;

  &::after {
    content: '';
    display: ${props => (props.checked ? 'block' : 'none')};
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LabelText = styled.span`
  font-size: 14px;
  user-select: none;
`;

const RadioButton = ({
  label,
  value,
  name,
  checked,
  onChange,
  onClick,
  className,
}: RadioButtonProps) => (
  <RadioWrapper className={className}>
    <HiddenRadio
      value={value}
      name={name}
      checked={checked}
      onChange={onChange}
      onClick={event => {
        onClick?.(event as MouseEvent<HTMLInputElement>);
      }}
    />
    <CustomRadio checked={checked} />
    <LabelText>{label}</LabelText>
  </RadioWrapper>
);

export default RadioButton;
