import { ThemeType } from '@point-of-sale/components';
import styled, { css } from 'styled-components';

interface ITableWrapperProps {
  $shouldFillHeight?: boolean;
}

export const Wrapper = styled.div<ITableWrapperProps>`
  background: var(--white);
  overflow: auto;

  border-radius: 8px;

  ${props =>
    props.$shouldFillHeight &&
    css`
      height: -moz-available;
      height: -webkit-fill-available;
      height: fill-available;
    `}
`;

Wrapper.defaultProps = {
  $shouldFillHeight: true,
};

export const Table = styled.table`
  width: 100%;

  border-collapse: collapse;

  /* border-radius: 8px; */
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHeader = styled.th<{ theme: ThemeType }>`
  background-color: var(--zircon);
  color: var(--cod-gray);
  padding: 16px 12px;
  text-align: left;

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.bold};
`;

interface ITableRowProps {
  $isSelected?: boolean;
  $haveHoverEffect?: boolean;
  $zebra?: boolean;
}

export const TableRow = styled.tr<ITableRowProps>`
  ${props =>
    props.$zebra &&
    css`
      &:nth-child(odd) {
        background-color: var(--white);
      }

      &:nth-child(even) {
        background-color: var(--soapstone);
      }
    `}

  ${props =>
    props.$haveHoverEffect &&
    css`
      &:hover {
        background-color: var(--zumthor);
      }
    `}

  ${props =>
    props.$isSelected &&
    css`
      background-color: var(--zumthor) !important;
    `}
`;

TableRow.defaultProps = {
  $haveHoverEffect: true,
  $zebra: true,
};

export const TableData = styled.td<{ theme: ThemeType }>`
  padding: 12px;

  text-align: left;

  font-size: ${props => props.theme.typography.fonts.size[12]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};

  vertical-align: middle;
`;

export const TableHeadingRow = styled.tr``;

export const LoaderContainer = styled.div`
  border: 1px solid black;
`;
