import { ButtonPropertyType, ColorConfigType, VariantConfig } from './types';

export const BUTTON_HEIGHTS: ButtonPropertyType<number> = {
  xs: 30,
  medium: 44,
  large: 52,
  xl: 59,
  small: 38,
} as const;

export const BUTTON_PADDING_VALUES: ButtonPropertyType<string> = {
  xs: '4px 8px',
  medium: '8px 24px',
  large: '12px 32px',
  xl: '12px 32px',
  small: '8px 16px',
} as const;

// TODO: find a better way to do this, maybe use theme tokens
const PRIMARY_PROPERTIES: VariantConfig = {
  fill: {
    background: 'var(--royal-blue)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'transparent',
    disabledBackground: 'var(--royal-blue)',
    backgroundOnHover: {
      atRest: 'var(--royal-blue)',
      whileLoading: 'var(--royal-blue)',
    },
  },
  ghost: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--royal-blue)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--zumthor)',
      whileLoading: 'var(--zumthor)',
    },
  },
  outline: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--royal-blue)',
    textDecoration: 'none',
    borderColor: 'var(--royal-blue)',
    backgroundOnHover: {
      atRest: 'var(--zumthor)',
      whileLoading: 'var(--zumthor)',
    },
  },
};

const SECONDARY_PROPERTIES: VariantConfig = {
  fill: {
    background: 'var(--purple-heart)',
    disabledBackground: 'var(--purple-heart)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--purple-heart)',
      whileLoading: 'var(--purple-heart)',
    },
  },
  ghost: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--purple-heart)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--selago)',
      whileLoading: 'var(--selago)',
    },
  },
  outline: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--purple-heart)',
    textDecoration: 'none',
    borderColor: 'var(--purple-heart)',
    backgroundOnHover: {
      atRest: 'var(--selago)',
      whileLoading: 'var(--selago)',
    },
  },
};

const TERTIARY_PROPERTIES: VariantConfig = {
  fill: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--cod-gray)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--white)',
      whileLoading: 'var(--white)',
    },
  },
  ghost: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--royal-blue)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--zumthor)',
      whileLoading: 'var(--zumthor)',
    },
  },
  outline: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--royal-blue)',
    textDecoration: 'none',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--zumthor)',
      whileLoading: 'var(--zumthor)',
    },
  },
};

const DARK_PROPERTIES: VariantConfig = {
  fill: {
    background: 'var(--cod-gray)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'transparent',
    disabledBackground: 'var(--cod-gray)',
    backgroundOnHover: {
      atRest: 'var(--cod-gray)',
      whileLoading: 'var(--cod-gray)',
    },
  },
  ghost: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--cod-gray)',
    textDecoration: 'underline',
    borderColor: 'transparent',
    backgroundOnHover: {
      atRest: 'var(--athens-gray)',
      whileLoading: 'var(--athens-gray)',
    },
  },
  outline: {
    background: 'var(--white)',
    disabledBackground: 'var(--white)',
    color: 'var(--cod-gray)',
    textDecoration: 'none',
    borderColor: 'var(--cod-gray)',
    backgroundOnHover: {
      atRest: 'var(--gallery)',
      whileLoading: 'var(--gallery)',
    },
  },
};

export const COLOR_CONFIG: ColorConfigType = {
  primary: PRIMARY_PROPERTIES,
  secondary: SECONDARY_PROPERTIES,
  tertiary: TERTIARY_PROPERTIES,
  dark: DARK_PROPERTIES,
} as const;

export const ICON_SIZE: ButtonPropertyType<number> = {
  xs: 12,
  small: 14,
  medium: 16,
  large: 20,
  xl: 24,
} as const;
