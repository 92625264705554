import * as React from 'react';
import { IconPropsType } from '../types';
const SettingsIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.465 3.957c.39-1.61 2.68-1.61 3.07 0a1.58 1.58 0 0 0 2.359.977c1.414-.861 3.034.758 2.173 2.173a1.58 1.58 0 0 0 .976 2.358c1.61.39 1.61 2.68 0 3.07a1.58 1.58 0 0 0-.977 2.359c.861 1.414-.758 3.034-2.173 2.173a1.58 1.58 0 0 0-2.358.976c-.39 1.61-2.68 1.61-3.07 0a1.58 1.58 0 0 0-2.359-.977c-1.414.861-3.034-.758-2.172-2.173a1.58 1.58 0 0 0-.977-2.358c-1.61-.39-1.61-2.68 0-3.07a1.58 1.58 0 0 0 .977-2.359c-.861-1.414.758-3.034 2.173-2.172a1.58 1.58 0 0 0 2.358-.977"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={11}
      cy={11}
      r={2.75}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SettingsIcon;
