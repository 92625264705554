import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th<{ theme: ThemeType }>`
  padding: 8px;
  background: var(--white);

  border: 1px solid var(--alto);

  white-space: nowrap;

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.semibold};

  text-align: left;
`;

export const TableData = styled.td<{ theme: ThemeType }>`
  padding: 8px;
  border: 1px solid var(--alto);

  background: var(--white);
  text-align: left;
  white-space: nowrap;

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};
`;

export const TableRow = styled.tr`
  /* background: var(--white); */
`;
