import { IconPropsType } from '../types';

const EquilibriumIcon = ({ size = 24, fill = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.56 1.792a.6.6 0 0 0 .032.848l1.68 1.56H5.4a.6.6 0 0 0 0 1.2h6.872l-1.68 1.56a.6.6 0 0 0 .816.88l2.8-2.6a.6.6 0 0 0 0-.88l-2.8-2.6a.6.6 0 0 0-.848.032m-5.12 6.4a.6.6 0 0 0-.848-.032l-2.8 2.6a.6.6 0 0 0 0 .88l2.8 2.6a.6.6 0 1 0 .816-.88l-1.68-1.56H10.6a.6.6 0 0 0 0-1.2H3.728l1.68-1.56a.6.6 0 0 0 .032-.848"
      fill={fill}
    />
  </svg>
);
export default EquilibriumIcon;
