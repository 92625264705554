import { useState } from 'react';
import {
  Button,
  IconButton,
  Input,
  Modal,
  RadioGroup,
  SelectDropdown,
  Text,
  TextArea,
} from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { RefundModeEnumSchema, RefundModeType, StorePickupsDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';
import { RETURN_REASONS } from '../../OrderDetails/constants';
import {
  rejectStorePickup,
  setStorePickups,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';

interface IRejectPickupProps {
  record: StorePickupsDTOType;
}

const RejectPickup = ({ record }: IRejectPickupProps) => {
  const salesStaffRecords = useAppSelector(store => store.common.storeStaff.data.records);
  const storePickups = useAppSelector(store => store.pendingTasks.storePickups.data);
  const [isLoading, isLoadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  const [isModalOpen, modalOpenActions] = useBoolean();
  const [stage, setStage] = useState<1 | 2>(1);

  const salesStaffOptions = Object.values(salesStaffRecords);

  const [salesStaffId, setSalesStaffId] = useState<number | null>(null);
  const [reason, setReason] = useState('');
  const [refundMode, setRefundMode] = useState<RefundModeType>(
    RefundModeEnumSchema.Enum.STORE_CREDIT
  );

  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    ifsc: '',
    bankName: '',
    accountName: '',
  });

  const isEligibleForRefund = record.costBreakup.amountPaid
    ? record.costBreakup.amountPaid > 0
    : false;

  function getIsSubmitButtonDisabled() {
    return !salesStaffId || !reason || !refundMode;
  }

  function onSubmit() {
    // Validation here

    if (!salesStaffId) {
      return;
    }

    const payload = {
      refundMode: refundMode,
      rtoReason: reason,
      salesStaffId: salesStaffId,
    };

    if (refundMode === 'BANK_ACCOUNT') {
      payload.bankAccount = bankDetails;
    }

    isLoadingActions.on();

    dispatch(
      rejectStorePickup(record.id, payload, {
        successCallback: () => {
          isLoadingActions.off();
          setStage(2);
        },
      })
    );
  }

  return (
    <div>
      <IconButton
        icon="RejectPackageIcon"
        shapeVariant="circle"
        sizeVariant={'small'}
        onClick={() => {
          modalOpenActions.on();
        }}
      />
      <Modal
        heading={<Text fontSize={20}>Cancellation Form</Text>}
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        size={'small'}
      >
        {stage === 1 && (
          <Styles.Wrapper>
            <Styles.FieldWrapper>
              <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
                Sales POC
              </Text>
              <SelectDropdown<(typeof salesStaffOptions)[number]>
                sizeVariant="small"
                options={salesStaffOptions}
                labelField="name"
                valueField="id"
                onChange={selectedValues => {
                  const newValue = selectedValues[0];
                  setSalesStaffId(newValue.id);
                  // dispatch(upsertSalesStaffInOrder(data.id, newValue.id));
                }}
                values={[salesStaffOptions.find(item => item.id === salesStaffId) ?? {}]}
                dropdownHeight="250px"
                width={150}
              />
            </Styles.FieldWrapper>
            <Styles.FieldWrapper>
              <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
                Reason For Rejection
              </Text>
              <SelectDropdown<(typeof RETURN_REASONS)[number]>
                className="half"
                sizeVariant="small"
                options={RETURN_REASONS}
                labelField="label"
                valueField="value"
                onChange={selectedValues => {
                  const newValue = selectedValues[0];
                  if (newValue) {
                    setReason(newValue.value);
                  } else {
                    setReason('');
                  }
                }}
                values={[RETURN_REASONS.find(item => item.value === reason) ?? {}]}
                dropdownHeight="250px"
              />
            </Styles.FieldWrapper>
            {isEligibleForRefund && (
              <Styles.FieldWrapper>
                <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
                  Refund Mode
                </Text>
                <RadioGroup
                  horizontal
                  name="refund-mode"
                  options={[
                    {
                      label: 'Store Credit',
                      value: RefundModeEnumSchema.Enum.STORE_CREDIT,
                    },
                    {
                      label: 'Cash',
                      value: RefundModeEnumSchema.Enum.CASH,
                    },
                    {
                      label: 'Bank Account',
                      value: RefundModeEnumSchema.Enum.BANK_ACCOUNT,
                    },
                  ]}
                  selectedValue={refundMode ?? ''}
                  onChange={selectedValue => {
                    setRefundMode(selectedValue as RefundModeType);
                  }}
                />
              </Styles.FieldWrapper>
            )}

            {refundMode === 'BANK_ACCOUNT' && (
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                  flexDirection: 'column',
                }}
              >
                <Input
                  label="Account Holder's Name"
                  value={bankDetails.accountName}
                  onChange={e => {
                    setBankDetails({
                      ...bankDetails,
                      accountName: e.target.value,
                    });
                  }}
                  sizeVariant="extraSmall"
                  variant="outline"
                />
                <Input
                  label="Bank Name"
                  value={bankDetails.bankName}
                  onChange={e => {
                    setBankDetails({
                      ...bankDetails,
                      bankName: e.target.value,
                    });
                  }}
                  sizeVariant="extraSmall"
                  variant="outline"
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                  }}
                >
                  <Input
                    label="Account Number"
                    value={bankDetails.accountNumber}
                    sizeVariant="extraSmall"
                    variant="outline"
                    isFullWidth
                    onChange={e => {
                      setBankDetails({
                        ...bankDetails,
                        accountNumber: e.target.value,
                      });
                    }}
                  />
                  <Input
                    sizeVariant="extraSmall"
                    variant="outline"
                    isFullWidth
                    label="IFSC"
                    value={bankDetails.ifsc}
                    onChange={e => {
                      setBankDetails({
                        ...bankDetails,
                        ifsc: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <Button
              onClick={() => {
                onSubmit();
              }}
              sizeVariant="large"
              isDisabled={getIsSubmitButtonDisabled()}
              isFullWidth
              isLoading={isLoading}
            >
              SUBMIT
            </Button>
          </Styles.Wrapper>
        )}
        {stage === 2 && (
          <Styles.Wrapper>
            <Text fontSize={24} weight="bold" textAlign="center">
              Item rejected by customer
            </Text>
            <Text fontSize={20} textAlign="center">
              Item status is updated.
            </Text>
            <Button
              onClick={() => {
                dispatch(
                  setStorePickups({
                    data: {
                      ids: storePickups.ids.filter(id => id !== record.id),
                      records: {
                        ...storePickups.records,
                      },
                    },
                    isLoading: false,
                    isSuccess: true,
                  })
                );

                modalOpenActions.off();
              }}
              sizeVariant="large"
              isFullWidth
              // isLoading={isLoading}
            >
              BACK TO PENDING TASKSLIST
            </Button>
          </Styles.Wrapper>
        )}
      </Modal>
    </div>
  );
};

export default RejectPickup;
