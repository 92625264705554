import { Text } from '@point-of-sale/components';
import styled, { css } from 'styled-components';
import { ServiceChargesPropsType } from '.';

export const Wrapper = styled.div<{
  $level: ServiceChargesPropsType['level'];
  $blank: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ $level }) =>
    $level === 'ITEM' &&
    css`
      margin-top: 12px;
      border: 1px solid var(--athens-gray);
    `}

  ${({ $level }) =>
    $level === 'TOP' &&
    css`
      /* border-top: 1px solid var(--alto); */
      border-bottom: 1px solid var(--alto);
    `}

  ${({ $blank }) =>
    $blank
      ? css`
          padding: 0;
          border: none !important;
        `
      : css`
          padding: 8px 16px;
          /* border-bottom: 1px solid var(--alto); */
        `}
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Heading = styled(Text)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ModalContent = styled.div`
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const CustomChargesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  margin-top: 8px;
`;

export const CustomChargesList = styled.div`
  max-height: 100px;
  overflow-y: scroll;
`;
