import { DeliveryModeType, FulfillmentModeType, LineItemDTOType } from '@point-of-sale/schemas';
import {
  Button,
  CheckBox,
  CheckFilledIcon,
  Image,
  SelectDropdown,
  Text,
} from '@point-of-sale/components';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import {
  formatNumberToLocale,
  formatToReadableDate,
  openInNewTab,
  titleCase,
  updateAttributesOfCdnImage,
} from '@point-of-sale/utils';
import KeyValuePair from '../KeyValuePair';
import { useOrderDetailsContext } from '../context';
import LineItemStatusGraph from '../LineItemStatusGraph';

import ReturnsAndExchangePolicy from '../../ReturnsAndExchangePolicy';
import RemarksAndCustomizations from './RemarksAndCustomizations';
import * as Styles from './styles';
import { useAppSelector } from '@point-of-sale/store';
import { CANCELLATION_REASONS, RETURN_REASONS } from '../constants';

interface ILineItemProps {
  data: LineItemDTOType;
}

const LineItem = ({ data }: ILineItemProps) => {
  const { lineItemsSelection, onToggleLineItemSelection, reasons, onReasonChange, operation } =
    useOrderDetailsContext();

  // const [isAlterationModalVisible, alterationModalVisibleAction] = useBoolean();

  const facilities = useAppSelector(state => state.common.facilities.data.records);

  const currency =
    CURRENCY_SYMBOLS[(data.costBreakup?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  // if (operation === 'RETURNS_AND_EXCHANGES' || operation === 'CANCELLATION') {
  //   return <PostPurchaseOperations data={data} currency={currency} />;
  // }

  function getDeliveryModeNotFulfillmentModeText(deliveryMode: DeliveryModeType): string {
    if (deliveryMode === 'STORE_PURCHASE') {
      return 'On hand';
    }

    if (deliveryMode === 'STORE_PICKUP') {
      const pickupFacility = Object.values(facilities).find(
        facility => facility.id === data.customerDeliveryStoreId
      );
      return `Store pickup ${pickupFacility?.name ? `(${pickupFacility.name})` : ''}`;
    }
    if (deliveryMode === 'HOME_DELIVERY') {
      return 'Home delivery';
    }

    return 'Ship to store';
  }

  function getFulfillmentModeText(fulfillmentMode: FulfillmentModeType): string {
    if (fulfillmentMode === 'JIT') {
      return 'JIT';
    }

    if (fulfillmentMode === 'MTC') {
      return 'MTC';
    }

    if (fulfillmentMode === 'MTO') {
      return 'MTO';
    }

    if (fulfillmentMode === 'ONHAND') {
      return 'Store Purchase';
    }

    return 'Store Purchase';
  }

  // todo: refactor
  function getReasonsList() {
    if (operation === 'RETURNS_AND_EXCHANGES') {
      return RETURN_REASONS;
    }

    if (operation === 'CANCELLATION') {
      return CANCELLATION_REASONS;
    }

    return RETURN_REASONS;
  }

  const options = getReasonsList();

  const isCancelable = data.cancellable;

  function getIsOperationEnabled() {
    if (operation === 'RETURNS_AND_EXCHANGES') {
      return data.currentlyReturnable || data.currentlyExchangeable;
    }

    if (operation === 'CANCELLATION') {
      return isCancelable;
    }

    return false;
  }

  const isOperationEnabled = getIsOperationEnabled();
  const isReturned = data.status === 'RETURNED';
  const isCancelled = data.status === 'CANCELLED';

  const ReturnBillButton = () =>
    data.refundRequest?.refundReceiptAsset?.url ? (
      <Button
        variant="outline"
        rightIcon="LinkIcon"
        onClick={() => {
          openInNewTab(data.refundRequest?.refundReceiptAsset?.url ?? '');
        }}
        sizeVariant="xs"
      >
        View Return Bill
      </Button>
    ) : null;
  // (
  //   <Button
  //     variant="outline"
  //     colorScheme="dark"
  //     rightIcon="ReceiptIcon"
  //     onClick={() => {
  //       // openInNewTab(data.refundRequest?.refundReceiptAsset?.url ?? '');
  //     }}
  //     sizeVariant="xs"
  //   >
  //     Generate Return Bill
  //   </Button>
  // );

  return (
    <Styles.Wrapper>
      <Styles.Right>
        <Styles.Top>
          {isOperationEnabled && (
            <Styles.Header>
              <CheckBox
                isChecked={lineItemsSelection[data.id]}
                onChange={() => {
                  onToggleLineItemSelection(data.id);
                }}
                name={data.skuCode}
              />
              <SelectDropdown<(typeof options)[number]>
                className="half"
                sizeVariant="small"
                options={options}
                labelField="label"
                valueField="value"
                onChange={selectedValues => {
                  const newValue = selectedValues[0];

                  if (newValue) {
                    onReasonChange(data.id, newValue.value);
                  } else {
                    onReasonChange(data.id, '');
                  }
                }}
                values={[options.find(item => item.value === reasons[data.id]) ?? {}]}
                dropdownHeight="250px"
              />
            </Styles.Header>
          )}

          {isReturned && (
            <Styles.Header>
              <CheckFilledIcon />
              <Text fontSize={12} color="var(--royal-blue)" weight="bold">
                Return Successful
              </Text>
              <ReturnBillButton />
            </Styles.Header>
          )}
          {isCancelled && (
            <Styles.Header>
              <CheckFilledIcon />
              <Text fontSize={12} color="var(--royal-blue)" weight="bold">
                Cancellation Successful
              </Text>
              <ReturnBillButton />
            </Styles.Header>
          )}
        </Styles.Top>
        <Styles.Top style={{ padding: 12 }}>
          <Image
            height={120}
            width={80}
            src={updateAttributesOfCdnImage(data.productImage?.url, {
              quality: 100,
              width: 120 * 5,
            })}
          />
          <Styles.Column>
            <Text fontSize={14} weight="bold" color="var(--dove-gray)">
              {data.brandName}
              <br />
              <Text as="span" fontSize={14} weight="semibold" color="var(--cod-gray)">
                {data.productName}
              </Text>
            </Text>
            <KeyValuePair title="SKU" value={data.skuCode} />
            <KeyValuePair title="Size" value={data.size} />
            <KeyValuePair title="Color" value={data.color} />
          </Styles.Column>
          <Styles.Column>
            <KeyValuePair
              title="Total"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.chargeableAmount ?? 0)}`}
            />
            <KeyValuePair
              title="SGST"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.sgst ?? 0)}`}
            />
            <KeyValuePair
              title="CGST"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.cgst ?? 0)}`}
            />

            <KeyValuePair
              // WTF
              title="Fulfillment mode"
              value={getDeliveryModeNotFulfillmentModeText(data.deliveryMode)}
            />
            <KeyValuePair title="Order type" value={getFulfillmentModeText(data.fulfillmentMode)} />
          </Styles.Column>
          <Styles.Column>
            <ReturnsAndExchangePolicy
              isExchangeable={data.currentlyExchangeable}
              isReturnable={data.currentlyReturnable}
              fontSize={14}
            />

            {isReturned
              ? data.returnEntry?.createdOn && (
                  <KeyValuePair
                    title="Returned on"
                    value={formatToReadableDate(data.returnEntry?.createdOn)}
                  />
                )
              : data.returnPolicy &&
                operation === 'RETURNS_AND_EXCHANGES' && <KeyValuePair value={data.returnPolicy} />}

            {operation === 'RETURNS_AND_EXCHANGES' && data.currentlyReturnable && (
              <KeyValuePair
                br
                title="Return expires on"
                value={new Date(data.returnableUntil ?? 0).toDateString()}
                valueProps={{
                  color: 'var(--chateau-green)',
                }}
              />
            )}

            {!isCancelable && operation === 'CANCELLATION' && (
              <KeyValuePair value="Item cannot be cancelled" />
            )}

            {operation === 'RETURNS_AND_EXCHANGES' && data.currentlyExchangeable && (
              <KeyValuePair
                br
                title="Exchange expires on"
                value={new Date(data.exchangeableUntil ?? 0).toDateString()}
                valueProps={{
                  color: 'var(--chateau-green)',
                }}
              />
            )}
            {/* {data.alterationEntry && (
              <Button
                variant="ghost"
                rightIcon="LinkIcon"
                onClick={alterationModalVisibleAction.on}
                sizeVariant="xs"
              >
                View Alterations
              </Button>
            )} */}
            <RemarksAndCustomizations data={data} />

            {operation === 'READ_ONLY' && (
              <KeyValuePair title="Status" value={titleCase(data.status)} />
            )}
          </Styles.Column>
        </Styles.Top>
        <Styles.Top style={{ padding: 12 }}>
          <Styles.Column>
            {/* <ReturnsAndExchangePolicy
              isExchangeable={isExchangeable}
              isReturnable={isReturnable}
              fontSize={14}
            /> */}
          </Styles.Column>
          {/* <Styles.Column>

          </Styles.Column> */}
        </Styles.Top>
        <Styles.Bottom style={{ padding: 12 }}>
          <LineItemStatusGraph data={data} />
        </Styles.Bottom>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default LineItem;
