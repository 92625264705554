import { ObjectType } from '@point-of-sale/types';

export function omit<T extends ObjectType, TKey extends keyof T>(
  object: T,
  keysToRemove: Array<TKey>
) {
  const result: ObjectType = {};
  const stringifiedKeys = keysToRemove.map(x => String(x));

  Object.keys(object).forEach(eachKey => {
    if (stringifiedKeys.includes(eachKey)) {
      return;
    }
    result[eachKey] = object[eachKey];
  });

  return result as Omit<T, TKey>;
}

export function pick<T extends ObjectType, TKey extends keyof T>(
  obj: T,
  keys: Array<TKey>
): Pick<T, TKey> {
  return keys.reduce((acc, key) => {
    if (Object.hasOwnProperty.call(obj, key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Pick<T, TKey>);
}

export function setValuesToEmptyString(obj: ObjectType): ObjectType {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        setValuesToEmptyString(obj[key] as ObjectType);
      } else {
        obj[key] = '';
      }
    }
  }
  return obj;
}

export function hasNonEmptyString(obj: ObjectType): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if (hasNonEmptyString(value as ObjectType)) {
          return true;
        }
      } else if (typeof value === 'string' && value.trim() !== '') {
        return true;
      }
    }
  }
  return false;
}
