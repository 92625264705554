import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal, Text, TextArea } from '@point-of-sale/components';
import { AlterationEntryDTOType } from '@point-of-sale/schemas';
import { DIAGRAM_IMAGE, PERSONALIZATION_FIELDS } from './constants';
import { titleCase } from '@point-of-sale/utils';
import * as Styles from './styles';

export interface IPersonalizationProps {
  isOpen: boolean;
  onClose: VoidFunction;
  mode: 'CUSTOMIZATION' | 'ALTERATION';
  alterationEntry: AlterationEntryDTOType;
  color: string;
  size: string;
  onChangeRemarks: (formData: AlterationEntryDTOType) => void;
  isLoading: boolean;
}

const PersonalizationRead = ({
  mode,
  isOpen,
  alterationEntry,
  color,
  size,
  onClose,
  onChangeRemarks,
  isLoading,
}: IPersonalizationProps) => {
  const [formData, setFormData] = useState<AlterationEntryDTOType>({
    referenceColor: '',
    referenceSize: '',
    remarks: '',
    customizationCategory: alterationEntry.customizationCategory,
  });

  const DiagramImage =
    DIAGRAM_IMAGE[alterationEntry.customizationCategory as keyof typeof DIAGRAM_IMAGE];

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      referenceColor: color,
      referenceSize: size,
    }));
  }, [color, size]);

  useEffect(() => {
    if (alterationEntry) {
      setFormData({
        ...alterationEntry,
      });
    }
  }, [alterationEntry]);

  const formSections = useMemo(() => {
    if (!alterationEntry.customizationCategory) {
      return [];
    }

    return alterationEntry.customizationCategory === 'CO_ORD_SET'
      ? [PERSONALIZATION_FIELDS.TOP_WEAR, PERSONALIZATION_FIELDS.BOTTOM_WEAR]
      : [PERSONALIZATION_FIELDS[alterationEntry.customizationCategory]];
  }, [alterationEntry.customizationCategory]);

  type FieldName =
    | keyof AlterationEntryDTOType['bottomwearAlterationEntry']
    | keyof AlterationEntryDTOType['topwearAlterationEntry'];

  const heading = titleCase(mode.toLowerCase());

  return (
    <Modal
      heading={<Text fontSize={20}>{heading} remarks</Text>}
      isOpen={isOpen}
      onClose={onClose}
      size="large"
    >
      <Styles.Wrapper>
        <Styles.Left>
          {/* {skuData && <Preview mode={mode} skuData={skuData} />} */}

          {formSections.map(section => {
            return (
              <Styles.FormSectionContainer key={section.title}>
                <Text fontSize={20}>{section.title}</Text>
                <Styles.FormSection>
                  {section.fields.map(field => {
                    // const value =
                    //   formData[section.accessor]?.[field.name as FieldName];

                    // if (!value) {
                    //   return null;
                    // }

                    return (
                      <Input
                        key={field.name}
                        label={field.label}
                        placeholder={field.label.replace('(inches)', '')}
                        sizeVariant="small"
                        name={field.name}
                        variant="outline"
                        type="number"
                        value={formData?.[section.accessor]?.[field.name as FieldName] ?? ''}
                        onChange={e =>
                          setFormData(prev => ({
                            ...prev,

                            [section.accessor]: {
                              ...prev[section.accessor],
                              [field.name]: e.target.value,
                            },
                          }))
                        }
                        min={0}
                      />
                    );
                  })}
                </Styles.FormSection>
              </Styles.FormSectionContainer>
            );
          })}

          <TextArea
            label="Remarks"
            value={formData.remarks}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFormData(prev => ({ ...prev, remarks: e.target.value }))
            }
          />
          <Button
            colorScheme={'primary'}
            isLoading={isLoading}
            onClick={() => {
              onChangeRemarks({ ...alterationEntry, ...formData });
            }}
          >
            Update Remarks
          </Button>
        </Styles.Left>

        <Styles.Diagram>
          <DiagramImage />
        </Styles.Diagram>
      </Styles.Wrapper>
    </Modal>
  );
};

export default PersonalizationRead;
