import { OrderDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';

interface IContactProps {
  data: OrderDTOType['sellerInfo'];
  fashionConsultant: string | number;
}

const Contact = ({ data, fashionConsultant }: IContactProps) => {
  return (
    <Styles.Text>
      Email: {data?.email}
      <br />
      Phone No. {data?.phone}
      <br />
      GSTIN NO: {data?.gstin}
      <br />
      PAN: {data?.pan}
      <br />
      CIN: {data?.cin}
      <br />
      Fashion Consultant: <b>{fashionConsultant}</b>
    </Styles.Text>
  );
};

export default Contact;
