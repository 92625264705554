import { getCurrentStoreAndWarehouseFacilityIds, ICommonState } from '@point-of-sale/store';
import { CheckBox } from '@point-of-sale/components';
import {
  DeliveryModeType,
  INormalizedServiceabilityData,
  ProductVariantDTOType,
} from '@point-of-sale/schemas';
import { emptyFunction } from '@point-of-sale/utils';
import * as Styles from './styles';
import { getDeliveryDateByServiceability } from './helpers';

interface IFacilitiesListProps {
  facilities: ICommonState['facilities']['data'];

  shouldShowInventory: boolean;
  shouldShowDelivery: boolean;

  variant: ProductVariantDTOType | null;

  onChange: (selectedFacilityId: number, promiseDate: string) => void;

  customerDeliveryLocationId: number | DeliveryModeType;

  serviceabilityData: INormalizedServiceabilityData[string];

  isMTO: boolean;
  isMTC: boolean;

  shouldShowOtherFacilities: boolean;

  defaultTat: number;
}

const FacilitiesList = ({
  facilities,
  shouldShowDelivery,
  shouldShowInventory,
  variant,
  onChange,
  customerDeliveryLocationId,
  serviceabilityData,
  isMTC,
  isMTO,
  shouldShowOtherFacilities,
  defaultTat,
}: IFacilitiesListProps) => {
  const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

  return (
    facilities.ids
      // * SHOW ONLY STORES
      .filter(facilityId => facilities.records[facilityId].facilityType === 'STORE')
      .filter(facilityId => {
        if (shouldShowOtherFacilities) {
          return true;
        }

        return currentStoreAndWarehousesFacilityIds.includes(facilityId);
      })
      .map(facilityId => {
        const date = getDeliveryDateByServiceability(
          serviceabilityData,
          facilityId,
          facilities,
          isMTO,
          isMTC,
          defaultTat
        );

        return (
          <Styles.Tr
            key={facilityId}
            $isSelected={String(facilityId) === customerDeliveryLocationId}
            onClick={() => onChange(facilityId, date)}
          >
            <Styles.Td>
              <CheckBox
                shape="round"
                name={facilities.records[facilityId].name}
                isChecked={String(facilityId) === String(customerDeliveryLocationId)}
                onChange={emptyFunction}
              />
            </Styles.Td>
            <Styles.Td>{facilities.records[facilityId].name}</Styles.Td>

            <Styles.Td>
              {shouldShowInventory && variant?.facilityInventory?.[facilityId].availableInventory}
            </Styles.Td>
            <Styles.Td>
              {shouldShowDelivery && (
                <>
                  {date !== 'EXPRESS' && <>Est. Delivery: &nbsp;</>}
                  {date}
                </>
              )}
            </Styles.Td>
          </Styles.Tr>
        );
      })
  );
};

export default FacilitiesList;
