import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CreditNote = styled.div`
  border: 1px solid var(--alto);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  p {
    width: fit-content;
  }
`;
