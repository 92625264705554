import { motion } from 'framer-motion';

import { IconPropsType } from '../types';

const ChevronRightIcon = ({ fill = 'var(--brown-pod)', ...props }: IconPropsType) => (
  <motion.svg
    width={24}
    height={24}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.158 3.135a.5.5 0 0 1 .707.023l3.75 4a.5.5 0 0 1 0 .684l-3.75 4a.5.5 0 1 1-.73-.684L9.566 7.5l-3.43-3.658a.5.5 0 0 1 .023-.707"
      fill={fill}
    />
  </motion.svg>
);
export default ChevronRightIcon;
