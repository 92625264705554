// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { callApi } from '../services/callApi';
import { AlterationEntryDTOType, PendingPaymentAddDTOType } from '@point-of-sale/schemas';
import { ObjectType } from '@point-of-sale/types';

export const getPendingStorePickupsApi = ({
  page = 1,
  size = 10,
  filters = [],
  orderBy = 'id',
  orderDirection = 'DESC',
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
  orderBy?: string;
  orderDirection?: string;
}) =>
  callApi('POST', `/pos/order/package/searchV2?page=${page}&size=${size}&enrichItems=true`, {
    filters,
    orderBy,
    orderDirection,
  });

export const getPendingCustomizationRemarksApi = ({
  page = 1,
  size = 10,
  filters = [],
  orderBy = 'id',
  orderDirection = 'DESC',
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
  orderBy?: string;
  orderDirection?: string;
}) =>
  callApi('POST', `/pos/order/lineitem/search?page=${page}&size=${size}`, {
    filters,
    orderBy,
    orderDirection,
    enrichOrder: true,
  });

export const updateLineItemAlterationEntryApi = ({
  lineItemId,
  data,
}: {
  lineItemId: number;
  data: AlterationEntryDTOType;
}) => callApi('PUT', `/pos/order/lineitem/${lineItemId}/alteration`, data);

export const markPackageDeliveredApi = ({ packageId }: { packageId: number }) =>
  callApi('PUT', `/pos/order/package/${packageId}/delivered`);

export const collectPendingPaymentApi = ({
  salesChannelOrderId,
  data,
}: {
  salesChannelOrderId: string;
  data: PendingPaymentAddDTOType;
}) => callApi('POST', `/pos/payment/order/${salesChannelOrderId}/transaction/add`, data);

export const getPostOrderPaymentOptionsApi = (orderId: string) =>
  callApi('GET', `/pos/payment/order/${orderId}/options`);

export const rejectStorePickupApi = (packageId: number, data: ObjectType) =>
  callApi('PUT', `/pos/order/package/${packageId}/pickuprejected`, data);
