import { Text } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);

  height: 100%;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 6px 16px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;

  padding-top: 4px;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  /* gap: 4px; */
`;

export const Content = styled.div`
  border-top: 1px solid var(--alto);

  /* height: 500px; */
  overflow-y: auto;

  flex: 1;
`;

export const CartSection = styled.div`
  /* border: 1px solid black; */
`;

export const CartSectionHeading = styled(Text)`
  padding: 8px 16px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid var(--alto);

  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const ContentDivider = styled.div`
  border-top: 4px dashed var(--moon-raker);
  width: 100%;
`;

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  background: var(--white);

  position: sticky;
  bottom: 0;

  box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 8px;

  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const FooterTop = styled.div`
  /* border-top: 1px solid var(--alto); */
  border-bottom: 1px solid var(--alto);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  /* border: 1px solid red; */
`;

export const FooterBottom = styled.div`
  /* border: 1px solid blue; */
`;
