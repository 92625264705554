// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { callApi } from '../services/callApi';

export const getOpenCartsApi = ({
  page = 1,
  size = 10,
  filters = [],
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
}) =>
  callApi('POST', `/pos/cart/saved/search?page=${page}&size=${size}`, {
    filters,
  });
