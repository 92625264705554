import { WithHeader } from '@point-of-sale/components';
import OrdersTable from '../../components/OrdersTable';
import OrderDiscovery from './OrderDiscovery';
import * as Styles from './styles';

const OrderManagementSystem = () => {
  return (
    <Styles.Wrapper>
      <OrderDiscovery />
      <Styles.Content>
        <OrdersTable />
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default WithHeader(OrderManagementSystem, {
  name: 'Order Management System',
});
