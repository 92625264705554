import { Text } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-bottom: 1px solid var(--alto);
`;

export const Heading = styled(Text)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ModalContent = styled.div`
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
`;
