import {
  APIResponseType,
  ApiResponseEnum,
  IDescribedState,
  INormalizedList,
} from '@point-of-sale/types';
import { ZodSchema } from 'zod';
import { ThunkActionType } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, convertArrayToObject } from '@point-of-sale/utils';
import toast from 'react-hot-toast';
import { UnknownAction } from 'redux';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SentryService } from '@point-of-sale/services';

interface IFetchThunkConfig<T extends { id: number }> {
  dispatchable: (payload: Partial<IDescribedState<INormalizedList<T>>>) => UnknownAction;
  dataFetcher: () => Promise<APIResponseType<T[]>>;
  zodSchema: ZodSchema<Array<T>>;
  identifier?: string;
  successCallback?: () => void;
}

export const createListFetchingThunk = <T extends { id: number }>({
  dispatchable,
  dataFetcher,
  zodSchema,
  successCallback,
}: IFetchThunkConfig<T>): ThunkActionType => {
  return async dispatch => {
    dispatch(
      dispatchable({
        isLoading: true,
      })
    );

    const promise = dataFetcher();
    const response = await apiResponseHandler<Array<T>>(promise, zodSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      console.error(`[LIST FETCHING THUNK] ${dispatchable.name}`, response.meta?.message);
      SentryService.catchError(
        new Error(`[LIST FETCHING THUNK] ${dispatchable.name} ${response.meta?.message}`)
      );
      dispatch(
        dispatchable({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
          isSuccess: false,
        })
      );

      return;
    }

    // all the operations on data
    const ids = response.data.map(item => item.id);
    const records = convertArrayToObject(response.data, 'id');

    dispatch(
      dispatchable({
        data: {
          records,
          ids,
        },
        error: null,
        isError: false,
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.();
  };
};
