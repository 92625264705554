import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ModalSizeVariants } from './types';
import { MODAL_WIDTHS, MODAL_CONTENT_MAXIMUM_HEIGHTS } from './constants';

export const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

interface IPropsWithSize {
  $size: ModalSizeVariants;
}

export const Wrapper = styled(motion.div)<IPropsWithSize>`
  border: 1px solid var(--swiss-coffee);
  background: var(--white);
  width: ${({ $size }) => MODAL_WIDTHS[$size]};
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
  z-index: 1;
`;

export const Header = styled.header`
  padding: 20px 24px;
  position: relative;
  border-bottom: 1px solid var(--alto);
`;

export const Content = styled.main<IPropsWithSize>`
  max-height: ${({ $size }) => MODAL_CONTENT_MAXIMUM_HEIGHTS[$size]};
  overflow-y: auto;
`;

export const Footer = styled.footer`
  padding: 20px 24px;
`;
