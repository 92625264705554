import { useNavigate } from 'react-router';
import { Button } from '@point-of-sale/components';
import {
  resetCheckoutState,
  setIsEditCustomerInfoModalOpen,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { openInSameTab } from '@point-of-sale/utils';

interface ICheckoutButtonProps {
  chargeableAmount: string;
}

const CheckoutButton = ({ chargeableAmount }: ICheckoutButtonProps) => {
  const mode = useAppSelector(state => state.pointOfSale.mode);
  const cartId = useAppSelector(state => state.pointOfSale.cart.data.id);

  const cartItems = useAppSelector(state => state.pointOfSale.cart.data.cartItems);

  const containsSomeStorePickupItems = cartItems.ids.some(
    id => cartItems.records[id].deliveryMode === 'STORE_PICKUP'
  );

  const containsSomeHomeDeliveryItems = cartItems.ids.some(
    id => cartItems.records[id].deliveryMode === 'HOME_DELIVERY'
  );
  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const customerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const customerData = useAppSelector(store => store.pointOfSale.customer.data);

  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() => {
        if (!customerData.phone && containsSomeStorePickupItems) {
          dispatch(setIsEditCustomerInfoModalOpen(true));
          return;
        }

        if ((selectedAddressId === -1 || !customerAddress) && containsSomeHomeDeliveryItems) {
          dispatch(setIsEditCustomerInfoModalOpen(true));
          return;
        }

        dispatch(resetCheckoutState());
        openInSameTab(`/point-of-sale/checkout?id=${cartId}`);
      }}
      isFullWidth
      colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      isDisabled={cartItems.ids.length === 0}
    >
      Checkout {chargeableAmount}
    </Button>
  );
};

export default CheckoutButton;
