import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const OperationSwitchContainer = styled.div<{ theme: ThemeType }>`
  display: flex;

  button {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
