import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 16px;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  overflow: auto;

  display: flex;
  gap: 16px;

  flex: 1;
`;
