import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);

  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const SectionHeading = styled.div`
  padding: 16px;
  border-bottom: 1px solid var(--alto);
`;

export const CartContainer = styled.div`
  /* border-bottom: 1px solid var(--alto); */

  /* border: 1px dashed yellow; */

  flex: 1;

  /* border: 1px dashed red; */
`;

export const Section = styled.div`
  /* border-bottom: 1px solid var(--alto); */

  /* border: 1px solid red; */
`;

export const Sticky = styled.div`
  background: var(--white);

  position: sticky;
  bottom: 0;
  z-index: 2;

  border-top: 1px solid var(--alto);

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SectionBody = styled.div`
  padding: 16px;

  /* border: 1px solid brown; */
  /* border-bottom: 1px solid var(--alto); */
`;
