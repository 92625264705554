import { StyledBaseText } from './StyledBaseText';
import type { IBaseTextProps } from './types';

const BaseText = ({
  children,
  as,
  className,
  color,
  fontSize,
  fontStyle,
  fontWeight,
  textAlign,
  textDecorationLine,
  wordBreak,
  display,
  width,
  textTransform,
  lineHeight,
}: IBaseTextProps) => {
  return (
    <StyledBaseText
      as={as}
      className={className}
      $color={color}
      $fontSize={fontSize}
      $fontStyle={fontStyle}
      $fontWeight={fontWeight}
      $textAlign={textAlign}
      $textDecorationLine={textDecorationLine}
      $wordBreak={wordBreak}
      $display={display}
      $width={width}
      $textTransform={textTransform}
      $lineHeight={lineHeight}
    >
      {children}
    </StyledBaseText>
  );
};

export default BaseText;
