import { addDays } from 'date-fns';
import { ICommonState } from '@point-of-sale/store';
import { formatToReadableDate } from '@point-of-sale/utils';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';

export const getDeliveryDateByServiceability = (
  serviceabilityData: INormalizedServiceabilityData[string],
  facilityId: number | undefined,
  facilities: ICommonState['facilities']['data'],
  isMTO: boolean,
  isMTC: boolean,
  defaultTat: number
): string => {
  const defaultDate = formatToReadableDate(addDays(new Date(), defaultTat));

  if (!facilityId) {
    return defaultDate;
  }

  const serviceability = serviceabilityData.find(
    item => item.to === facilities.records[facilityId]?.address?.pincode
  );

  if (isMTO) {
    return serviceability?.info.mtoTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtoTat))
      : defaultDate;
  }

  if (isMTC) {
    return serviceability?.info.mtcTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtcTat))
      : defaultDate;
  }

  if (serviceability?.info.express) {
    return 'EXPRESS';
  }

  return serviceability?.info.bestTat
    ? formatToReadableDate(addDays(new Date(), serviceability?.info.bestTat))
    : defaultDate;
};
