import { z } from 'zod';

export const GstDetailsDTOSchema = z.object({
  principlePlaceOfBusiness: z.object({
    address: z.object({
      buildingName: z.string(),
      buildingNumber: z.string(),
      district: z.string(),
      floorNumber: z.string(),
      geocodeLevel: z.string(),
      landmark: z.string(),
      longitude: z.string(),
      location: z.string(),
      locality: z.string(),
      latitude: z.string(),
      pinCode: z.string(),
      street: z.string(),
      stateCode: z.string().optional(),
    }),
    natureOfTrade: z.string(),
  }),
  additionalPlaceOfBusiness: z.array(
    z.object({
      address: z.object({
        buildingName: z.string(),
        buildingNumber: z.string(),
        district: z.string(),
        floorNumber: z.string(),
        geocodeLevel: z.string(),
        landmark: z.string(),
        longitude: z.string(),
        location: z.string(),
        locality: z.string(),
        latitude: z.string(),
        pinCode: z.string(),
        street: z.string(),
        stateCode: z.string().optional(),
      }),
      natureOfTrade: z.string(),
    })
  ),
  companyType: z.string(),
  companyJurisdiction: z.string(),
  CompanyJurisdictionCode: z.string(),
  companyExitDate: z.string(),
  companyDuty: z.string(),
  eInvoiceStatus: z.string(),
  gstin: z.string(),
  legalName: z.string(),
  lastUpdate: z.string(),
  natureOfBusinessActivity: z.array(z.string()).nullable(),
  registrationDate: z.string(),
  stateJurisdiction: z.string(),
  stateJurisdictionCode: z.string(),
  status: z.string(),
  tradeName: z.string(),
});

export type GstDetailsDTOType = z.infer<typeof GstDetailsDTOSchema>;
