import { IconPropsType } from '../types';

const OrdersBoldIcon = (props: IconPropsType) => (
  <svg
    width={58}
    height={58}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.708 6.6h.001a6.7 6.7 0 0 1 6.691 6.69v19.335a1.858 1.858 0 1 1-3.717 0V13.292M44.708 6.6v3.067a3.63 3.63 0 0 1 3.625 3.625h-.65M44.708 6.6H13.291A6.7 6.7 0 0 0 6.6 13.29v31.42a6.7 6.7 0 0 0 6.691 6.691h16.917a1.858 1.858 0 1 0 0-3.717H13.292M44.708 6.6 13.292 47.683m34.391-34.391a2.98 2.98 0 0 0-2.975-2.975m2.975 2.975-2.975-2.975m0 0H36.9v12.641a1.86 1.86 0 0 1-1.858 1.859H22.958a1.86 1.86 0 0 1-1.858-1.859V10.317h-7.808a2.98 2.98 0 0 0-2.975 2.975v31.416a2.98 2.98 0 0 0 2.975 2.975m31.416-37.366-14.5 40.433a1.209 1.209 0 0 0 0-2.417H13.292v-.65M33.183 21.1h-8.366V10.317h8.366zm8.76 21.75.452-.453.008-.008a1.859 1.859 0 0 0-2.627-2.628l-.008.008-3.624 3.624a1.86 1.86 0 0 0 0 2.63l3.62 3.62a1.86 1.86 0 0 0 3.04-2.028 1.9 1.9 0 0 0-.412-.6l-.45-.448h7.598a1.859 1.859 0 0 0 0-3.717z"
      fill="var(--haiti)"
      stroke="var(--haiti)"
      strokeWidth={1.3}
    />
  </svg>
);
export default OrdersBoldIcon;
