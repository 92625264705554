import { z } from 'zod';

export const RoleDTOSchema = z
  .object({
    createdBy: z.string(),
    createdOn: z.string(),
    lastModifiedOn: z.string(),
    version: z.number(),
    id: z.number(),
    name: z.string(),
    portalCode: z.string(),
    permissions: z.array(z.string()),
  })
  .strict();

export type RoleDTOType = z.infer<typeof RoleDTOSchema>;

export const StoreStaffDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  userType: z.string(),
  name: z.string(),
  email: z.string(),
  lastLoginTime: z.string().optional(),
  isActive: z.boolean(),
  storeSalesChannelIds: z.array(z.number()),
  roles: z.array(RoleDTOSchema),
  adminUserAttributes: z.object({}),
  profileImageUrl: z.string().optional(),
});
// .strict();

export type StoreStaffDTOType = z.infer<typeof StoreStaffDTOSchema>;
