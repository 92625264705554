import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--zumthor);
  padding: 24px;
  display: flex;
`;

export const Left = styled.div`
  width: 5%;
`;

export const Right = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
`;
