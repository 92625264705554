import { CssVariableType } from '../tokens/types';
import { ComplexionType } from '../types';

export const COMPLEXION_VARIANTS: Record<
  ComplexionType,
  {
    primaryColor: CssVariableType;
    secondaryColor: CssVariableType;
    tertiaryColor: CssVariableType;
    quaternaryColor: CssVariableType;
  }
> = {
  primary: {
    primaryColor: 'var(--geraldine)',
    secondaryColor: 'var(--cosmos)',
    tertiaryColor: 'var(--chablis)',
    quaternaryColor: 'var(--sundown)',
  },
  secondary: {
    primaryColor: 'var(--royal-blue)',
    secondaryColor: 'var(--zumthor)',
    tertiaryColor: 'var(--link-water)',
    quaternaryColor: 'var(--water)',
  },
  success: {
    primaryColor: 'var(--chateau-green)',
    secondaryColor: 'var(--silver-tree)',
    tertiaryColor: 'var(--granny-apple)',
    quaternaryColor: 'var(--chateau-green)',
  },
  danger: {
    primaryColor: 'var(--carnation)',
    secondaryColor: 'var(--geraldine)',
    tertiaryColor: 'var(--chablis)',
    quaternaryColor: 'var(--carnation)',
  },
  warning: {
    primaryColor: 'var(--tulip-tree)',
    secondaryColor: 'var(--romantic)',
    tertiaryColor: 'var(--island-spice)',
    quaternaryColor: 'var(--seashell-peach)',
  },
  accent: {
    primaryColor: 'var(--purple)',
    secondaryColor: 'var(--moon-raker)',
    tertiaryColor: 'var(--titan-white)',
    quaternaryColor: 'var(--selago)',
  },
  info: {
    primaryColor: 'var(--royal-blue)',
    secondaryColor: 'var(--zumthor)',
    tertiaryColor: 'var(--water)',
    quaternaryColor: 'var(--link-water)',
  },
  neutral: {
    primaryColor: 'var(--big-stone)',
    secondaryColor: 'var(--athens-gray)',
    tertiaryColor: 'var(--alabaster)',
    quaternaryColor: 'var(--gallery)',
  },
};
