import { useIsPresent } from 'framer-motion';
import * as Styles from './styles';

interface IOptionItemProps {
  label: string | number;
  onClick: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
}

const OptionItem = ({ label, isDisabled, isSelected, onClick }: IOptionItemProps) => {
  //   const FADE_UP_ANIMATION_VARIANTS = {
  //     hidden: { opacity: 0, y: 10 },
  //     show: { opacity: 1, y: 0, transition: { type: 'spring' } },
  //   };

  return (
    <Styles.OptionItem
      onClick={() => {
        if (isDisabled || isSelected) {
          return;
        }
        onClick();
      }}
      $isDisabled={isDisabled}
      $isSelected={isSelected}
      whileTap={isDisabled || isSelected ? {} : { scale: 0.95 }}
      //   variants={{
      //     hidden: { opacity: 0, y: 10 },
      //     show: {
      //       opacity: 1,
      //       y: 0,
      //       transition: { type: 'spring', duration: 0.2 },
      //     },
      //   }}
    >
      {label}
    </Styles.OptionItem>
  );
};

export default OptionItem;
