import { z } from 'zod';
import { AddressDTOSchema } from '../customer';

export const SalesChannelDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number().int(),
  id: z.number().int(),
  name: z.string(),
  salesChannelType: z.enum(['POS', 'SHOPIFY']),
  enabled: z.boolean(),
  facilityId: z.number().int().optional(),
  // TODO: Remove default value after API is fixed
  numPosTerminals: z.number().int().optional(),
});

export type SalesChannelDTOType = z.infer<typeof SalesChannelDTOSchema>;

export const FacilityDTOSchema = z.object({
  address: AddressDTOSchema.extend({ id: z.number().optional() }),
  facilityType: z.enum(['WAREHOUSE', 'STORE', 'VENDOR']),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  name: z.string(),
  enabled: z.boolean(),
  grnEnabled: z.boolean(),
  salesChannelId: z.number().optional(),
  facilityGroupId: z.number().optional(),
  unicomFacilityCode: z.string().optional(),
  jitInvSyncEnabled: z.boolean().optional(),
  gstin: z.string().optional(),
  isOnline: z.boolean().optional(),
});

export type FacilityDTOType = z.infer<typeof FacilityDTOSchema>;

export const CostBreakupDTOSchema = z.object({
  mrp: z.number().optional(),
  currency: z.string().optional(),
  tradeDiscount: z.number().optional(),
  sellingPrice: z.number().optional(),
  cgst: z.number().optional(),
  sgst: z.number().optional(),
  igst: z.number().optional(),
  taxValue: z.number().optional(),
  taxableValue: z.number().optional(),
  couponDiscount: z.number().optional(),
  shippingCharge: z.number().optional(),
  codCharge: z.number().optional(),
  chargeableAmount: z.number().optional(),
  amountPaid: z.number().optional(),
  amountRemaining: z.number().optional(),
  customCharges: z
    .array(
      z.object({
        amount: z.number(),
        type: z.string(),
        remarks: z.string().optional(),
        taxClassPerc: z.number().optional(),
        taxValue: z.number().optional(),
        taxableValue: z.number().optional(),
      })
    )
    .optional(),
  chargeableAmountExCustomCharges: z.number().optional(),
  customChargesViaOrder: z
    .array(
      z.object({
        amount: z.number(),
        type: z.string(),
        remarks: z.string().optional(),
        taxClassPerc: z.number(),
      })
    )
    .optional(),
});

export type CostBreakupDTOType = z.infer<typeof CostBreakupDTOSchema>;

export const InvoiceAssetSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  type: z.string(),
  bucket: z.string(),
  path: z.string(),
  usage: z.string(),
  source: z.string(),
  url: z.string(),
  uploaded: z.boolean(),
});

export type InvoiceAssetType = z.infer<typeof InvoiceAssetSchema>;
