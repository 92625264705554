import { DependencyList, useEffect, useRef } from 'react';

function debounce<T extends (...args: any) => any>(func: T, duration: number) {
  let timeout: NodeJS.Timeout | null;

  function clear() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  function implementation(...args: Array<unknown>) {
    clear();

    timeout = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
      clear();
    }, duration);
  }

  return {
    clear,
    implementation,
  };
}

export function useDebounce<T extends (...args: any) => any>(
  callback: T,
  duration: number,
  deps?: DependencyList
) {
  const debounceRef = useRef(debounce(callback, duration));

  useEffect(() => {
    debounceRef.current = debounce(callback, duration);
    return () => {
      debounceRef.current.clear();
    };
  }, [duration, ...(deps ?? [])]);

  return debounceRef.current;
}
