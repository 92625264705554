import { IconPropsType } from '../icons/types';
import { ButtonColorSchemeType, ButtonVariantType } from './types';

// TODO: Fix convoluted logic
export const getIconProps = (
  colorScheme: ButtonColorSchemeType,
  variant: ButtonVariantType
): IconPropsType => {
  if (colorScheme === 'primary' || colorScheme === 'secondary' || colorScheme === 'dark') {
    if (variant === 'outline') {
      return { stroke: 'var(--royal-blue)' };
    }

    return { stroke: 'var(--white)' };
  }

  return { stroke: 'var(--royal-blue)' };
};
