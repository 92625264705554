import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  background: var(--white);

  border-radius: 8px;
  position: relative;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Top = styled.div`
  /* height: 20vh; */
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 100%;
  padding: 16px;

  overflow: hidden;
`;

export const Left = styled.div`
  width: 70%;

  display: flex;
  flex-direction: column;
  /* gap: 12px; */

  /* height: 70vh; */

  /* border: 1px solid var(--athens-gray); */
`;

export const Right = styled.div`
  width: 30%;
  /* height: 60vh; */
  /* padding: 16px; */

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LeftTop = styled.div<{ $doesTopHaveBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;
  padding: 16px 0;

  z-index: 1;

  label {
    margin: 0;
  }

  /* transition: border-bottom linear 0.3s; */

  ${({ $doesTopHaveBorder }) =>
    $doesTopHaveBorder
      ? css`
          border: 1px solid var(--athens-gray);
          /* background: var(--zircon); */
          /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
        `
      : css`
          border: 1px solid var(--white);
        `}

  transition: border 0.3s;
`;

export const LineItemsContainer = styled.div`
  padding-top: 16px;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  cursor: progress;
`;
