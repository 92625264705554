import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  gap: 24px;
  background: var(--white);

  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const PrintableWrapper = styled.div`
  position: relative;
  overflow: hidden;

  border: 1px solid red;
`;

export const PrintableAction = styled.div`
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--white); */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrintableContent = styled.div``;
