import { Button, Input } from '@point-of-sale/components';
import { collectPendingPayment, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useEffect, useState } from 'react';
import { CartTransactionDTOType } from '@point-of-sale/schemas';
import { useBoolean } from '@point-of-sale/hooks';
import { usePaymentCollectionContext } from '../context';
import { PropsWithSalesPoc } from '../types';
import * as Styles from './styles';

const Cash = ({ salesPoc }: PropsWithSalesPoc) => {
  const { data } = useAppSelector(store => store.checkout.cartTransactions);

  const { costBreakup, salesChannelOrderId, lineItemIds, packageId } =
    usePaymentCollectionContext();

  const [cashValue, setCashValue] = useState('');

  const [returnableAmount, setReturnableAmount] = useState('');

  const [isUpsertLoading, upsertLoadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  const onAddCash = () => {
    upsertLoadingActions.on();
    dispatch(
      collectPendingPayment(
        salesChannelOrderId,
        {
          amount: Number(cashValue) - Number(returnableAmount),
          changeAmount: Number(returnableAmount),
          paymentGateway: 'CASH',
          paymentMode: 'CASH',
          lineItemIds: lineItemIds,
          packageId: packageId,
        },
        {
          successCallback: upsertLoadingActions.off,
          failCallback: upsertLoadingActions.off,
        }
      )
    );
  };

  const verifiedCashTransaction = Object.values(data.records).find(
    (t: CartTransactionDTOType) =>
      t.paymentMode === 'CASH' && t.status === 'VERIFIED' && t.paymentGateway === 'CASH'
  );

  useEffect(() => {
    if (verifiedCashTransaction?.amount) {
      setCashValue(String(verifiedCashTransaction.amount));
    }
  }, [verifiedCashTransaction]);

  return (
    <Styles.Wrapper>
      {/* <Styles.Row>
        <Input
          label="Cash"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          placeholder="Enter cash amount"
          // isFullWidth
          value={cashValue}
          onKeyDown={e =>
            (e.key === 'e' || e.key === 'E') && e.preventDefault()
          }
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            setCashValue(e.target.value);

            if (!costBreakup) {
              setReturnableAmount('');
              return;
            }

            const difference =
              Number(e.target.value) - (costBreakup.amountRemaining ?? 0);
            if (difference > 0) {
              setReturnableAmount(String(difference.toFixed(2)));
            } else {
              setReturnableAmount('');
            }
          }}
        />
        <Input
          label="Return Amount"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          // isFullWidth
          value={returnableAmount}
          onKeyDown={e =>
            (e.key === 'e' || e.key === 'E') && e.preventDefault()
          }
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            setReturnableAmount(e.target.value);
          }}
        />
        <Button
          isLoading={isUpsertLoading}
          onClick={onAddCash}
          isDisabled={Number(cashValue) <= 0 || !salesPoc}
        >
          {verifiedCashTransaction?.amount ? 'Update Cash' : 'Add Cash'}
        </Button>
      </Styles.Row> */}
      <Styles.Row>
        <Input
          label="Cash"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          placeholder="Enter cash amount"
          // isFullWidth
          value={cashValue}
          onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            if (!costBreakup) {
              setReturnableAmount('');
              return;
            }

            setCashValue(e.target.value);

            const difference = Number(e.target.value) - (costBreakup.amountRemaining ?? 0);
            if (difference > 0) {
              setReturnableAmount(String(difference));
            } else {
              setReturnableAmount('');
            }
          }}
        />
        <Input
          label="Return Amount"
          className="cash-input"
          variant="outline"
          sizeVariant="small"
          type="number"
          // isFullWidth
          value={returnableAmount}
          onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
          onChange={e => {
            if (Number(e.target.value) < 0) {
              return;
            }

            setReturnableAmount(e.target.value);
          }}
        />
        <Button
          isLoading={isUpsertLoading}
          onClick={onAddCash}
          isDisabled={Number(cashValue) <= 0 || !salesPoc}
        >
          {verifiedCashTransaction?.amount ? 'Update Cash' : 'Add Cash'}
        </Button>
      </Styles.Row>
    </Styles.Wrapper>
  );
};

export default Cash;
