import React, { ChangeEvent, Fragment, MouseEvent } from 'react';
import RadioButton from '../RadioButton';
import styled from 'styled-components';

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;

  /* border: 1px solid black; */
  flex-wrap: wrap;
`;

const StyledRadioButton = styled(RadioButton)`
  /* border: 1px solid red; */
  flex-grow: 1;
`;

export interface RadioGroupProps {
  name: string;
  options: { label: string; value: string | number }[];
  selectedValue: string;
  onChange?: (value: string) => void;
  horizontal?: boolean;
  onClick?: (value: string) => void;
}

const RadioGroup = ({
  name,
  options,
  selectedValue,
  onChange,
  onClick,
  horizontal = false,
}: RadioGroupProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };
  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    onClick?.(event.currentTarget.value);
  };

  const Wrapper = horizontal ? HorizontalWrapper : Fragment;

  return (
    <Wrapper>
      {options.map(option => (
        <StyledRadioButton
          key={option.value}
          label={option.label}
          value={option.value}
          name={name}
          checked={selectedValue === option.value}
          onChange={handleChange}
          onClick={handleClick}
        />
      ))}
    </Wrapper>
  );
};

export default RadioGroup;
