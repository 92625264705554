import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Left = styled.div`
  min-width: 70px;
  width: 70px;
`;

export const Right = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
`;
