import { ThunkActionType } from '../store';

/**
 * Dispatches an array of thunks sequentially.
 * @param dispatch - The Redux dispatch function.
 * @param thunks - An array of thunk action creators.
 * @returns A promise that resolves when all thunks have been dispatched.
 */
export const dispatchThunksSequentially = async (
  dispatch: (action: ThunkActionType) => Promise<void>,
  thunks: Array<[(...args: Array<any>) => ThunkActionType, ...any[]]>
): Promise<void> => {
  for (const [thunk, ...args] of thunks) {
    try {
      await dispatch(thunk(...args));
    } catch (error) {
      console.error('Error dispatching thunk:', error);
      throw error; // Optional: Rethrow to stop further processing if needed
    }
  }
};
