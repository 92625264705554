import { z } from 'zod';

export const FacetDTOSchema = z.object({
  key: z.object({ key: z.string(), displayName: z.string() }),
  values: z.array(z.object({ id: z.string(), value: z.string(), count: z.number() })),
});

export type FacetDTOType = z.infer<typeof FacetDTOSchema>;

export const FacetsDTOSchema = z.array(FacetDTOSchema);

export type FacetsDTOType = z.infer<typeof FacetsDTOSchema>;
