import { IconPropsType } from '../types';

const RejectPackageIcon = ({ size = 18, stroke = 'var(--cod-gray)', ...props }: IconPropsType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3647_969)">
        <path d="M18 0H0V18H18V0Z" fill="white" fillOpacity={0.01} />
        <path
          d="M3.25 9.2304V2.125C3.25 1.50368 3.75368 1 4.375 1H15.625C16.2463 1 16.75 1.50368 16.75 2.125V5.8864"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.75 5.75V14.125C16.75 14.3321 16.5821 14.5 16.375 14.5H7"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 1.25V5.70969C7.5 6.12894 7.98497 6.36203 8.31235 6.10012L9.3753 5.24976C9.74052 4.95758 10.2595 4.95758 10.6247 5.24976L11.6877 6.10012C12.015 6.36203 12.5 6.12894 12.5 5.70969V1.25"
          stroke={stroke}
        />
        <circle cx={4} cy={12.75} r={3.5} stroke={stroke} />
        <path
          d="M2.5 11.25L5.4965 14.25M5.5 11.25L2.5035 14.25"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_969">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RejectPackageIcon;
