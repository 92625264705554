export const MULTI_COLORS = [
  '#ff003c', // Bright Red
  '#00ff92', // Neon Green
  '#0055ff', // Bright Blue
  '#ffc400', // Vivid Yellow

  '#00ffd5', // Aquamarine
  '#ff6600', // Bright Orange

  '#ff9966', // Peach
  '#99ff33', // Neon Green
  '#9900ff', // Deep Purple

  '#ff66cc', // Bubblegum Pink

  '#3399ff', // Vibrant Blue
  '#ffff33', // Bright Yellow
];
