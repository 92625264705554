import { SearchBar } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-wrap: wrap;

  gap: 20px;
`;

export const SearchInput = styled(SearchBar)`
  min-width: 20vw;
  max-width: 50vw;
`;
