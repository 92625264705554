import styled, { css } from 'styled-components';
import { INPUT_HEIGHTS, FONT_SIZES, FONT_WEIGHTS, ICON_PADDINGS } from './constants';
import { InputSizeVariantType, InputVariantType } from './types';
import { ThemeType } from '../themes/types';

interface IWrapperProps {
  $isFullWidth: boolean;

  $sizeVariant: InputSizeVariantType;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  // *─── Dynamic Properties ──────────────────────────────────────────────────────

  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div<{ $variant: InputVariantType }>`
  background: var(--white);
  display: flex;

  ${props => {
    if (props.$variant === 'outline') {
      return css`
        border: 1px solid var(--alto);
        border-radius: 1px;
      `;
    }
  }}
`;

export const IconWrapper = styled.div<{ $sizeVariant: InputSizeVariantType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => ICON_PADDINGS[props.$sizeVariant]};
`;

interface IInputProps {
  $sizeVariant: InputSizeVariantType;
  theme: ThemeType;
}

export const Input = styled.input<IInputProps>`
  // *─── Static Properties ───────────────────────────────────────────────────────

  width: 100%;
  /* padding: 0 px; */
  color: var(--cod-gray);
  background: var(--white);

  border: none;
  outline: none;

  &:disabled {
    background: var(--titan-white);
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    color: var(--brown-pod) !important;
    -webkit-text-fill-color: var(--brown-pod) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }
  &::placeholder {
    /* color: var(--alto); */
    color: var(--dove-gray);
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  // *─── Dynamic Properties ──────────────────────────────────────────────────────

  height: ${props => INPUT_HEIGHTS[props.$sizeVariant]}px;

  font-size: ${props => props.theme.typography.fonts.size[FONT_SIZES[props.$sizeVariant]]};

  font-weight: ${props => props.theme.typography.fonts.weight[FONT_WEIGHTS[props.$sizeVariant]]};

  padding-left: ${props => (props.$sizeVariant === 'extraSmall' ? '8px' : '20px')};
  /* Whys is this even */
  /* padding-left: ${props => (props.$sizeVariant === 'extraSmall' ? '4px' : '20px')}; */
`;
