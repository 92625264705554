import { IconPropsType } from '../types';

interface IHamBurgerIconProps extends IconPropsType {
  orientation?: 'left' | 'right';
}

const HamBurgerIcon = ({
  stroke = 'var(--cod-gray)',
  orientation = 'left',
  ...props
}: IHamBurgerIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {orientation === 'left' && (
      <>
        <path
          d="M20 6H13"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 12H11"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 18H13"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M8 8L4 12L8 16"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}

    {orientation === 'right' && (
      <>
        <path
          d="M13 6H6"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 12H4"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 18H6"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 16L22 12L18 8"
          stroke={stroke}
          strokeWidth={1.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
export default HamBurgerIcon;
