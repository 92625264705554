import { WithHeader } from '@point-of-sale/components';
import ProductDiscovery from '../../components/ProductDiscovery';
import ProductsTable from '../../components/ProductsTable';
import * as Styles from './styles';

const ExploreProducts = () => {
  return (
    <Styles.Wrapper>
      <ProductDiscovery usage="EXPLORE_PRODUCTS" />
      <Styles.Content>
        <ProductsTable />
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default WithHeader(ExploreProducts, {
  name: 'Explore Products',
});
