import { ComponentProps, ElementType, ReactNode, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Input, Modal, SpinnerIcon, Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';

import Address from './Address';
import GstDetails from './GstDetails';
import * as Styles from './styles';

interface ICustomerDetailBaseProps {
  actionables: (formData: { phone: string; name?: string; email?: string }) => ReactNode;
}

type ModalProps = {
  isModal: true;
  isOpen: boolean;
  onClose: () => void;
};

type NonModalProps = {
  isModal: false;
};

type CustomerDetailsModalPropsType = ICustomerDetailBaseProps & (ModalProps | NonModalProps);

const CustomerDetails = ({ actionables, ...maybeModalProps }: CustomerDetailsModalPropsType) => {
  const Wrapper: ElementType = maybeModalProps.isModal ? Modal : Styles.Wrapper;

  const wrapperProps = maybeModalProps.isModal
    ? {
        ...({
          size: 'regular',
          heading: (
            <Text fontSize={20} weight="bold">
              Customer Details
            </Text>
          ),
          isOpen: maybeModalProps.isOpen,
          onClose: maybeModalProps.onClose,
        } as ComponentProps<typeof Modal>),
      }
    : {};

  // --------------------------------------------------------

  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    email: '',
    // address: '',
    // city: '',
    // pinCode: '',
    // gst: '',
  });

  const customerData = useAppSelector(store => store.pointOfSale.customer.data);
  const addresses = useAppSelector(store => store.pointOfSale.addresses.data);
  const isAddressesLoading = useAppSelector(store => store.pointOfSale.addresses.isLoading);

  function handleFormInputChanges(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    if (customerData) {
      setFormData({
        email: customerData.email ?? '',
        phone: customerData.phone ?? '',
        name: customerData.name ?? '',
      });
    }
  }, [customerData]);

  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const addressIds = [...addresses.ids].sort((a, b) => (a === selectedAddressId ? -1 : 1));

  return (
    <Wrapper {...wrapperProps}>
      <Styles.Content>
        <Input
          label="Phone Number"
          placeholder="Phone Number"
          sizeVariant="extraSmall"
          name="phone"
          type={customerData.id ? 'text' : 'number'}
          variant="outline"
          value={formData.phone}
          onChange={handleFormInputChanges}
          disabled={!!customerData.id}
        />
        <Input
          label="Name"
          placeholder="Name"
          sizeVariant="extraSmall"
          name="name"
          variant="outline"
          value={formData.name}
          onChange={handleFormInputChanges}
        />
        <Input
          label="Email id (optional)"
          placeholder="Email id (optional)"
          sizeVariant="extraSmall"
          name="email"
          variant="outline"
          value={formData.email}
          onChange={handleFormInputChanges}
        />

        {customerData.id && <GstDetails customerId={customerData.id} />}

        {customerData.id && (
          <Text fontSize={14} color="var(--dove-gray)">
            Addresses
          </Text>
        )}

        {isAddressesLoading && <SpinnerIcon />}

        <AnimatePresence mode="popLayout">
          {addressIds.map(id => (
            <Address key={id} data={structuredClone(addresses.records[id])} />
          ))}
        </AnimatePresence>
        {customerData.id && (
          <Address isAddMode customerId={customerData.id} isFirst={addressIds.length === 0} />
        )}
      </Styles.Content>
      <Styles.Actionables>{actionables(formData)}</Styles.Actionables>
    </Wrapper>
  );
};

export default CustomerDetails;
