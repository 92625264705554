import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Text } from './styles';

const StyledSectionHeading = styled(Text)`
  border-top: 1px dashed var(--dusty-gray);
  border-bottom: 1px dashed var(--dusty-gray);
  text-transform: uppercase;

  margin: 8px 0;
  text-align: center;
  padding: 4px 0;
`;

interface ISectionHeadingProps {
  heading: string;
}

const SectionHeading = ({ heading }: ISectionHeadingProps) => {
  return (
    <StyledSectionHeading weight={700}>
      {heading.split('\n').map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < heading.split('\n').length - 1 && <br />}
        </Fragment>
      ))}
    </StyledSectionHeading>
  );
};

export default SectionHeading;
