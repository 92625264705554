import ReactDropdownSelect from 'react-dropdown-select';
import * as Styles from './styles';

interface ISelectDropdownProps<T extends string | object> {
  label?: string;
  sizeVariant?: 'small' | 'regular';
  options: Array<T>;
  values: Array<T> | [object];
  onChange: (values: Array<T>) => void;
  labelField: keyof T;
  valueField: keyof T;
  className?: string;
  dropdownHeight?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  width?: number;
}

const SelectDropdown = <T extends string | object>({
  label,
  sizeVariant,
  options,
  onChange,
  values,
  labelField,
  valueField,
  // * Optional props
  className,
  dropdownHeight,
  isDisabled,
  isLoading,
  width = 200,
}: ISelectDropdownProps<T>) => {
  return (
    <Styles.Wrapper className={className} $minWidth={width}>
      <ReactDropdownSelect
        required={false}
        options={options}
        labelField={labelField as string}
        valueField={valueField as string}
        searchBy={labelField as string}
        onChange={onChange}
        // @ts-expect-error stupid types
        values={values}
        dropdownHeight={dropdownHeight}
        disabled={isDisabled}
        loading={isLoading}
        dropdownPosition="auto"
        portal={document.getElementById('portal-root') as HTMLElement}
        backspaceDelete
      />
    </Styles.Wrapper>
  );
};

export default SelectDropdown;
