import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
/**
 *  TODO : figure out some way to clean this up
 * - maybe use a separate file to define the routes
 *  layout and page must be coupled together
 *  or should they ?
 */
import BasicLayout from '../components/BasicLayout';
import {
  getFacilities,
  getSalesChannels,
  getStoreStaff,
  useAppDispatch,
} from '@point-of-sale/store';

import Home from '../pages/Home';
import PointOfSale from '../pages/PointOfSale';
import OpenCarts from '../pages/OpenCarts';

import Customer from '../pages/Customer';
import Checkout from '../pages/Checkout';
import PosHeaderLayout from '../components/PosHeaderLayout';
import PendingTasks from '../pages/PendingTasks';
import ExploreProducts from '../pages/ExploreProducts';
import OrderManagementSystem from '../pages/OrderManagementSystem';
import OrderDetails from '../components/OrderDetails';

/**
 * * Should there be only one BaseRoutes component
 * * for both desktop and mobile ?
 * * or should desktop and mobile routes should be
 * * defined separately ?
 */
const BaseRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreStaff());
    dispatch(getSalesChannels());
    dispatch(getFacilities());
  }, []);

  return (
    <Routes>
      <Route path="*" element={<BasicLayout />}>
        <Route path="" element={<>Page Not Found</>} />
      </Route>
      <Route path="/" element={<BasicLayout />}>
        <Route path="" element={<Home />} />
      </Route>
      <Route path="/point-of-sale" element={<BasicLayout />}>
        <Route path="" element={<PosHeaderLayout />}>
          <Route index element={<Navigate to="/point-of-sale/add-customer" replace={true} />} />
          <Route path="add-customer" element={<Customer />} />
          <Route path="cart" element={<PointOfSale />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="*" element={<Navigate to="/point-of-sale/add-customer" replace={true} />} />
        </Route>
      </Route>

      <Route path="/open-carts" element={<BasicLayout />}>
        <Route path="" element={<OpenCarts />} />
      </Route>

      <Route path="/pending-tasks" element={<BasicLayout />}>
        <Route path="" element={<PendingTasks />} />
      </Route>

      <Route path="/products" element={<BasicLayout />}>
        <Route path="" element={<ExploreProducts />} />
      </Route>

      <Route path="/orders" element={<BasicLayout />}>
        <Route path="" element={<OrderManagementSystem />} />
        <Route path="/orders/:id" element={<OrderDetails />} />
      </Route>
    </Routes>
  );
};

export default BaseRoutes;
