import { IconPropsType } from '../types';

const ReturnsIcon = ({ stroke = 'var(--cod-gray)', size = 24, ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.822 13.784h2.592C17.436 13.769 19 14.24 19 15.94c0 1.764-1.93 2.06-2.587 2.06H7.757C5.75 18 3 17.644 3 14.619V8.201h16v3.162m-3.598 1.02-1.362.994a.6.6 0 0 0-.158.183.5.5 0 0 0-.006.463.6.6 0 0 0 .166.196l1.36.968M3.005 8.194l.737-1.61c.598-1.253.897-1.878 1.504-2.23C5.852 4 6.63 4 8.184 4H13.8c1.555 0 2.333 0 2.94.353.606.352.904.978 1.503 2.23L19 8.196m-8.03.004V4m-1.6 7h3.2"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReturnsIcon;
