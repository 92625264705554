import { ThemeType } from '@point-of-sale/components';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  /* border: 1px solid burlywood; */
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  /* background-color: burlywood; */
  /* border-bottom: 1px solid var(--mercury); */
`;

export const TableRow = styled.tr<{ $isDisabled: boolean }>`
  border-bottom: 1px solid var(--mercury);
  cursor: pointer;

  ${props =>
    props.$isDisabled &&
    css`
      background: var(--athens-gray) !important;
      cursor: not-allowed;
    `}
`;

export const TableHeader = styled.th<{ theme: ThemeType }>`
  padding: 8px;
  text-align: left;
  padding: 24px;
  font-size: ${props => props.theme.typography.fonts.size[16]};
  font-weight: ${props => props.theme.typography.fonts.weight.bold};
`;

export const TableBody = styled.tbody`
  /* border: 1px dashed var(--mercury);
  padding: 24px; */

  ${TableRow}:hover {
    background: var(--zumthor);
  }
`;

export const TableData = styled.td`
  padding: 24px;
  /* border-bottom: 1px solid var(--mercury); */
`;
