import { CartIcon, Text } from '@point-of-sale/components';
import * as Styles from './styles';
import { useAppSelector } from '@point-of-sale/store';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { formatNumberToLocale } from '@point-of-sale/utils';

const MinimumCollections = () => {
  const { cartMinAmountToBePaid } = useAppSelector(store => store.checkout);

  const currency = useAppSelector(
    store => CURRENCY_SYMBOLS[store.pointOfSale.cart.data.currency as keyof typeof CURRENCY_SYMBOLS]
  );

  const billingOrderValue = useAppSelector(store =>
    store.pointOfSale.cart.data.cartItems.ids
      .filter(
        item => store.pointOfSale.cart.data.cartItems.records[item].fulfillmentMode === 'ONHAND'
      )
      .reduce(
        (acc, curr) => acc + store.pointOfSale.cart.data.cartItems.records[curr].totalSellingPrice,
        0
      )
  );

  const salesOrderValue = useAppSelector(store =>
    store.pointOfSale.cart.data.cartItems.ids
      .filter(
        item => store.pointOfSale.cart.data.cartItems.records[item].fulfillmentMode !== 'ONHAND'
      )
      .reduce(
        (acc, curr) => acc + store.pointOfSale.cart.data.cartItems.records[curr].totalSellingPrice,
        0
      )
  );

  return (
    <Styles.Wrapper>
      <Styles.Left>
        <CartIcon />
      </Styles.Left>
      <Styles.Right>
        <Text fontSize={16} weight="semibold">
          There are custom tailored products added in your cart.
        </Text>
        <Styles.Row>
          <Text fontSize={16} color="var(--dove-gray)">
            Billing Order Value:{' '}
          </Text>
          <Text fontSize={16} weight="semibold" textAlign="right">
            {currency?.code} {formatNumberToLocale(billingOrderValue)}
          </Text>
        </Styles.Row>
        <Styles.Row>
          <Text fontSize={16} color="var(--dove-gray)">
            Sales Order Value:{' '}
          </Text>
          <Text fontSize={16} weight="semibold" textAlign="right">
            {currency?.code} {formatNumberToLocale(salesOrderValue)}
          </Text>
        </Styles.Row>
        <Styles.Row>
          <Text fontSize={16} color="var(--dove-gray)">
            Minimum Advance:{' '}
          </Text>
          <Text fontSize={16} weight="semibold" textAlign="right">
            {currency?.code} {formatNumberToLocale(cartMinAmountToBePaid)}
          </Text>
        </Styles.Row>
        <Styles.Row>
          <Text fontSize={16} weight="bold" color="var(--dove-gray)">
            Minimum Amount to be collected:{' '}
          </Text>
          <Text fontSize={20} weight="semibold" color="var(--cod-gray)" textAlign="right">
            {currency?.code} {formatNumberToLocale(cartMinAmountToBePaid)}
          </Text>
        </Styles.Row>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default MinimumCollections;
