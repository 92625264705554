import styled from 'styled-components';
import { NoImageIcon } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';

const StyledImage = styled.div`
  height: 90px;
  width: 60px;
  position: relative;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const IconWrapper = styled.div`
  border: 1px solid var(--alto);
  height: 90px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1px;
`;

interface IRibbonProps {
  $isMadeToOrder: boolean;
}

export const Ribbon = styled.div<IRibbonProps>`
  --f: 5px; /* control the folded part*/
  --r: 3px; /* control the ribbon shape */

  position: absolute;
  bottom: 0;
  left: calc(-1 * var(--f));
  background: ${props => (props.$isMadeToOrder ? 'var(--chinese-purple)' : 'var(--royal-blue)')};
  border-bottom: var(--f) solid #0005;
  clip-path: polygon(
    calc(100% - var(--r)) 0,
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    100% 0
  );
  font-size: 10px;
  font-weight: 400;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;

interface IImageProps {
  src: string | undefined;
  // isBuyNow: boolean;
  isMTO: boolean;
  isMTC: boolean;
  label: string;
}

const Image = ({ src, label, isMTC, isMTO }: IImageProps) => {
  const [isImageValid, imageValidActions] = useBoolean(true);

  return (
    <StyledImage>
      {src && isImageValid ? (
        <img
          src={src ?? ''}
          alt="Product"
          onError={() => {
            imageValidActions.off();
          }}
        />
      ) : (
        <IconWrapper>
          <NoImageIcon size={28} />
        </IconWrapper>
      )}

      <Ribbon $isMadeToOrder={isMTC || isMTO}>
        {/* {isBuyNow && 'Handover'}
        {isMTO && 'MTO'}
        {isMTC && 'MTC'} */}
        {label}
      </Ribbon>
    </StyledImage>
  );
};

export default Image;
