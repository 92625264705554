import { OpenCartsActionsTypes } from './constants';
import { IOpenCartsState } from './types';

export const setOpenCartSearch = (payload: Partial<IOpenCartsState['search']>) => ({
  type: OpenCartsActionsTypes.SET_SEARCH,
  payload,
});

export const setOpenCarts = (payload: Partial<IOpenCartsState['carts']>) => ({
  type: OpenCartsActionsTypes.SET_CARTS,
  payload,
});

export const setOpenCartsPagination = (payload: Partial<IOpenCartsState['pagination']>) => ({
  type: OpenCartsActionsTypes.SET_PAGINATION,
  payload,
});

export const resetOpenCartsState = () => ({
  type: OpenCartsActionsTypes.RESET_STATE,
});
