import { callApi } from '../services/callApi';

export const requestOtpApi = (email: string) =>
  callApi('POST', `/pos/auth/request/otp`, {
    targetMode: 'EMAIL',
    identityKey: email,
    operation: 'LOGIN',
  });

export const verifyOtpApi = ({ id, otp }: { id: number; otp: string }) =>
  callApi('POST', `/pos/auth/login`, {
    authMode: 'EMAIL_OTP',
    id: String(id),
    secret: otp,
  });
