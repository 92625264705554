import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  flex: 1;

  max-height: 80vh;
  overflow: auto;
`;

export const Left = styled.div`
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 60%;
`;

export const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
`;

export const Diagram = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const DiagramImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;
