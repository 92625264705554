import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  DiscountIcon,
  IconButton,
  Input,
  Modal,
  RadioGroup,
  Text,
} from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { modifyServiceCharge, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import {
  CartItemDTOType,
  ServiceChargeDTOSchema,
  ServiceChargeDTOType,
} from '@point-of-sale/schemas';
import { removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import * as Styles from './styles';

export type ServiceChargesPropsType =
  | {
      level: 'ITEM';
      cartItem: CartItemDTOType;
    }
  | {
      level: 'TOP';
    };

const ChargeTypeOptions: Record<
  ServiceChargesPropsType['level'],
  Array<ServiceChargeDTOType['type']>
> = {
  ITEM: [
    ServiceChargeDTOSchema.shape.type.enum.ALTERATION,
    ServiceChargeDTOSchema.shape.type.enum.BRAND_CUSTOMIZATION,
  ],
  TOP: [ServiceChargeDTOSchema.shape.type.enum.SHIPPING],
} as const;

const ServiceCharges = (props: ServiceChargesPropsType) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);

  const customCharges = useAppSelector(state => {
    if (props.level === 'ITEM') {
      return state.pointOfSale.cart.data.cartItems.records[props.cartItem.id].customCharges;
    }
    return state.pointOfSale.cart.data.customCharges;
  });

  const [loaders, setLoaders] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (!customCharges || customCharges.length === 0) {
      return;
    }

    const newLoaders = customCharges.reduce((acc, _, index) => {
      acc[index] = false;
      return acc;
    }, {} as Record<number, boolean>);

    setLoaders(newLoaders);
  }, [customCharges]);

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  const currency = useAppSelector(
    store =>
      CURRENCY_SYMBOLS[store.pointOfSale.cart.data?.currency as keyof typeof CURRENCY_SYMBOLS]
  );

  const [isModalOpen, modalOpenActions] = useBoolean();

  const [formData, setFormData] = useState<ServiceChargeDTOType>({} as ServiceChargeDTOType);

  const doesCustomChargeExist = customCharges?.length > 0;

  return (
    <Styles.Wrapper $level={props.level} $blank={props.level === 'ITEM' && !doesCustomChargeExist}>
      <Styles.Row>
        {props.level === 'TOP' && <DiscountIcon size={20} />}

        <Text color="var(--dove-gray)" fontSize={12} weight="bold">
          {doesCustomChargeExist && props.level === 'TOP' && 'Apply '}
          Service Charges
        </Text>

        <Button
          variant="outline"
          onClick={modalOpenActions.on}
          isDisabled={hasPaymentsStarted}
          sizeVariant={props.level === 'ITEM' ? 'xs' : 'xs'}
          rightIcon={props.level === 'ITEM' ? 'DiscountIcon' : undefined}
        >
          Apply {!doesCustomChargeExist && props.level === 'ITEM' && 'Service Charges'}
        </Button>
      </Styles.Row>

      <Styles.CustomChargesWrapper>
        {customCharges && customCharges?.length > 0 && (
          <Styles.Row>
            <Text color="var(--cod-gray)" fontSize={12} weight="bold">
              Applied Charges
            </Text>
          </Styles.Row>
        )}
        <Styles.CustomChargesList>
          {customCharges?.map((charge, index) => (
            <Styles.Row key={index}>
              <Text fontSize={14} color="var(--dove-gray)">
                {index + 1}. {charge.remarks} ({removeUnderscoresAndCapitalize(charge.type)}) :{' '}
                {currency.code}
                {charge.amount}
              </Text>
              {/* <Button
              rightIcon="DeleteIcon"
              colorScheme="dark"
              variant="outline"
              sizeVariant="xs"
              isLoading={loaders[index]}
              onClick={() => {
                setLoaders(prev => ({
                  ...prev,
                  [index]: true,
                }));

                function successCallback() {
                  setLoaders(prev => ({
                    ...prev,
                    [index]: false,
                  }));
                }

                if (props.level === 'TOP') {
                  dispatch(
                    modifyServiceCharge(
                      null,
                      'TOP',
                      undefined,
                      index,
                      successCallback
                    )
                  );
                } else {
                  dispatch(
                    modifyServiceCharge(
                      null,
                      'ITEM',
                      props.cartItem.id,
                      index,
                      successCallback
                    )
                  );
                }

              }}
            >
              Remove
            </Button> */}

              <IconButton
                icon="DeleteIcon"
                shapeVariant="circle"
                sizeVariant="small"
                onClick={() => {
                  setLoaders(prev => ({
                    ...prev,
                    [index]: true,
                  }));

                  function successCallback() {
                    setLoaders(prev => ({
                      ...prev,
                      [index]: false,
                    }));
                  }

                  if (props.level === 'TOP') {
                    dispatch(modifyServiceCharge(null, 'TOP', undefined, index, successCallback));
                  } else {
                    dispatch(
                      modifyServiceCharge(null, 'ITEM', props.cartItem.id, index, successCallback)
                    );
                  }

                  // dispatch(
                  //   unApplyServiceChargeToCart(index, () => {
                  //     setLoaders(prev => ({
                  //       ...prev,
                  //       [index]: false,
                  //     }));
                  //   })
                  // );
                }}
              />
            </Styles.Row>
          ))}
        </Styles.CustomChargesList>
      </Styles.CustomChargesWrapper>
      <Modal
        size="small"
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        heading={
          <Styles.Heading fontSize={props.level === 'ITEM' ? 16 : 20} weight="bold">
            Apply Service Charges{' '}
            {props.level === 'ITEM' ? `for ${props.cartItem.productVariant.productName}` : ''}
          </Styles.Heading>
        }
      >
        <Styles.ModalContent>
          <Text fontSize={16} weight="semibold">
            Select Type Of Charge
          </Text>

          <RadioGroup
            horizontal
            name="options"
            options={ChargeTypeOptions[props.level].map((option: ServiceChargeDTOType['type']) => ({
              label: removeUnderscoresAndCapitalize(option),
              value: option,
            }))}
            selectedValue={formData.type}
            onChange={selectedValue => {
              setFormData(prev => ({
                ...prev,
                type: selectedValue as ServiceChargeDTOType['type'],
              }));
            }}
          />

          <Input
            placeholder="Enter Amount"
            sizeVariant="small"
            variant="outline"
            isFullWidth
            type="number"
            value={formData.amount}
            onChange={e =>
              setFormData(prev => ({
                ...prev,
                amount: e.target.value,
              }))
            }
          />
          <Input
            placeholder="Remarks"
            sizeVariant="small"
            variant="outline"
            isFullWidth
            value={formData.remarks}
            onChange={e =>
              setFormData(prev => ({
                ...prev,
                remarks: e.target.value,
              }))
            }
          />
          <Button
            isLoading={isLoading}
            isDisabled={hasPaymentsStarted}
            onClick={() => {
              if (!formData.type || !formData.amount) {
                toast.error('Please fill all the fields');
                return;
              }

              function successCallback() {
                setFormData({} as ServiceChargeDTOType);
                modalOpenActions.off();
              }

              if (props.level === 'TOP') {
                dispatch(
                  modifyServiceCharge(formData, 'TOP', undefined, undefined, successCallback)
                );
              } else {
                dispatch(
                  modifyServiceCharge(
                    formData,
                    'ITEM',
                    props.cartItem.id,
                    undefined,
                    successCallback
                  )
                );
              }

              // dispatch(applyServiceChargeToCart(formData, successCallback));
            }}
          >
            Apply
          </Button>
        </Styles.ModalContent>
      </Modal>
    </Styles.Wrapper>
  );
};

export default ServiceCharges;
