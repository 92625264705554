import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  padding: 20px;
  background: var(--white);
  display: flex;
  gap: 24px;
  /* align-items: ; */
  border-bottom: 1px solid var(--gallery);

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const Left = styled(motion.div)`
  /* width: 20%; */
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Right = styled(motion.div)`
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`;
