import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router';

import App from './app/App';

export function getRootNode() {
  const node = document.getElementById('root');
  if (node === null) {
    throw new Error('Root node is null. Application is down');
  }
  return node;
}

const root = ReactDOM.createRoot(getRootNode());

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
