import React, { ChangeEvent, useEffect, useState } from 'react';
import { DateRangePicker, IOption, MultiSelect } from '@point-of-sale/components';
import { PosFilterNames } from '@point-of-sale/store';
import { IDateRange } from '@point-of-sale/types';
import * as Styles from './styles';

export interface IFilter {
  name: string;
  placeholder: string;
  options: Array<IOption>;
  values:
    | Array<string>
    | Array<number>
    | {
        from: number;
        to: number;
      };
  type?: 'MULTISELECT' | 'DATE_RANGE';
}

interface IDiscoveryProps {
  searchQuery: string;
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (search: string) => void;
  filters: IFilter[];
  onFilterChange: (filterName: any, selectedOptions: IOption[] | IDateRange) => void;
  onFilterClear: (filterName: string) => void;
  renderSelectedFilters?: () => React.ReactNode;
  placeholder?: string;
  isLoading?: boolean;
}

const Discovery = ({
  searchQuery,
  onSearchChange,
  onPressEnter,
  filters,
  onFilterChange,
  onFilterClear,
  renderSelectedFilters,
  isLoading = false,
  placeholder = 'Search...',
}: IDiscoveryProps) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Styles.SearchInput
          placeholder={placeholder}
          value={search}
          autoFocus
          type="search"
          onChange={e => setSearch(e.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onPressEnter?.(search);
            }
          }}
        />

        {filters.map(filter => {
          if (filter.type === 'DATE_RANGE') {
            return (
              <DateRangePicker
                key={filter.name}
                label={filter.placeholder}
                values={filter.values as IDateRange}
                onChange={selected => onFilterChange(filter.name, selected)}
              />
            );
          }

          return (
            <MultiSelect
              isLoading={isLoading}
              key={filter.name}
              isSearchable
              placeholder={filter.placeholder}
              label={filter.placeholder}
              options={filter.options}
              values={filter.values as string[] | number[]}
              onChange={selectedOptions =>
                onFilterChange(filter.name as PosFilterNames, selectedOptions)
              }
              onClear={() => onFilterClear(filter.name)}
            />
          );
        })}
      </Styles.Row>
      {renderSelectedFilters && renderSelectedFilters()}
    </Styles.Wrapper>
  );
};

export default Discovery;
