import { IconPropsType } from '../types';

const CheckFilledIcon = ({ fill = 'var(--blue-ribbon)', size = 18, ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18" fill={fill} />
    <path
      d="M12.696 6.34a.383.383 0 0 0-.538 0l-4.562 4.52-1.754-1.738a.383.383 0 0 0-.538 0 .374.374 0 0 0 0 .533l2.023 2.004c.148.147.39.147.538 0l4.831-4.785a.375.375 0 0 0 0-.533Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.8}
    />
  </svg>
);

export default CheckFilledIcon;
