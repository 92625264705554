import { ModalPropertyType } from './types';

export const MODAL_WIDTHS: ModalPropertyType<string> = {
  small: '40vw',
  regular: '70vw',
  large: '90vw',
};

export const MODAL_CONTENT_MAXIMUM_HEIGHTS: ModalPropertyType<string> = {
  small: '60vh',
  regular: '60vh',
  large: '80vh',
};
