// **************************************************************************
// **************************************************************************
// ************************                        **************************
// ************************         Button         **************************
// ************************                        **************************
// **************************************************************************
// **************************************************************************

import { memo, MouseEventHandler, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import SpinnerIcon from '../icons/elements/SpinnerIcon';
import iconMap from '../icons/iconMap';
import { IconographyNameType } from '../icons/types';
import { ICON_SIZE } from './constants';
import { getIconProps } from './utils';
import { ButtonColorSchemeType, ButtonSizeVariantType, ButtonVariantType } from './types';
import { emptyFunction } from '@point-of-sale/utils';
import * as Styles from './styles';

export interface IButtonProps {
  children: ReactNode;
  /** On Click Listener for web */
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  isLoading?: boolean;
  colorScheme?: ButtonColorSchemeType;
  sizeVariant?: ButtonSizeVariantType;
  variant?: ButtonVariantType;
  leftIcon?: Exclude<IconographyNameType, 'SpinnerIcon'>;
  rightIcon?: Exclude<IconographyNameType, 'SpinnerIcon'>;
  // dataProps?: Record<DataAttributeType, string | number>;
  htmlType?: 'button' | 'submit' | 'reset';
  className?: string;
  isFullWidth?: boolean;
}

const Button = memo(
  ({
    children,
    onClick,
    leftIcon,
    rightIcon,
    className = '',
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    htmlType = 'button',
    variant = 'fill',
    sizeVariant = 'medium',
    colorScheme = 'primary',
  }: IButtonProps) => {
    const LeftIcon = leftIcon ? iconMap[leftIcon] : null;
    const RightIcon = rightIcon ? iconMap[rightIcon] : null;

    const whileTap = isDisabled || isLoading ? {} : { scale: 0.95 };

    return (
      <Styles.Button
        className={className}
        onClick={isLoading || isDisabled ? emptyFunction : onClick}
        disabled={isDisabled}
        type={htmlType}
        $isFullWidth={isFullWidth}
        $colorScheme={colorScheme}
        $sizeVariant={sizeVariant}
        $isLoading={isLoading}
        $variant={variant}
        // * FRAMER MOTION ANIMATIONS
        whileTap={whileTap}
      >
        <AnimatePresence mode="wait">
          {isLoading && (
            <Styles.SpinnerWrapper
              $variant={variant}
              $colorScheme={colorScheme}
              // animate={isLoading ? 'show' : 'hidden'}
              // initial="hidden"
              // variants={{
              //   hidden: { y: -BUTTON_HEIGHTS[sizeVariant] },
              //   show: { y: 0, transition: { type: 'tween' } },
              // }}
            >
              <SpinnerIcon {...getIconProps(colorScheme, variant)} />
            </Styles.SpinnerWrapper>
          )}
        </AnimatePresence>

        {LeftIcon && <LeftIcon size={ICON_SIZE[sizeVariant]} />}
        {children}
        {RightIcon && <RightIcon size={ICON_SIZE[sizeVariant]} />}
      </Styles.Button>
    );
  }
);

export default Button;
