import Lottie, { type LottieProps } from 'react-lottie-player';
import animationData from './rack.json';

interface IRackLottieProps extends Omit<LottieProps, 'animationData'> {
  height?: number;
  width?: number;
}

const RackLottie = ({ height = 400, width = 400, ...props }: IRackLottieProps) => {
  return (
    <Lottie
      {...props}
      animationData={animationData}
      loop
      play
      style={{
        height: height,
        width: width,
      }}
    />
  );
};

export default RackLottie;
