type FilterTypeType =
  | 'EQ'
  | 'NEQ'
  | 'LT'
  | 'LTE'
  | 'GT'
  | 'GTE'
  | 'IN'
  | 'LIKE'
  | 'ILIKE'
  | 'ISNULL'
  | 'ISNOTNULL'
  | 'NOTLIKE'
  | 'NOTIN'
  | 'ICONTAINS'
  | 'IEQ'
  | 'ISNOTEMPTY'
  | 'QUERY';

type FilterValueType<T extends FilterTypeType> = T extends 'LIKE' | 'ILIKE'
  ? `%${string}%` | `${string}%` | `%${string}`
  : string | number;

export interface IFilter<T extends FilterTypeType = FilterTypeType> {
  filterType: T;
  field: string;
  value: FilterValueType<T>;
}

export class FilterBuilder {
  private filters: Array<IFilter> = [];

  addFilter<T extends FilterTypeType>(filter: IFilter<T>) {
    this.filters.push(filter as IFilter);
    return this;
  }

  public build() {
    return this.filters;
  }
}
