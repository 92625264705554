import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Modal, RadioGroup, Text } from '@point-of-sale/components';
import { bulkReturnOrderItems, useAppDispatch } from '@point-of-sale/store';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import {
  LineItemDTOType,
  OrderDTOType,
  RefundModeEnumSchema,
  RefundModeType,
} from '@point-of-sale/schemas';

import KeyValuePair from '../KeyValuePair';
import Box from '../Box';
import { useOrderDetailsContext } from '../context';

import { RETURN_REASONS } from '../constants';
import * as Styles from './styles';
import { useBoolean } from '@point-of-sale/hooks';
import { getRefundOptions } from '../helpers';

interface IReturnDetailsProps {
  data: OrderDTOType;
  setPdfData: (data: Array<LineItemDTOType>) => void;
}

const ReturnDetails = ({ data, setPdfData }: IReturnDetailsProps) => {
  const { lineItemsSelection, reasons: returnReasons } = useOrderDetailsContext();

  const dispatch = useAppDispatch();

  const [isModalOpen, modalOpenActions] = useBoolean();

  const itemsToBeReturned = Object.entries(lineItemsSelection)
    .filter(([id, isSelected]) => isSelected)
    .map(([id]) => id)
    .map(Number);

  const currency =
    CURRENCY_SYMBOLS[(data?.costBreakup?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  const refundAmount = data.lineItems
    ?.filter(item => itemsToBeReturned.includes(item.id))
    ?.reduce((acc, curr) => {
      if (!curr.costBreakup?.amountPaid) {
        return acc + 0;
      }

      return acc + curr.costBreakup?.amountPaid;
    }, 0);

  const [refundMode, setRefundMode] = useState<RefundModeType | null>(
    RefundModeEnumSchema.Enum.STORE_CREDIT
  );

  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    ifsc: '',
    bankName: '',
    accountName: '',
  });

  const [upiHandle, setUpiHandle] = useState('');

  function getIsSubmitButtonDisabled() {
    return (
      bankDetails.accountName === '' ||
      bankDetails.bankName === '' ||
      bankDetails.accountNumber === '' ||
      bankDetails.ifsc === ''
    );
  }

  function getIsProcessButtonDisabled() {
    if (refundMode === RefundModeEnumSchema.Enum.BANK_ACCOUNT) {
      return getIsSubmitButtonDisabled() || itemsToBeReturned.length === 0;
    }

    if (refundMode === RefundModeEnumSchema.Enum.UPI) {
      return upiHandle === '' || itemsToBeReturned.length === 0;
    }

    return itemsToBeReturned.length === 0;
  }

  return (
    <Box header="Return Details" hasPadding={false} flexOne>
      <Styles.Content>
        <Styles.Section>
          <KeyValuePair title="No of items" value={itemsToBeReturned.length} />
        </Styles.Section>
        <Styles.Section $flexOne>
          <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
            Refund Mode
            <Text weight="semibold" color="var(--alizarin-crimson)">
              *
            </Text>
          </Text>
          <RadioGroup
            horizontal
            name="refund-mode"
            options={getRefundOptions(data)}
            selectedValue={refundMode ?? ''}
            onChange={selectedValue => {
              setRefundMode(selectedValue as RefundModeType);

              if (selectedValue === RefundModeEnumSchema.Enum.BANK_ACCOUNT) {
                modalOpenActions.on();
              }
            }}
          />

          {refundMode === RefundModeEnumSchema.Enum.UPI && (
            <Input
              label="Upi Handle"
              value={upiHandle}
              onChange={e => {
                setUpiHandle(e.target.value);
              }}
              sizeVariant="extraSmall"
              variant="outline"
            />
          )}
        </Styles.Section>
        {/* <Styles.Section>
          <KeyValuePair title="Store Credit For Size Exchange" value="price" />
        </Styles.Section> */}
        {refundMode === RefundModeEnumSchema.Enum.BANK_ACCOUNT && (
          <Styles.Section>
            <Styles.BankDetails>
              <KeyValuePair title="Name of the Bank" value={bankDetails.bankName} />
              <KeyValuePair title="Account Holder's Name" value={bankDetails.accountName} />
              <KeyValuePair title="Account Number" value={bankDetails.accountNumber} />
              <KeyValuePair title="IFSC" value={bankDetails.ifsc} />
              <Button
                colorScheme="dark"
                variant="outline"
                onClick={modalOpenActions.on}
                sizeVariant="xs"
                leftIcon="EditIcon"
              >
                Edit
              </Button>
            </Styles.BankDetails>
          </Styles.Section>
        )}

        <Styles.Section>
          <KeyValuePair title="Refund Amount" value={`${currency} ${refundAmount}`} />
        </Styles.Section>
        <Styles.Section>
          <Button
            isFullWidth
            isDisabled={getIsProcessButtonDisabled()}
            onClick={() => {
              if (!refundMode) {
                toast.error('Please select refund mode');
                return;
              }

              dispatch(
                bulkReturnOrderItems(
                  {
                    ids: itemsToBeReturned,
                    refundMode: refundMode,
                    returnMode: 'AT_STORE',
                    bankAccount: bankDetails,
                    upiHandle: upiHandle,
                    returnReasons: itemsToBeReturned
                      .map(id => returnReasons[id])
                      .map(reason => RETURN_REASONS.find(r => r.value === reason)?.label ?? ''),
                  },
                  setPdfData
                )
              );
            }}
          >
            Process Refund
          </Button>
        </Styles.Section>
      </Styles.Content>

      <Modal
        heading={<Text fontSize={20}>Bank Details</Text>}
        isOpen={isModalOpen}
        size={'small'}
        onClose={modalOpenActions.off}
        footer={
          <Button
            onClick={() => {
              modalOpenActions.off();
            }}
            sizeVariant="medium"
            isDisabled={getIsSubmitButtonDisabled()}
            isFullWidth
          >
            Done
          </Button>
        }
      >
        <Styles.ModalContent>
          <Input
            label="Account Holder's Name"
            value={bankDetails.accountName}
            onChange={e => {
              setBankDetails({
                ...bankDetails,
                accountName: e.target.value,
              });
            }}
            sizeVariant="extraSmall"
            variant="outline"
          />
          <Input
            label="Bank Name"
            value={bankDetails.bankName}
            onChange={e => {
              setBankDetails({
                ...bankDetails,
                bankName: e.target.value,
              });
            }}
            sizeVariant="extraSmall"
            variant="outline"
          />
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Input
              label="Account Number"
              value={bankDetails.accountNumber}
              sizeVariant="extraSmall"
              variant="outline"
              isFullWidth
              onChange={e => {
                setBankDetails({
                  ...bankDetails,
                  accountNumber: e.target.value,
                });
              }}
            />
            <Input
              sizeVariant="extraSmall"
              variant="outline"
              isFullWidth
              label="IFSC"
              value={bankDetails.ifsc}
              onChange={e => {
                setBankDetails({
                  ...bankDetails,
                  ifsc: e.target.value,
                });
              }}
            />
          </div>
        </Styles.ModalContent>
      </Modal>
    </Box>
  );
};

export default ReturnDetails;
