import { IconPropsType } from '../types';

const ClipboardIcon = ({ stroke = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg
    width={28}
    height={20}
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.815 12.915q.39 0 .695-.13.31-.13.52-.365.21-.236.32-.57a2.3 2.3 0 0 0 .115-.75V6.77h1.345v4.33q0 .645-.21 1.195a2.7 2.7 0 0 1-.595.945q-.385.395-.94.62-.555.22-1.25.22-.7 0-1.255-.22a2.8 2.8 0 0 1-.945-.62 2.8 2.8 0 0 1-.595-.945 3.4 3.4 0 0 1-.205-1.195V6.77H7.16v4.325q0 .415.11.75a1.7 1.7 0 0 0 .325.575q.214.235.52.365.31.13.7.13m6.862-2.51q.369 0 .645-.09.275-.095.454-.265.185-.175.276-.42.09-.25.09-.555 0-.29-.09-.525t-.27-.4a1.2 1.2 0 0 0-.456-.25q-.275-.09-.65-.09h-1.005v2.595zm0-3.635q.724 0 1.255.17t.875.475.51.73q.17.425.17.93 0 .525-.175.965-.175.435-.526.75-.35.315-.88.49-.525.175-1.23.175h-1.005V14h-1.344V6.77zm5.19 7.23h-1.35V6.77h1.35z"
      fill={stroke}
    />
    <rect x={0.568} y={0.5} width={26.164} height={19} rx={3.5} stroke={stroke} />
  </svg>
);
export default ClipboardIcon;
