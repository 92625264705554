import styled from 'styled-components';

export const ConfirmationModalContent = styled.div`
  padding: 72px;
  display: flex;
  flex-direction: column;

  gap: 48px;
`;

export const ConfirmationModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductInfoWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px #0000001c;
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 24px;
  border-radius: 21px;
`;

export const ProductInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 24px;
`;

export const ProductInfoTextContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProductInfoTextContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px #0000001c;
  height: 165px;
  width: 110px;
  border-radius: 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;
