import React from 'react';
import { Button } from '@point-of-sale/components';
import { OperationType } from '../types';
import { ObjectType } from '@point-of-sale/types';
import { useAppSelector } from '@point-of-sale/store';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface IOperationSwitchProps {
  operation: OperationType;
  setOperation: React.Dispatch<React.SetStateAction<OperationType>>;
  setReasons: React.Dispatch<React.SetStateAction<ObjectType>>;
  setLineItemsSelection: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
}

const OperationSwitch = ({
  operation,
  setOperation,
  setReasons,
  setLineItemsSelection,
}: IOperationSwitchProps) => {
  const selectedSalesChannelFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );
  const isSelectedSalesChannelOnline = useAppSelector(store =>
    selectedSalesChannelFacilityId
      ? store.common.facilities.data?.records?.[selectedSalesChannelFacilityId]?.isOnline
      : false
  );

  if (isSelectedSalesChannelOnline) {
    return null;
  }

  return (
    <CommonStyles.LeftTop $doesTopHaveBorder={false}>
      <Styles.OperationSwitchContainer>
        <Button
          sizeVariant="small"
          onClick={() => {
            if (operation === 'RETURNS_AND_EXCHANGES') {
              setOperation('READ_ONLY');
            } else {
              setOperation('RETURNS_AND_EXCHANGES');
            }

            setReasons({});
            setLineItemsSelection({});
          }}
          colorScheme="primary"
          variant={operation === 'RETURNS_AND_EXCHANGES' ? 'fill' : 'outline'}
        >
          Return & Exchange
        </Button>
        <Button
          sizeVariant="small"
          onClick={() => {
            if (operation === 'CANCELLATION') {
              setOperation('READ_ONLY');
            } else {
              setOperation('CANCELLATION');
            }

            setReasons({});
            setLineItemsSelection({});
          }}
          colorScheme="primary"
          variant={operation === 'CANCELLATION' ? 'fill' : 'outline'}
        >
          Cancellation
        </Button>
      </Styles.OperationSwitchContainer>
    </CommonStyles.LeftTop>
  );
};

export default OperationSwitch;
