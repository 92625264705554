import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;

  .cash-input {
    width: 70%;
  }

  button {
    width: 30% !important ;
  }
`;
