import { IconPropsType } from '../types';

const HomeBoldIcon = (props: IconPropsType) => (
  <svg
    width={58}
    height={58}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.832 23.795c-2.417-2.231-8.862-2.973-8.862 1.488s8.862 2.23 8.862 6.69c0 4.458-7.25 4.46-9.667 1.486M29 35.529v3.138m0-16.644v-2.69m-24.167 0 23.519-11.76a1.45 1.45 0 0 1 1.295 0l23.52 11.76"
      stroke="var(--haiti)"
      strokeWidth={4.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.33 26.583v19.334a4.833 4.833 0 0 1-4.833 4.833h-29a4.833 4.833 0 0 1-4.833-4.833V26.583"
      stroke="var(--haiti)"
      strokeWidth={4.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HomeBoldIcon;
