import { NoImageIcon, Text } from '@point-of-sale/components';
import { CartItemDTOType } from '@point-of-sale/schemas';
import { updateAttributesOfCdnImage } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { IPersonalizationProps } from '../index';
import { useBoolean } from '@point-of-sale/hooks';
import * as Styles from './styles';

interface IPreviewProps {
  skuData: CartItemDTOType['productVariant'];
  mode: IPersonalizationProps['mode'];
}

const Preview = ({ skuData, mode }: IPreviewProps) => {
  const [isImageValid, imageValidActions] = useBoolean(true);

  return (
    <Styles.Wrapper>
      <Text>Preview</Text>
      <Styles.Product
        $background={mode === 'CUSTOMIZATION' ? 'var(--purple-lilac)' : 'var(--zumthor)'}
      >
        <Styles.ImageWrapper>
          {skuData.images?.[0].url && isImageValid ? (
            <img
              src={updateAttributesOfCdnImage(skuData.images?.[0].url, {
                width: 480,
                quality: 100,
              })}
              alt="Product"
              onError={() => {
                imageValidActions.off();
              }}
            />
          ) : (
            <Styles.IconWrapper>
              <NoImageIcon size={28} />
            </Styles.IconWrapper>
          )}
        </Styles.ImageWrapper>
        <Styles.Section style={{ flex: 1 }}>
          <Text fontSize={12} color="var(--dove-gray)">
            {skuData.brandName}
          </Text>
          <Text fontSize={20}>{skuData.productName}</Text>
          {skuData.components && (
            <Text fontSize={12} color="var(--dove-gray)">
              Pack Contains: {skuData.components}
            </Text>
          )}
        </Styles.Section>
        <Styles.Section>
          <Text fontSize={12} color="var(--dove-gray)">
            Color
          </Text>
          <Text fontSize={20}>{skuData.color}</Text>
        </Styles.Section>
        <Styles.Section>
          <Text fontSize={12} color="var(--dove-gray)">
            Size
          </Text>
          <Text fontSize={20}>{skuData.size}</Text>
        </Styles.Section>
        <Styles.Section style={{ flex: 1 }}>
          <Text fontSize={12} color="var(--dove-gray)" textAlign="right">
            Price
          </Text>
          <Text fontSize={20} textAlign="right">
            {CURRENCY_SYMBOLS[skuData.currency as keyof typeof CURRENCY_SYMBOLS]?.code}{' '}
            {skuData.mrp}
          </Text>
        </Styles.Section>
      </Styles.Product>
    </Styles.Wrapper>
  );
};

export default Preview;
