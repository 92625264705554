import { Input } from '@point-of-sale/components';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  min-width: 150px;
  margin-left: 8px;

  input {
    height: 26px;
  }
`;

interface IDemandedPincodeInputProps {
  demandedPincode: string;
  onChangeDemandedPincode: (pincode: string) => void;
}

const DemandedPincodeInput = ({
  demandedPincode,
  onChangeDemandedPincode,
}: IDemandedPincodeInputProps) => {
  const [value, setValue] = useState(demandedPincode);

  return (
    <StyledInput
      placeholder="Enter Pincode"
      sizeVariant="extraSmall"
      variant="outline"
      value={value}
      onChange={event => {
        setValue(event.target.value);
      }}
      onKeyUp={event => {
        if (event.key === 'Enter') {
          onChangeDemandedPincode(value);
        }
      }}
    />
  );
};

export default DemandedPincodeInput;
