import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 0.5px solid var(--alto);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 12px;
  /* min-height: 48px;

  border: 1px solid black; */
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 4px;
`;

export const ModalTriggerContainer = styled.div`
  align-self: center;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
