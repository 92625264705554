import { IconPropsType } from '../types';

const ReceiptIcon = ({ fill = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill={fill}>
      <path d="M1.42.506a.5.5 0 0 1 .434.14l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 14.5 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13l-.647-.647-.646.647a.5.5 0 0 1-.708 0l-.646-.647-.646.647a.5.5 0 0 1-.708 0l-.646-.647-.646.647a.5.5 0 0 1-.708 0l-.646-.647-.646.647a.5.5 0 0 1-.708 0l-.646-.647-.646.647a.5.5 0 0 1-.708 0l-.646-.647-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 .5 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338-.137.274v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0l-.646-.647-.646.647a.5.5 0 0 1-.708 0L9.5 1.707l-.646.647a.5.5 0 0 1-.708 0L7.5 1.707l-.646.647a.5.5 0 0 1-.708 0L5.5 1.707l-.646.647a.5.5 0 0 1-.708 0L3.5 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
      <path d="M2.5 4.5A.5.5 0 0 1 3 4h6a.5.5 0 1 1 0 1H3a.5.5 0 0 1-.5-.5m0 2A.5.5 0 0 1 3 6h6a.5.5 0 1 1 0 1H3a.5.5 0 0 1-.5-.5m0 2A.5.5 0 0 1 3 8h6a.5.5 0 1 1 0 1H3a.5.5 0 0 1-.5-.5m0 2A.5.5 0 0 1 3 10h6a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m8-6A.5.5 0 0 1 11 4h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2A.5.5 0 0 1 11 6h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2A.5.5 0 0 1 11 8h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="var(--white)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ReceiptIcon;
