import { CssVariableType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Product = styled.div<{ $background: CssVariableType }>`
  background: ${props => props.$background};
  padding: 16px;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-evenly; */
`;

export const ImageWrapper = styled.div`
  height: 90px;
  width: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 24px;
`;

export const IconWrapper = styled.div`
  border: 1px solid var(--alto);
  background: var(--white);
  height: 90px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1px;
`;
