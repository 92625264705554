const MESSAGES = [
  'Congratulations!',
  'Something went horribly wrong',
  'Well well well, What do we have here?',
  'This was not supposed to happen',
  'Houston, we have a problem',
  'Hmm, something’s fishy here',
  'I’ve got a bad feeling about this',
  'To infinity and beyond... or not',
  'I find your lack of success disturbing',
  'We’re gonna need a bigger boat... of fixes',
  'I’m sorry, Dave. I’m afraid I can’t do that',
  'It’s not a bug, it’s a feature',
  'You shall not pass... until we fix this',
  'I’ll be back... after this error is fixed',
  'I see dead links',
  'You talking to me? Something went wrong',
  'I love it when a plan falls apart',
  'This is the error screen you are looking for',
  "Mayday! Mayday! Mayday! We're going down",
];

export const getRandomMessage = () => {
  return MESSAGES[Math.floor(Math.random() * MESSAGES.length)];
};
