import { Select } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 1px;
  background: var(--white);
  /* padding: 12px; */

  display: flex;
  flex-direction: column;
  /* gap: 16px; */

  border: 1px solid var(--athens-gray);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 12px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow: auto;
`;

export const Left = styled.div`
  padding-right: 16px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */

  overflow: hidden;

  /* > * {
    user-select: none;
  } */
`;

export const SelectReturnReason = styled(Select)`
  width: 300px;
`;

export const Header = styled.div`
  background: var(--zircon);
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  gap: 12px;
`;
