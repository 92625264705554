import { Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { OrderDTOType } from '@point-of-sale/schemas';
import { PLACEHOLDERS } from '@point-of-sale/constants';
import KeyValuePair from '../KeyValuePair';
import Box from '../Box';

interface ICustomerDetailsProps {
  data: OrderDTOType;
}

const CustomerDetails = ({ data }: ICustomerDetailsProps) => {
  const addresses = useAppSelector(store => store.pointOfSale.addresses.data.records);

  const addressFromLineItems = data.lineItems?.find(
    lineItem => lineItem.customerDeliveryAddress
  )?.customerDeliveryAddress;

  let defaultAddress = Object.values(addresses).find(address => address.isDefault);

  if (!defaultAddress) {
    defaultAddress = Object.values(addresses)[0];
  }

  let addressToShow = defaultAddress;

  if (addressFromLineItems) {
    addressToShow = addressFromLineItems;
  }

  return (
    <Box header="Customer Details">
      {data.customerId ? (
        <>
          <KeyValuePair title="Name" value={data.customerName ?? PLACEHOLDERS.NOT_AVAILABLE} />
          <KeyValuePair title="Phone" value={data.customerPhone ?? PLACEHOLDERS.NOT_AVAILABLE} />
          {data.customerEmail && (
            <KeyValuePair title="Email" value={data.customerEmail ?? PLACEHOLDERS.NOT_AVAILABLE} />
          )}
          {data.customerGSTIN && (
            <KeyValuePair title="GSTIN" value={data.customerGSTIN ?? PLACEHOLDERS.NOT_AVAILABLE} />
          )}
          {addressToShow && (
            <Text weight="semibold" fontSize={14}>
              <Text weight="regular" color="var(--dove-gray)">
                Address:{' '}
              </Text>
              {addressToShow.addressLine}, {addressToShow.cityDisplayName},{' '}
              {addressToShow.stateDisplayName}, {addressToShow.pincode}
            </Text>
          )}
        </>
      ) : (
        <Text fontSize={14} color="var(--cod-gray)">
          Guest
        </Text>
      )}
    </Box>
  );
};

export default CustomerDetails;
