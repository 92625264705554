import { CSSObject } from 'styled-components';
import { StyledBaseTextProps } from './types';

export const getBaseTextStyles = ({
  $fontSize,
  $fontStyle,
  $textAlign,
  $textDecorationLine,
  $wordBreak,
  $lineHeight,
  $display = 'inline',
  $width = '100%',
  $color = 'var(--cod-gray)',
  $fontWeight = 'regular',
  ...props
}: StyledBaseTextProps): CSSObject => {
  return {
    color: $color,
    fontSize: $fontSize,
    fontStyle: $fontStyle,
    fontWeight: props.theme.typography.fonts.weight[$fontWeight],
    textAlign: $textAlign,
    wordBreak: $wordBreak,
    margin: 0,
    padding: 0,
    textDecoration: $textDecorationLine,
    textTransform: props.$textTransform,
    display: $display,
    width: $width,
    lineHeight: $lineHeight ? `${$lineHeight}px` : 'normal',
    transition: 'all 0.3s ease',
  };
};
