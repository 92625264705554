export const OMSActionsTypes = {
  SET_SEARCH: 'oms/SET_SEARCH',
  SET_ORDERS: 'oms/SET_ORDERS',

  SET_PAGINATION: 'oms/SET_PAGINATION',

  SET_ORDER_DETAILS: 'oms/SET_ORDER_DETAILS',
  SET_LINE_ITEM_DETAILS_BY_ID: 'oms/SET_LINE_ITEM_DETAILS_BY_ID',

  UPDATE_FILTER: 'oms/UPDATE_FILTER',
  DELETE_FILTER: 'oms/DELETE_FILTER',

  UPDATE_CREATED_ON_FILTER: 'oms/UPDATE_CREATED_ON_FILTER',
  DELETE_CREATED_ON_FILTER: 'oms/DELETE_CREATED_ON_FILTER',

  RESET_STATE: 'oms/RESET_STATE',
} as const;
