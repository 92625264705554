import { Button } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { openInSameTab } from '@point-of-sale/utils';

const RecoverCartButton = () => {
  const cartId = useAppSelector(state => state.pointOfSale.cart.data.id);

  return (
    <Button
      onClick={() => {
        openInSameTab(`/point-of-sale/cart?id=${cartId}`);
      }}
      isFullWidth
      colorScheme={'primary'}
    >
      Recover Cart
    </Button>
  );
};

export default RecoverCartButton;
