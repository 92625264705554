import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div<{ theme: ThemeType }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: var(--white);
  height: 50px;
  padding: 12px;

  border-radius: 8px;

  button {
    height: 50px !important;
    font-weight: ${({ theme }) => theme.typography.fonts.weight.semibold};
    text-transform: uppercase;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 420px;
  }
`;
