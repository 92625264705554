import { Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { formatNumberToLocale, removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import { CartTransactionDTOType, CustomChargeDTOType } from '@point-of-sale/schemas';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import * as Styles from './styles';

const PriceDetails = () => {
  const totalPrice = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.reduce(
      (acc, curr) => acc + state.pointOfSale.cart.data.cartItems.records[curr].totalSellingPrice,
      0
    )
  );

  const cartTransactions = useAppSelector(state => state.checkout.cartTransactions.data);
  const customCharges = useAppSelector(state => state.pointOfSale.cart.data.customCharges);

  const currency = useAppSelector(
    store => CURRENCY_SYMBOLS[store.pointOfSale.cart.data.currency as keyof typeof CURRENCY_SYMBOLS]
  );

  const chargeableAmount = useAppSelector(
    store => store.pointOfSale.cart.data.chargeableAmount ?? 0
  );
  const amountPaid = useAppSelector(store => store.pointOfSale.cart.data.amountPaid ?? 0);
  const couponDiscount = useAppSelector(store => store.pointOfSale.cart.data.couponDiscount);

  const tenderDetails = Object.values(cartTransactions.records).filter(
    (t: CartTransactionDTOType) => t.paymentGateway !== 'CHANGE_RETURN' || t.status === 'VERIFIED'
  );

  return (
    <Styles.Wrapper>
      <Styles.Top>
        <Styles.Row>
          <Text fontSize={14} color="var(--dove-gray)">
            Net Amount
          </Text>
          <Text fontSize={14} textAlign="right">
            {currency?.code} {formatNumberToLocale(totalPrice)}
          </Text>
        </Styles.Row>
        {Boolean(couponDiscount) && (
          <Styles.Row>
            <Text fontSize={14} color="var(--dove-gray)">
              Discount
            </Text>

            <Text fontSize={14} textAlign="right">
              {currency?.code} {formatNumberToLocale(couponDiscount ?? 0)}
            </Text>
          </Styles.Row>
        )}
      </Styles.Top>
      <Styles.Bottom>
        {(tenderDetails.length > 0 || customCharges?.length > 0) && (
          <Styles.Row>
            <Text fontSize={14} weight="bold">
              Tender Details
            </Text>
          </Styles.Row>
        )}
        {tenderDetails.map((t: CartTransactionDTOType) => (
          <Styles.Row key={t.id}>
            <Text fontSize={14} color="var(--dove-gray)" textTransform="capitalize">
              {t.paymentModeDisplayName} {t.paymentGateway === 'MANUAL' && '(Manual)'}
            </Text>
            <Text fontSize={14} weight="bold" textAlign="right">
              {currency.code} {formatNumberToLocale(t.amount)}
            </Text>
          </Styles.Row>
        ))}

        {customCharges?.map((c: CustomChargeDTOType[number]) => (
          <Styles.Row key={Number(c.amount) + Math.random()}>
            <Text fontSize={14} color="var(--dove-gray)" textTransform="capitalize">
              {removeUnderscoresAndCapitalize(c.type)}
            </Text>
            <Text fontSize={14} weight="bold" textAlign="right">
              {currency.code}
              {formatNumberToLocale(Number(c.amount))}
            </Text>
          </Styles.Row>
        ))}
        <Styles.Footer>
          <Text fontSize={14} weight="bold" color="var(--cod-gray)">
            Balance Amount
          </Text>
          <Text fontSize={14} weight="bold" color="var(--cod-gray)" textAlign="right">
            {formatNumberToLocale(chargeableAmount - amountPaid)}
          </Text>
        </Styles.Footer>
      </Styles.Bottom>
    </Styles.Wrapper>
  );
};

export default PriceDetails;
