import { CustomizationCategoryType } from '@point-of-sale/schemas';

import TopLabels from './assets/TopLabels';
import PantLabels from './assets/PantLabels';
import CoOrdSetLabels from './assets/CoOrdSetLabels';
import DressLabels from './assets/DressLabels';
import { IconPropsType } from '@point-of-sale/components';

const DRESS_FIELDS = [
  {
    label: 'Shoulder (inches)',
    name: 'shoulder',
    isMandatory: true,
  },
  {
    label: 'Bust (inches)',
    name: 'bust',
    isMandatory: true,
  },
  {
    label: 'Waist (inches)',
    name: 'waist',
    isMandatory: true,
  },
  {
    label: 'Armhole (inches)',
    name: 'armHole',
    isMandatory: true,
  },
  {
    label: 'Hips (inches)',
    name: 'hips',
    isMandatory: true,
  },
  {
    label: 'Sleeve length (inches)',
    name: 'sleeveLength',
    isMandatory: false,
  },
  {
    label: 'Sleeve Round (inches)',
    name: 'sleeveRound',
    isMandatory: false,
  },
  {
    label: 'Dress Length (inches)',
    name: 'dressLength',
    isMandatory: false,
  },
];

const TOP_WEAR_FIELDS = [
  {
    label: 'Bust (inches)',
    name: 'bust',
    isMandatory: true,
  },
  {
    label: 'Waist (inches)',
    name: 'waist',
    isMandatory: true,
  },
  {
    label: 'Shoulder (inches)',
    name: 'shoulder',
    isMandatory: true,
  },
  {
    label: 'Armhole (inches)',
    name: 'armHole',
    isMandatory: true,
  },
  {
    label: 'Sleeve length (inches)',
    name: 'sleeveLength',
    isMandatory: false,
  },
  {
    label: 'Sleeve Round (inches)',
    name: 'sleeveRound',
    isMandatory: false,
  },
];

const BOTTOM_WEAR_FIELDS = [
  {
    label: 'Waist (inches)',
    name: 'waist',
    isMandatory: true,
  },
  {
    label: 'Hips (inches)',
    name: 'hips',
    isMandatory: true,
  },
  {
    label: 'Thigh (inches)',
    name: 'thigh',
    isMandatory: true,
  },
  {
    label: 'Pant Length (inches)',
    name: 'pantLength',
    isMandatory: true,
  },
];

export const PERSONALIZATION_FIELDS: Record<
  string,
  {
    title: string;
    accessor: 'topwearAlterationEntry' | 'bottomwearAlterationEntry';
    fields: Array<{
      label: string;
      name: string;
      isMandatory: boolean;
    }>;
  }
> = {
  DRESS: {
    title: 'Dress',
    fields: DRESS_FIELDS,
    accessor: 'topwearAlterationEntry',
  },
  TOP_WEAR: {
    title: 'Top Wear',
    fields: TOP_WEAR_FIELDS,
    accessor: 'topwearAlterationEntry',
  },
  BOTTOM_WEAR: {
    title: 'Bottom Wear',
    fields: BOTTOM_WEAR_FIELDS,
    accessor: 'bottomwearAlterationEntry',
  },
  // CO_ORD_SET  = TOP_WEAR + BOTTOM_WEAR (handled in UI)
  // CO_ORD_SET: {
  //   title: 'Co-Ord Set',
  //   fields: CO_ORD_SET_FIELDS,
  // },
} as const;

export const DIAGRAM_IMAGE: Record<
  CustomizationCategoryType,
  (props: IconPropsType) => JSX.Element
> = {
  TOP_WEAR: TopLabels,
  BOTTOM_WEAR: PantLabels,
  CO_ORD_SET: CoOrdSetLabels,
  DRESS: DressLabels,
  NONE: () => <> </>,
} as const;
