export const INDIAN_STATES = [
  {
    countryCode: 'IN',
    code: 'IN-AN',
    iso2Code: 'AN',
    stateName: 'Andaman & Nicobar Islands',
  },
  {
    countryCode: 'IN',
    code: 'IN-AP',
    iso2Code: 'AP',
    stateName: 'Andhra Pradesh (Old)',
  },
  {
    countryCode: 'IN',
    code: 'IN-AR',
    iso2Code: 'AR',
    stateName: 'Arunachal Pradesh',
  },
  {
    countryCode: 'IN',
    code: 'IN-AS',
    iso2Code: 'AS',
    stateName: 'Assam',
  },
  {
    countryCode: 'IN',
    code: 'IN-BR',
    iso2Code: 'BR',
    stateName: 'Bihar',
  },
  {
    countryCode: 'IN',
    code: 'IN-CH',
    iso2Code: 'CH',
    stateName: 'Chandigarh',
  },
  {
    countryCode: 'IN',
    code: 'IN-CT',
    iso2Code: 'CT',
    stateName: 'Chhattisgarh',
  },
  {
    countryCode: 'IN',
    code: 'IN-DN',
    iso2Code: 'DN',
    stateName: 'Dadra & Nagar Haveli',
  },
  {
    countryCode: 'IN',
    code: 'IN-DD',
    iso2Code: 'DD',
    stateName: 'Daman & Diu',
  },
  {
    countryCode: 'IN',
    code: 'IN-DL',
    iso2Code: 'DL',
    stateName: 'Delhi',
  },
  {
    countryCode: 'IN',
    code: 'IN-GA',
    iso2Code: 'GA',
    stateName: 'Goa',
  },
  {
    countryCode: 'IN',
    code: 'IN-GJ',
    iso2Code: 'GJ',
    stateName: 'Gujarat',
  },
  {
    countryCode: 'IN',
    code: 'IN-HR',
    iso2Code: 'HR',
    stateName: 'Haryana',
  },
  {
    countryCode: 'IN',
    code: 'IN-HP',
    iso2Code: 'HP',
    stateName: 'Himachal Pradesh',
  },
  {
    countryCode: 'IN',
    code: 'IN-JK',
    iso2Code: 'JK',
    stateName: 'Jammu & Kashmir',
  },
  {
    countryCode: 'IN',
    code: 'IN-JH',
    iso2Code: 'JH',
    stateName: 'Jharkhand',
  },
  {
    countryCode: 'IN',
    code: 'IN-KA',
    iso2Code: 'KA',
    stateName: 'Karnataka',
  },
  {
    countryCode: 'IN',
    code: 'IN-KL',
    iso2Code: 'KL',
    stateName: 'Kerala',
  },
  {
    countryCode: 'IN',
    code: 'IN-LD',
    iso2Code: 'LD',
    stateName: 'Lakshadweep',
  },
  {
    countryCode: 'IN',
    code: 'IN-MP',
    iso2Code: 'MP',
    stateName: 'Madhya Pradesh',
  },
  {
    countryCode: 'IN',
    code: 'IN-MH',
    iso2Code: 'MH',
    stateName: 'Maharashtra',
  },
  {
    countryCode: 'IN',
    code: 'IN-MN',
    iso2Code: 'MN',
    stateName: 'Manipur',
  },
  {
    countryCode: 'IN',
    code: 'IN-ML',
    iso2Code: 'ML',
    stateName: 'Meghalaya',
  },
  {
    countryCode: 'IN',
    code: 'IN-MZ',
    iso2Code: 'MZ',
    stateName: 'Mizoram',
  },
  {
    countryCode: 'IN',
    code: 'IN-NL',
    iso2Code: 'NL',
    stateName: 'Nagaland',
  },
  {
    countryCode: 'IN',
    code: 'IN-OR',
    iso2Code: 'OR',
    stateName: 'Odisha',
  },
  {
    countryCode: 'IN',
    code: 'IN-PY',
    iso2Code: 'PY',
    stateName: 'Puducherry',
  },
  {
    countryCode: 'IN',
    code: 'IN-PB',
    iso2Code: 'PB',
    stateName: 'Punjab',
  },
  {
    countryCode: 'IN',
    code: 'IN-RJ',
    iso2Code: 'RJ',
    stateName: 'Rajasthan',
  },
  {
    countryCode: 'IN',
    code: 'IN-SK',
    iso2Code: 'SK',
    stateName: 'Sikkim',
  },
  {
    countryCode: 'IN',
    code: 'IN-TN',
    iso2Code: 'TN',
    stateName: 'Tamil Nadu',
  },
  {
    countryCode: 'IN',
    code: 'IN-TR',
    iso2Code: 'TR',
    stateName: 'Tripura',
  },
  {
    countryCode: 'IN',
    code: 'IN-UP',
    iso2Code: 'UP',
    stateName: 'Uttar Pradesh',
  },
  {
    countryCode: 'IN',
    code: 'IN-UT',
    iso2Code: 'UT',
    stateName: 'Uttarakhand',
  },
  {
    countryCode: 'IN',
    code: 'IN-WB',
    iso2Code: 'WB',
    stateName: 'West Bengal',
  },
  {
    countryCode: 'IN',
    code: 'IN-TL',
    iso2Code: 'TL',
    stateName: 'Telangana',
  },
  {
    countryCode: 'IN',
    code: 'IN-AD',
    iso2Code: 'AD',
    stateName: 'Andhra Pradesh',
  },
];
