import { IconPropsType } from '@point-of-sale/components';
import { FlavorType } from '../types';

interface IMagnifyingGlassProps extends IconPropsType {
  flavor: FlavorType;
}

const MagnifyingGlass = ({ flavor, ...props }: IMagnifyingGlassProps) => (
  <svg
    width={159}
    height={145}
    viewBox="0 0 159 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={78.747} cy={78.698} r={65.614} fill="#F1F3FF" />
    <circle cx={17.246} cy={34.397} r={4.113} fill="#F1F3FF" />
    <circle cx={125.481} cy={7.664} r={7.664} fill="#F1F3FF" />
    <circle cx={18.555} cy={120.947} r={7.29} fill="#F1F3FF" />
    <circle cx={149.035} cy={113.842} r={7.29} fill="#F1F3FF" />
    <circle cx={134.827} cy={122.068} r={3.552} fill="#F1F3FF" />
    <path
      d="m152.458 91.347 1.145 5.409 5.331 1.161-5.331 1.162-1.145 5.408-1.145-5.408-5.332-1.162 5.332-1.162z"
      fill="#fff"
    />
    <path
      d="M145.611 36.124c.083-.396.648-.396.731 0l.729 3.444a.38.38 0 0 0 .287.288l3.418.745c.393.085.393.645 0 .73l-3.418.745a.38.38 0 0 0-.287.288l-.729 3.444c-.083.395-.648.395-.731 0l-.729-3.444a.38.38 0 0 0-.287-.288l-3.418-.745c-.393-.085-.393-.645 0-.73l3.418-.745a.38.38 0 0 0 .287-.288zM9.852 81.36c.084-.394.648-.394.732 0l1.25 5.905c.03.144.142.257.286.288l5.843 1.273c.392.085.392.645 0 .73l-5.844 1.273a.37.37 0 0 0-.286.288l-1.25 5.905c-.083.395-.647.395-.731 0l-1.25-5.905a.37.37 0 0 0-.286-.288l-5.843-1.272c-.393-.086-.393-.646 0-.731l5.843-1.273a.37.37 0 0 0 .286-.288zm23.293-62.84c-.217-.046-.217-.355 0-.401l3.277-.697a.2.2 0 0 0 .158-.157l.706-3.254c.047-.216.354-.216.401 0l.706 3.254c.017.079.079.14.158.157l3.277.697c.217.046.217.355 0 .401l-3.277.697a.2.2 0 0 0-.158.158l-.706 3.254c-.047.215-.354.215-.401 0l-.706-3.254a.2.2 0 0 0-.157-.158z"
      fill="#C4CBF5"
    />
    <circle
      cx={92.393}
      cy={54.583}
      r={41.499}
      fill="#DDE2FF"
      stroke="#C4CBF5"
      strokeWidth={1.495}
    />
    <circle cx={92.393} cy={54.583} r={34.77} fill="#F1F3FF" stroke="#C4CBF5" strokeWidth={1.495} />
    <path
      d="m38.273 131.46 22.43-31.151.996-.748 2.243-.499h3.24l1.745 1.247 1.744.747 1.495 1.745 1.246 2.741-19.438 36.634-2.243 1.246H48.99l-3.739-1.246-2.99-1.246-2.742-3.738-1.246-1.495z"
      fill="#DDE2FF"
    />
    <path
      d="m77.65 93.58-5.686 9.22m-.997-12.211-5.483 8.224m-3.286.25-24.174 33.145m35.14-27.165-19.439 37.631M61.7 99.561l.41-.228a4.92 4.92 0 0 1 4.167-.292l.702.271.237.079a8.96 8.96 0 0 1 3.502 2.164l.772.773a5.44 5.44 0 0 1 1.418 2.478l.256.986m-18.94 36.383-1.061.478a8.63 8.63 0 0 1-5.857.439l-.715-.2-.24-.081a14.9 14.9 0 0 1-5.645-3.473l-.923-.906a6.31 6.31 0 0 1-1.649-6.232l.14-.492"
      stroke="#C4CBF5"
      strokeWidth={1.495}
    />
  </svg>
);
export default MagnifyingGlass;
