import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 12px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 32px;
  padding: 32px;
`;

export const GalleryItem = styled.div`
  border: 1px solid var(--alto);
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 142px;

  padding-bottom: 4px;

  p {
    padding: 0 4px;
  }
`;
