import { IconPropsType } from '../types';

const DiscountIcon = ({ stroke = 'var(--royal-blue)', size = 20, ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.458 13.57 13.6 6.427M.715 15.713A1.43 1.43 0 0 0 2.14 17.14h15.72a1.43 1.43 0 0 0 1.425-1.428v-2.906a2.91 2.91 0 0 1 0-5.617V4.284a1.43 1.43 0 0 0-1.425-1.429H2.14A1.43 1.43 0 0 0 .715 4.284v2.9a2.91 2.91 0 0 1 0 5.629z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.171 7.854a.714.714 0 1 0 0-1.428.714.714 0 0 0 0 1.428m5.715 5.715a.714.714 0 1 0 0-1.429.714.714 0 0 0 0 1.429"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DiscountIcon;
