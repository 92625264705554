import { addItemToCart, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { Button } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import useProductContext from '../context/useProductContext';

const AddToBag = () => {
  const { quantity, selectedVariant, fulfillmentFacilityId } = useProductContext();

  const allFacilities = useAppSelector(store => store.common.facilities.data.records);

  const dispatch = useAppDispatch();

  const [isLoading, loadingActions] = useBoolean();

  function addToCart() {
    if (!selectedVariant) {
      return;
    }

    loadingActions.on();

    if (fulfillmentFacilityId) {
      const fulfillmentFacility = allFacilities[fulfillmentFacilityId];

      dispatch(
        addItemToCart(
          {
            skuId: selectedVariant.skuId,
            skuCode: selectedVariant.skuCode,
            fulfillmentFacilityId: fulfillmentFacilityId,
            deliveryMode: 'STORE_PURCHASE',
            fulfillmentMode: 'ONHAND',
            quantity,
            fulfillmentFacilityGroupId: fulfillmentFacility.facilityGroupId,
            fulfillmentFacilityType: fulfillmentFacility.facilityType,
          },
          loadingActions.off
        )
      );
    }
  }

  return (
    <Button
      sizeVariant="medium"
      variant="fill"
      isLoading={isLoading}
      onClick={addToCart}
      isDisabled={!selectedVariant}
    >
      Add to Bag
    </Button>
  );
};

export default AddToBag;
