import { Text } from '@point-of-sale/components';
import { getInventoryStatus, useAppSelector } from '@point-of-sale/store';
import useProductContext from '../../context/useProductContext';
import BuyWithoutInventory from '../../AddOperations/BuyWithNoInventory';
import BuyFromAnotherStore from '../../AddOperations/BuyFromAnotherStore';
import PlaceMadeToOrder from '../../AddOperations/PlaceMadeToOrder';
import * as Styles from './styles';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';

interface IAvailabilityProps {
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const Availability = ({ serviceabilityData, isFetchingServiceability }: IAvailabilityProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const isSearchBarcodish = useAppSelector(store => store.pointOfSale.isBarcodish);

  const { isExpanded, selectedVariant } = useProductContext();

  const shouldShowAvailability = isSearchBarcodish && selectedVariant && !isExpanded;

  if (!shouldShowAvailability) {
    return null;
  }

  const {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
  } = getInventoryStatus(selectedVariant);

  if (hasInventoryInCurrentStoreOrWarehouse || mode === 'MADE_TO_ORDER') {
    return (
      <Styles.VariantsInfoWrapper>
        <Text
          fontSize={14}
          lineHeight={20}
          weight="semibold"
          textAlign="left"
          color="var(--cod-gray)"
        >
          {selectedVariant.color}
        </Text>
        <Text
          fontSize={14}
          lineHeight={20}
          textAlign="left"
          weight="semibold"
          color="var(--cod-gray)"
        >
          {selectedVariant.size}
        </Text>
      </Styles.VariantsInfoWrapper>
    );
  }

  if (hasInventoryInOtherStoreOrWarehouse) {
    const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];

    return (
      <Styles.Wrapper>
        <Styles.Row>
          <Text fontSize={12} color="var(--alizarin-crimson)">
            No Inventory in current store
          </Text>
          <BuyWithoutInventory />
        </Styles.Row>
        <Styles.Row>
          <Text fontSize={12}>
            Product Available at {firstFacilityWithInventory.name}, &nbsp;
            {firstFacilityWithInventory.address.cityDisplayName}
          </Text>

          <BuyFromAnotherStore
            anotherStore={firstFacilityWithInventory}
            serviceabilityData={serviceabilityData}
            isFetchingServiceability={isFetchingServiceability}
          />
        </Styles.Row>
      </Styles.Wrapper>
    );
  }

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Text fontSize={12} color="var(--alizarin-crimson)">
          No inventory in any location
        </Text>
        <BuyWithoutInventory />
      </Styles.Row>
      <Styles.Hr />
      <Styles.Row>
        <Text
          fontSize={12}
          color={
            selectedVariant?.mtoAcceptedByBrand ? 'var(--cod-gray)' : 'var(--alizarin-crimson)'
          }
        >
          {selectedVariant?.mtoAcceptedByBrand ? '' : 'Cannot'} Place Made to Order
        </Text>
        <PlaceMadeToOrder doesNotAddToCart />
      </Styles.Row>
    </Styles.Wrapper>
  );
};

export default Availability;
