import * as React from 'react';
import { IconPropsType } from '../types';
const CircleSquareIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={8.708}
      cy={8.708}
      r={5.958}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={9.168}
      y={9.167}
      width={10.083}
      height={10.083}
      rx={2}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CircleSquareIcon;
