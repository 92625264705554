import { IconPropsType } from '../types';

const MinusIcon = ({ size = 36, ...props }: IconPropsType) => (
  <svg
    fill="#000000"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M64 232h384v48H64z" />
  </svg>
);

export default MinusIcon;
