import { LineItemDTOType } from '@point-of-sale/schemas';
import { Stepper, Text } from '@point-of-sale/components';
// import BrandStatusGraph from './BrandStatusGraph';
import * as Styles from './styles';

import { getStepperItems } from './utils';

interface ILineItemStatusGraphProps {
  data: LineItemDTOType;
}

const LineItemStatusGraph = ({ data }: ILineItemStatusGraphProps) => {
  // const steps = getSteps(
  //   data.deliveryMode,
  //   data.fulfillmentMode,
  //   data.brandStatus
  // );
  // const items = getStepperItems(steps, data.status as string, data.brandStatus);

  // console.log({
  //   steps,
  //   items,
  // });

  // const shouldShowBrandStatus = data.fulfillmentMode !== 'ONHAND';

  const steps = getStepperItems(data);

  return (
    <Styles.Wrapper>
      <Text fontSize={12}>Status</Text>
      <Stepper items={steps} />

      {/* {shouldShowBrandStatus && (
        <BrandStatusGraph data={structuredClone(data)} />
      )} */}
    </Styles.Wrapper>
  );
};

export default LineItemStatusGraph;
