import { z } from 'zod';
import { LineItemDTOSchema } from '../cart';
import { CostBreakupDTOSchema } from '../common';

export const OrderDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  cartId: z.number().optional(),
  salesStaffId: z.number().optional(),
  salesChannelId: z.number().optional(),
  salesFacilityId: z.number().optional(),
  salesChannelTerminalId: z.number().optional(),
  sellerInfo: z
    .object({
      logo: z.object({
        id: z.number(),
        url: z.string(),
        uploaded: z.boolean(),
      }),
      name: z.string(),
      address: z.object({
        addressLine: z.string(),
        locality: z.string(),
        pincode: z.string(),
        cityCode: z.string(),
        cityDisplayName: z.string(),
        stateCode: z.string().optional(),
        stateDisplayName: z.string().optional(),
        countryCode: z.string(),
        countryDisplayName: z.string(),
        lat: z.number(),
        lng: z.number(),
      }),
      phone: z.string(),
      email: z.string(),
      gstin: z.string(),
      pan: z.string(),
      cin: z.string().optional(),
    })
    .optional(),
  salesChannelOrderId: z.string(),
  currency: z.string(),
  lineItems: z.array(LineItemDTOSchema),
  costBreakup: CostBreakupDTOSchema,

  cancelled: z.boolean(),

  purchaseReceipt: z
    .object({
      id: z.number(),
      url: z.string(),
      uploaded: z.boolean(),
    })
    .optional(),

  customerId: z.number().optional(),
  customerName: z.string().optional(),
  customerPhone: z.string().optional(),
  customerEmail: z.string().optional(),
  customerGSTIN: z.string().optional(),
  customerBillingAddress: z.object({}).optional(),

  invoiceNumber: z.string().optional(),
  advanceVoucherNumber: z.string().optional(),

  salesChannelType: z.enum(['SHOPIFY', 'POS']),
  salesChannelOrderMetadata: z.string().optional(),
  salesFacilityGroupId: z.number().optional(),

  couponCode: z.string().optional(),
});

export type OrderDTOType = z.infer<typeof OrderDTOSchema>;

export const OrdersDTOSchema = z.array(OrderDTOSchema);

export type OrdersDTOType = z.infer<typeof OrdersDTOSchema>;

export const RefundModeEnumSchema = z.enum([
  'STORE_CREDIT',
  'BANK_ACCOUNT',
  'UPI',
  'CASH',
  'SOURCE',
]);

export type RefundModeType = z.infer<typeof RefundModeEnumSchema>;

export const BulkCancelOrderItemDTOSchema = z.object({
  ids: z.array(z.number()),
  cancellationReason: z.string(),
  refundMode: RefundModeEnumSchema.optional(),

  upiHandle: z.string().optional(),
  bankAccount: z.object({
    accountNumber: z.string(),
    ifsc: z.string(),
    bankName: z.string(),
    accountName: z.string(),
  }),
});

export type BulkCancelOrderItemDTOType = z.infer<typeof BulkCancelOrderItemDTOSchema>;

export const BulkReturnOrderItemDTOSchema = z.object({
  ids: z.array(z.number()),
  returnReasons: z.array(z.string()),
  returnMode: z.enum(['AT_STORE']),
  refundMode: RefundModeEnumSchema,
  upiHandle: z.string().optional(),

  bankAccount: z.object({
    accountNumber: z.string(),
    ifsc: z.string(),
    bankName: z.string(),
    accountName: z.string(),
  }),
});

export type BulkReturnOrderItemDTOType = z.infer<typeof BulkReturnOrderItemDTOSchema>;

export const UpiMetaDataDTOSchema = z.object({
  BatchNumber: z.string().optional(),
  Amount: z.string().optional(),
  MID: z.string().optional(),
  AmountInPaisa: z.string().optional(),
  FinalAmount: z.string().optional(),
  TransactionLogId: z.string().optional(),
  OriginalAmount: z.string().optional(),
  TID: z.string().optional(),
  ApprovalCode: z.string().optional(),
  'Acquirer Id': z.string().optional(),
  RRN: z.string().optional(),
  'Invoice Number': z.string().optional(),
  'Acquirer Name': z.string().optional(),
  'Customer VPA': z.string().optional(),
  'Transaction Time': z.string().optional(),
  'Transaction Date': z.string().optional(),
  PaymentMode: z.string().optional(),
});

export type UpiMetaDataDTOType = z.infer<typeof UpiMetaDataDTOSchema>;

export const CardMetaDataDTOSchema = z.object({
  BatchNumber: z.string().optional(),
  Amount: z.string().optional(),
  MID: z.string().optional(),
  AmountInPaisa: z.string().optional(),
  FinalAmount: z.string().optional(),
  TransactionLogId: z.string().optional(),
  OriginalAmount: z.string().optional(),
  TID: z.string().optional(),
  ApprovalCode: z.string().optional(),
  'Acquirer Id': z.string().optional(),
  RRN: z.string().optional(),
  IsPartialPayByPointsTxn: z.string().optional(),
  'Card Holder Name': z.string().optional(),
  'Invoice Number': z.string().optional(),
  'Acquirer Name': z.string().optional(),
  'Expiry Date': z.string().optional(),
  'Transaction Time': z.string().optional(),
  'Card Number': z.string().optional(),
  'Card Type': z.string().optional(),
  'Transaction Date': z.string().optional(),
  PaymentMode: z.string().optional(),
  'Currency Type': z.string().optional(),
});

export type CardMetaDataDTOType = z.infer<typeof CardMetaDataDTOSchema>;
