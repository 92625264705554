import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AddressDTOType } from '@point-of-sale/schemas';
import { Button, Input, Text } from '@point-of-sale/components';
import usePinCodeDetails from '../../../../hooks/usePinCodeDetails';
import {
  addAddressToCustomer,
  assignCustomerBillingAddressToCart,
  updateAddressOfCustomer,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { concatenateWithCommas } from '@point-of-sale/utils';

import * as Styles from './styles';

type AddressPropsType = {
  customerId: number;
};

const BillingAddress = ({ customerId }: AddressPropsType) => {
  const dispatch = useAppDispatch();

  const customerBillingAddressId = useAppSelector(
    store => store.pointOfSale.cart.data.customerBillingAddressId
  );

  const customerBillingAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[customerBillingAddressId ?? -1]
  );

  const [formData, setFormData] = useState<Partial<AddressDTOType>>({});
  const [addressId, setAddressId] = useState(-1);

  const [modeOfOperation, setModeOfOperation] = useState<'READ' | 'EDIT'>('EDIT');

  const { data: pinCodeData, isFetching } = usePinCodeDetails(formData.pincode);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      pincode: formData.pincode,
      cityDisplayName: pinCodeData?.cityDisplayName,
      stateDisplayName: pinCodeData?.stateDisplayName,
      countryDisplayName: pinCodeData?.countryDisplayName,
      cityCode: pinCodeData?.cityCode,
      stateCode: pinCodeData?.stateCode,
      countryCode: pinCodeData?.countryCode,
    }));
  }, [pinCodeData]);

  useEffect(() => {
    if (customerBillingAddress) {
      setFormData({
        addressLine: customerBillingAddress.addressLine,
        cityDisplayName: customerBillingAddress.cityDisplayName,
        stateDisplayName: customerBillingAddress.stateDisplayName,
        countryDisplayName: customerBillingAddress.countryDisplayName,
        pincode: customerBillingAddress.pincode,
        name: customerBillingAddress.name,
        phone: customerBillingAddress.phone,
        label: customerBillingAddress.label,
        landmark: customerBillingAddress.landmark,
        locality: customerBillingAddress.locality,
      });
      setAddressId(customerBillingAddress ? customerBillingAddress.id ?? -1 : -1);
      setModeOfOperation('READ');
    }
  }, [customerBillingAddress]);

  return (
    <Styles.Wrapper
      $isEditMode={modeOfOperation !== 'READ'}
      layout
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0,
        opacity: 0,
      }}
      transition={{
        type: 'spring',
        stiffness: 900,
        damping: 40,
        duration: 0.3,
      }}
    >
      {modeOfOperation === 'EDIT' && (
        <Styles.EditMode $isLoading={isFetching}>
          <Styles.Row>
            <Input
              label="Pincode *"
              placeholder="ex. 110023"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.pincode}
              onChange={event => {
                setFormData({
                  ...formData,
                  pincode: event.target.value,
                });
              }}
            />
            <Input
              label="Name"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.name}
              onChange={event => {
                setFormData({
                  ...formData,
                  name: event.target.value,
                });
              }}
            />
            <Input
              label="Phone"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.phone}
              onChange={event => {
                setFormData({
                  ...formData,
                  phone: event.target.value,
                });
              }}
            />
          </Styles.Row>

          <Styles.Row>
            <Input
              label="Label"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.label}
              onChange={event => {
                setFormData({
                  ...formData,
                  label: event.target.value,
                });
              }}
            />
            <Input
              label="Landmark"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.landmark}
              onChange={event => {
                setFormData({
                  ...formData,
                  landmark: event.target.value,
                });
              }}
            />
            <Input
              label="Locality"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.locality}
              onChange={event => {
                setFormData({
                  ...formData,
                  locality: event.target.value,
                });
              }}
            />
          </Styles.Row>
          <Input
            label="Address Line *"
            sizeVariant="extraSmall"
            variant="outline"
            className="half"
            value={formData.addressLine}
            onChange={event => {
              setFormData({
                ...formData,
                addressLine: event.target.value,
              });
            }}
          />
          <Styles.Row>
            <Input
              // disabled
              label="City"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.cityDisplayName}
            />
            <Input
              disabled
              label="State"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.stateDisplayName}
            />
            <Input
              disabled
              label="Country"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.countryDisplayName}
            />
          </Styles.Row>

          <Styles.EditModeCTAs>
            <Button
              sizeVariant="xs"
              colorScheme="dark"
              variant="outline"
              onClick={() => {
                setModeOfOperation('READ');
              }}
            >
              Cancel
            </Button>

            <Button
              sizeVariant="xs"
              colorScheme="dark"
              onClick={() => {
                if (!formData.addressLine) {
                  toast.error('Address Line is required');
                  return;
                }

                if (addressId) {
                  dispatch(
                    updateAddressOfCustomer(customerId, formData, () => {
                      setModeOfOperation('READ');
                    })
                  );
                } else {
                  dispatch(
                    addAddressToCustomer(
                      customerId,
                      formData,
                      (newAddressId: number) => {
                        setModeOfOperation('READ');
                        setAddressId(newAddressId);
                        dispatch(assignCustomerBillingAddressToCart(newAddressId));
                      },
                      {
                        shouldMakeDeliveryAddress: false,
                      }
                    )
                  );
                }
              }}
            >
              Add Billing Address
            </Button>
          </Styles.EditModeCTAs>
        </Styles.EditMode>
      )}

      {modeOfOperation === 'READ' && (
        <Styles.ViewMode>
          <Styles.ViewModeContent>
            <Text fontSize={12} weight="semibold" color="var(--cod-gray)">
              {formData.label}
            </Text>
            <Text fontSize={14} color="var(--cod-gray)">
              {formData.addressLine}
            </Text>
            <Text fontSize={14}>
              {concatenateWithCommas([
                formData.cityDisplayName,
                formData.pincode,
                formData.stateDisplayName,
                formData.countryDisplayName,
              ])}
            </Text>
          </Styles.ViewModeContent>

          <Button
            sizeVariant="xs"
            colorScheme="dark"
            variant="outline"
            onClick={() => {
              setModeOfOperation('EDIT');
            }}
            leftIcon="EditIcon"
          >
            Edit
          </Button>
        </Styles.ViewMode>
      )}
    </Styles.Wrapper>
  );
};

export default BillingAddress;
