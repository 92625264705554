import { Button, Image, Modal, Text } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { swallowEvent, updateAttributesOfCdnImage } from '@point-of-sale/utils';
import * as Styles from './styles';

interface IGalleryImage {
  src?: string;
  text?: string;
  subText?: string;
}

interface IImageGalleryProps {
  images: Array<IGalleryImage>;
  heading: string;
  truncateAfter?: number;
}

const ImageGallery = ({ images, heading, truncateAfter = 1 }: IImageGalleryProps) => {
  const [isModalOpen, modalOpenActions] = useBoolean();

  const previewImages = images.slice(0, truncateAfter);

  return (
    <>
      <Styles.Wrapper>
        {previewImages.map(item => (
          <Image
            key={`${Math.floor(Math.random() * 1000)}--${item.src}`}
            height={60}
            width={44}
            src={item.src}
          />
        ))}

        {images.length > 0 && (
          <Button
            onClick={event => {
              swallowEvent(event);
              modalOpenActions.on();
            }}
            sizeVariant="xs"
            variant="outline"
            colorScheme="dark"
            {...(images.length > truncateAfter && { leftIcon: 'PlusIcon' })}
          >
            {images.length > truncateAfter ? `${images.length - truncateAfter}` : 'View All'}
          </Button>
        )}
      </Styles.Wrapper>
      <Modal
        heading={<Text fontSize={20}>{heading}</Text>}
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        size="small"
      >
        <Styles.ModalContent>
          {images.map(item => (
            <Styles.GalleryItem key={`${Math.floor(Math.random() * 1000)}--${item.src}`}>
              <Image
                height={220}
                width={140}
                src={updateAttributesOfCdnImage(item.src, {
                  quality: 100,
                  width: 700,
                })}
              />
              {item.subText && (
                <Text fontSize={10} weight="regular">
                  {item.subText}
                </Text>
              )}
              {item.text && (
                <Text fontSize={12} weight="bold">
                  {item.text}
                </Text>
              )}
            </Styles.GalleryItem>
          ))}
        </Styles.ModalContent>
      </Modal>
    </>
  );
};

export default ImageGallery;
