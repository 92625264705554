import { IDescribedState } from '@point-of-sale/types';

export const createDescribedState = <T>(data: T): IDescribedState<T> => ({
  isLoading: false,
  isError: false,
  isSuccess: true,
  error: null,
  data,
});

export const emptyFunction = () => {};
