import { IconPropsType } from '../types';

const BarCodeIcon = (props: IconPropsType) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.75 6v5a.75.75 0 1 1-1.5 0V6.75H23a.75.75 0 1 1 0-1.5h5a.75.75 0 0 1 .75.75M9 25.25H4.75V21a.75.75 0 1 0-1.5 0v5a.75.75 0 0 0 .75.75h5a.75.75 0 1 0 0-1.5m19-5a.75.75 0 0 0-.75.75v4.25H23a.75.75 0 1 0 0 1.5h5a.75.75 0 0 0 .75-.75v-5a.75.75 0 0 0-.75-.75m-24-8.5a.75.75 0 0 0 .75-.75V6.75H9a.75.75 0 0 0 0-1.5H4a.75.75 0 0 0-.75.75v5a.75.75 0 0 0 .75.75m6-1.5a.75.75 0 0 0-.75.75v10a.75.75 0 1 0 1.5 0V11a.75.75 0 0 0-.75-.75M22.75 21V11a.75.75 0 1 0-1.5 0v10a.75.75 0 1 0 1.5 0M18 10.25a.75.75 0 0 0-.75.75v10a.75.75 0 1 0 1.5 0V11a.75.75 0 0 0-.75-.75m-4 0a.75.75 0 0 0-.75.75v10a.75.75 0 1 0 1.5 0V11a.75.75 0 0 0-.75-.75"
      fill="var(--dove-gray)"
    />
  </svg>
);

export default BarCodeIcon;
