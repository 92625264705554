import { useEffect, useRef } from 'react';
import {
  Button,
  LargeGreenCheckIcon,
  LargeRedExclamationIcon,
  Modal,
  SpinnerIcon,
  Text,
} from '@point-of-sale/components';
import {
  resetCheckoutState,
  resetPointOfSaleState,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import { useNavigate } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { OrderDTOType } from '@point-of-sale/schemas';
import PrintableInvoice from '../../../pages/Checkout/PrintableInvoice';
import * as Styles from './styles';

const PaymentStatusModal = () => {
  const { isLoading, isSuccess, isError, data } = useAppSelector(
    store => store.checkout.processedOrder
  );
  const [isModalOpen, modalOpenActions] = useBoolean();

  useEffect(() => {
    if (Object.keys(data).length > 0 && !isLoading) {
      modalOpenActions.on();
    } else if (isLoading && !data) {
      modalOpenActions.on();
    }
  }, [data, isLoading]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  // TODO: change bill to data

  const invoiceData: OrderDTOType = {
    ...data,
    lineItems: data.lineItems?.filter(item => item.fulfillmentMode === 'ONHAND'),
  };

  const receiptData: OrderDTOType = {
    ...data,
    lineItems: data.lineItems?.filter(item => item.fulfillmentMode !== 'ONHAND'),
  };

  return (
    <Modal isOpen={isModalOpen} size="regular">
      <Styles.Wrapper>
        {isLoading && (
          <>
            <SpinnerIcon size={80} stroke="var(--tulip-tree)" />
            <Text fontSize={28} weight="bold" textAlign="center" color="var(--tulip-tree)">
              Your payment is being verified
            </Text>
          </>
        )}
        {isSuccess && (
          <>
            <LargeGreenCheckIcon />
            <Text fontSize={28} weight="bold" textAlign="center">
              Your Payment is successful
            </Text>
            <Text fontSize={20} textAlign="center">
              Thank you for your purchase.
            </Text>
          </>
        )}
        {isError && (
          <>
            <LargeRedExclamationIcon />
            <Text fontSize={28} weight="bold" textAlign="center">
              Your payment was not successful
            </Text>
          </>
        )}
        {isSuccess && (
          <Button
            onClick={() => {
              handlePrint();
            }}
          >
            PRINT BILL
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(resetPointOfSaleState());
            dispatch(resetCheckoutState());
            navigate('/point-of-sale/add-customer');
          }}
        >
          START A NEW BILL
        </Button>
      </Styles.Wrapper>

      {Object.keys(data).length > 0 && (
        <div ref={componentRef}>
          <PrintableInvoice data={invoiceData} type="INVOICE" />

          {receiptData.lineItems.length > 0 && (
            <PrintableInvoice data={receiptData} type="RECEIPT" />
          )}

          {/* <PrintableInvoice data={data} componentRef={componentRef} /> */}
        </div>
      )}

      {/* <PrintableInvoice data={bill} componentRef={componentRef} /> */}
    </Modal>
  );
};

export default PaymentStatusModal;
