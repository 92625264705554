import styled from 'styled-components';
import { Select } from '@point-of-sale/components';
import { COUNTRIES } from '@point-of-sale/constants';

export const StyledCountrySelector = styled(Select)`
  border: 1px solid var(--alto);
  height: 46px;
  padding: 0 8px;

  input {
    max-width: 100px;
    padding: 0;
  }
`;

interface ICountrySelectorProps {
  value: string;
  onChange: (value: string) => void;
}

const CountrySelector = ({ value, onChange }: ICountrySelectorProps) => {
  return (
    <StyledCountrySelector
      isSearchable
      sizeVariant="small"
      placeholder="Country"
      options={COUNTRIES.map(country => ({
        label: country.dial_code,
        value: country.dial_code,
      }))}
      value={value}
      onChange={selectedOption => {
        onChange(String(selectedOption.value));
      }}
      onClear={() => onChange('')}
      defaultValue={'+91'}
    />
  );
};

export default CountrySelector;
