interface IStatus {
  code: number;
  message: string;
  key: string;
  type: string;
  count: number;
}

export type APIResponseType<T> =
  | {
      status: IStatus;
      serverTime: string;
      success: true;
      data: T;
    }
  | {
      status: IStatus;
      serverTime: string;
      success: false;
    };

export enum ApiResponseEnum {
  Success,
  Failure,
  NetworkFailure,
}

interface INetworkSuccess<T> {
  type: ApiResponseEnum.Success;
  data: T;
  meta: IStatus;
}

interface INetworkFailure {
  type: ApiResponseEnum.Failure;
  meta?: IStatus;
  error?: Error;
  source: 'network' | 'response';
}

export type ApiResponseHandlerType<T> = INetworkSuccess<T> | INetworkFailure;
