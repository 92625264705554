import { Button } from '@point-of-sale/components';
import {
  updateCartItemStockKeepingUnit,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import useProductContext from '../context/useProductContext';

const UpdateCartItem = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const { selectedVariant, mannerOfPurchase, fulfillmentFacilityId, quantity } =
    useProductContext();

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  return (
    <Button
      sizeVariant="xs"
      variant="outline"
      isLoading={isLoading}
      colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      onClick={() => {
        if (selectedVariant) {
          loadingActions.on();
          dispatch(
            updateCartItemStockKeepingUnit(
              {
                customerDeliveryStoreId: mannerOfPurchase.customerDeliveryStoreId,
                deliveryMode: mannerOfPurchase.deliveryMode,
                fulfillmentFacilityId: fulfillmentFacilityId,
                fulfillmentMode: mannerOfPurchase.fulfillmentMode,
                skuCode: selectedVariant.skuCode,
                quantity: quantity,
              },
              loadingActions.off
            )
          );
        }
      }}
    >
      Update
    </Button>
  );
};

export default UpdateCartItem;
