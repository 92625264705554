/* eslint-disable @nx/enforce-module-boundaries */
import { ApiResponseEnum } from '@point-of-sale/types';
import { persistor, ThunkActionType } from '../store';
import { requestOtpApi, verifyOtpApi } from './api';
import { apiResponseHandler } from '@point-of-sale/utils';
import { SentryService } from '@point-of-sale/services';
import { resetAuthState, setAuthenticationInfo, setUser } from './actions';
import { IAuthState } from './types';
import toast from 'react-hot-toast';

export const getOtp =
  (
    email: string,
    {
      failureCallback,
      successCallback,
    }: {
      successCallback: VoidFunction;
      failureCallback: (message?: string) => void;
    }
  ): ThunkActionType =>
  async dispatch => {
    const promise = requestOtpApi(email);
    const response = await apiResponseHandler<IAuthState['user']>(promise);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      failureCallback(response.meta?.message);
      return;
    }

    dispatch(setUser(response.data));
    successCallback();
  };

export const verifyOtp =
  (
    otp: string,
    {
      failureCallback,
      successCallback,
    }: {
      successCallback: VoidFunction;
      failureCallback: (message?: string) => void;
    }
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const { id } = getState().identity.user;

    if (!id) {
      return;
    }

    const promise = verifyOtpApi({ id, otp });
    const response = await apiResponseHandler<
      Pick<IAuthState, 'token' | 'refreshToken' | 'erpUser'>
    >(promise);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      failureCallback(response.meta?.message);
      return;
    }

    if (!response.data.token) {
      return;
    }

    dispatch(setAuthenticationInfo(response.data));
    successCallback();
  };

export const logout = (): ThunkActionType => async dispatch => {
  dispatch(resetAuthState());
  SentryService.unsetUser();

  persistor.pause();
  persistor.flush().then(() => {
    window.location.reload();
    return persistor.purge();
  });
};
