import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Top = styled.div`
  /* border: 1px solid purple; */
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BottomLeft = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const BottomRight = styled.div`
  /* border: 1px solid pink; */
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ModeSwitchContainer = styled.div<{ theme: ThemeType }>`
  display: flex;
  gap: 16px;

  button {
    height: 50px;
    font-size: ${props => props.theme.typography.fonts.size[16]};
    font-weight: ${props => props.theme.typography.fonts.weight.semibold};

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const Right = styled.div``;
