import { IconPropsType } from '../types';

const LogoutIcon = ({ fill = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.367 3.01c-.018 0-.034-.01-.054-.01H9.251C8.01 3 7 3.953 7 5.125v.708c0 .391.336.709.75.709s.75-.318.75-.709v-.708c0-.39.336-.708.75-.708h3.494l-.229.072c-.607.198-1.015.739-1.015 1.344v10.625H9.25c-.414 0-.75-.318-.75-.708v-1.417c0-.39-.336-.708-.75-.708s-.75.317-.75.708v1.417c0 1.172 1.01 2.125 2.25 2.125h2.25v.708c0 .782.672 1.417 1.5 1.417.16 0 .313-.022.477-.07l4.507-1.419c.608-.198 1.016-.739 1.016-1.344V4.417c0-.826-.754-1.474-1.634-1.406"
      fill={fill}
    />
    <path
      d="m9.03 9.583-3-2.833a.78.78 0 0 0-.817-.154.71.71 0 0 0-.463.655v2.125h-3c-.414 0-.75.317-.75.708s.336.709.75.709h3v2.125a.71.71 0 0 0 .463.654.78.78 0 0 0 .817-.154l3-2.833a.68.68 0 0 0 0-1.002"
      fill={fill}
    />
  </svg>
);
export default LogoutIcon;
