import { CUSTOM_TAILORED } from '@point-of-sale/constants';
import Text from '../Typography/Text';
import * as Styles from './styles';

interface ISize {
  name: string;
  quantity: {
    store: number | null;
    warehouse: number | null;
  };
}

interface ISizeSelectorProps {
  availableSizes: Array<ISize>;
  onSelectSize: (color: ISize) => void;
  selectedSize: ISize['name'] | null;
  enabledSizes: Array<ISize['name']>;
  shouldShowCustomTailored: boolean;
  label?: string;
  shouldDisableZeroInventory?: boolean;
  boxSize?: 'COMPACT' | 'COMFORTABLE';
}

function getName(name: string): string {
  if (name === CUSTOM_TAILORED.key) {
    return CUSTOM_TAILORED.value;
  }

  return name;
}

const SizeSelector = ({
  availableSizes,
  onSelectSize,
  selectedSize,
  enabledSizes,
  shouldDisableZeroInventory,
  shouldShowCustomTailored,
  label,
  boxSize = 'COMFORTABLE',
}: ISizeSelectorProps) => {
  return (
    <Styles.Wrapper
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      {label && (
        <Text as="label" weight="regular" fontSize={16} color="var(--cod-gray)">
          {label}
        </Text>
      )}
      <Styles.SelectableWrapper>
        {availableSizes
          .filter(size => {
            if (!shouldShowCustomTailored && size.name === CUSTOM_TAILORED.key) {
              return false;
            }

            return true;
          })
          .map(size => {
            // TODO: Fix insider trading
            const hasNoInventory = size.quantity.store === 0 && size.quantity.warehouse === 0;

            const isMTC = size.name === CUSTOM_TAILORED.key && shouldShowCustomTailored;

            const isDisabled =
              !isMTC && shouldDisableZeroInventory
                ? hasNoInventory
                : enabledSizes.length > 0 && !enabledSizes.includes(size.name);

            const isVariantNotSelected =
              size.quantity.store === null && size.quantity.warehouse === null;

            const canShowInventory = !hasNoInventory && !isVariantNotSelected && !isMTC;

            const isSelected = selectedSize === size.name;

            return (
              <Styles.Selectable
                key={size.name}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }

                  onSelectSize(size);
                }}
                $isSelected={isSelected}
                $isDisabled={isDisabled}
                $size={boxSize}
              >
                <Text
                  fontSize={14}
                  weight={isSelected ? 'bold' : 'medium'}
                  color={isDisabled ? 'var(--dove-gray)' : 'var(--cod-gray)'}
                  textAlign="center"
                >
                  {getName(size.name)}
                </Text>
                {hasNoInventory && !isMTC && (
                  <Text
                    fontSize={10}
                    color="var(--geraldine)"
                    textTransform="uppercase"
                    weight="regular"
                    textAlign="center"
                  >
                    S: 0 &nbsp;|&nbsp; W: 0
                  </Text>
                )}

                {canShowInventory && (
                  <Text
                    fontSize={10}
                    weight="semibold"
                    color="var(--silver-tree)"
                    textAlign="center"
                  >
                    S: {size.quantity.store} &nbsp;|&nbsp; W: {size.quantity.warehouse}
                  </Text>
                )}
              </Styles.Selectable>
            );
          })}
      </Styles.SelectableWrapper>
    </Styles.Wrapper>
  );
};

export default SizeSelector;
