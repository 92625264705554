import { useRef } from 'react';

export const useFetchOnListEnd = (fetchNext: CallableFunction) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = (node: HTMLElement | null) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchNext();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  };

  return { lastItemRef };
};
