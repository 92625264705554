import QRCode from 'react-qr-code';
import styled from 'styled-components';
import * as Styles from './styles';

export const Wrapper = styled.div`
  padding: 8px 0;
  /* border-bottom: 2px dashed var(--alto); */
`;

const QrcodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

interface IFooterProps {
  type: 'INVOICE' | 'RECEIPT' | 'CREDIT_NOTE';
  salesChannelOrderId?: string;
  phone?: string;
}

const Footer = ({ salesChannelOrderId }: IFooterProps) => {
  const qrCodeLink = `https://track.coyu.com/order/${salesChannelOrderId}`;

  return (
    <Wrapper>
      <Styles.Text align="center" weight={700}>
        Scan here for care tips and order details
      </Styles.Text>
      {salesChannelOrderId && (
        <QrcodeWrapper>
          <QRCode value={qrCodeLink} size={128} viewBox="0 0 128 128" />
        </QrcodeWrapper>
      )}
      <br />
      <Styles.Text align="center" weight={400}>
        ORDER {salesChannelOrderId}
      </Styles.Text>

      <Styles.LineBreak />
    </Wrapper>
  );
};

export default Footer;
