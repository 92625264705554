import styled from 'styled-components';
import { ThemeType } from '../themes/types';

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  color: var(--cod-gray);
  border-radius: 1px;
  height: 30px;
`;

export const Button = styled.button`
  padding: 8px;
  border: none;
  outline: none;
  background: var(--white);
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: 1px solid var(--athens-gray);

  &:disabled {
    color: var(--dove-gray);
    cursor: not-allowed;
  }
`;

export const Value = styled.span<{ theme: ThemeType }>`
  border-top: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);
  padding: 8px 0;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${props => props.theme.typography.fonts.size[16]};
`;

export const DeleteButton = styled.button`
  border: 1px solid var(--athens-gray);
  padding: 8px 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  background: var(--white);
  outline: none;
  cursor: pointer;
`;
