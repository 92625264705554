import { IconPropsType } from '../types';

const CartCompactIcon = ({ stroke = 'var(--dove-gray)', ...props }: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={5.501}
      cy={17.416}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
      r={1.833}
    />
    <circle
      cx={15.583}
      cy={17.416}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
      r={1.833}
    />
    <path
      d="M15.585 15.583H5.5V2.75H3.668"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m5.5 4.583 12.833.917-.916 6.416H5.5"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CartCompactIcon;
