import { IconPropsType } from '../types';

const CartBoldIcon = (props: IconPropsType) => (
  <svg
    width={58}
    height={58}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.33 53.167a2.417 2.417 0 1 0 0-4.834 2.417 2.417 0 0 0 0 4.834m26.587 0a2.417 2.417 0 1 0 0-4.834 2.417 2.417 0 0 0 0 4.834M4.953 4.954h4.833l6.429 30.015a4.83 4.83 0 0 0 4.833 3.818h23.635a4.83 4.83 0 0 0 4.713-3.794l3.987-17.956h-41.01"
      stroke="var(--haiti)"
      strokeWidth={4.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CartBoldIcon;
