import { useAppSelector } from '@point-of-sale/store';
// import { getAuthToken } from 'libs/services/src/api/utils';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

interface IRouteProtectorProps {
  children: ReactNode;
}

const RouteProtector = ({ children }: IRouteProtectorProps) => {
  const token = useAppSelector(store => store.identity.token);
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RouteProtector;
