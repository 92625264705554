import { useEffect, useMemo, useState } from 'react';
import { Button, Counter, IconButton, Text } from '@point-of-sale/components';
import {
  AddressDTOType,
  AlterationEntryDTOType,
  CartItemDTOType,
  CustomChargeDTOType,
  DeliveryModeType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import {
  formatNumberToLocale,
  formatToReadableDate,
  getDiscountPercentage,
  getExpressPromiseDate,
  isEmptyObject,
  removeUnderscoresAndCapitalize,
  truncate,
} from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';
import { CURRENCY_SYMBOLS, CUSTOM_TAILORED } from '@point-of-sale/constants';
import {
  deletePersonalization,
  setIsEditCustomerInfoModalOpen,
  updateQuantityOfCart,
  useAppDispatch,
  useAppSelector,
  updateSingleCartItemDestinations,
  updateMultipleCartItemDestinations,
  getCurrentStoreAndWarehouseFacilityIds,
  setCustomerInfoModalUpdateCallback,
  store,
  RootStateType,
} from '@point-of-sale/store';
import Image from './Image';
import Personalization from '../Personalization';
import DestinationSelector from '../../DestinationSelector';
import { getStringifiedAlterationDetails } from './utils';

import { areAllValuesEmptyStrings } from '../helpers';
import ServiceCharges from '../../../pages/Checkout/Summary/ServiceCharges';
import { CartUsageType } from '../types';
import useScrollToView from './useScrollToView';
import * as Styles from './styles';

interface ICartItemProps {
  data: Omit<
    Required<CartItemDTOType>,
    | 'enabled'
    | 'alterationEntry'
    | 'customerDeliveryStoreId'
    | 'customerDeliveryAddressId'
    | 'totalShippingCharge'
    | 'totalCodCharge'
    | 'isExpress'
    | 'promiseDate'
    | 'fulfillmentFacilityType'
    | 'fulfillmentFacilityGroupId'
    | 'totalBagDiscount'
    | 'customCharges'
    | 'mtcAcceptedByBrand'
  > & {
    alterationEntry?: AlterationEntryDTOType;
    customerDeliveryStoreId?: number;
    customerDeliveryAddressId?: number | null;
    promiseDate?: string;
    isExpress?: boolean;
    customCharges?: CustomChargeDTOType;
  };
  usage?: CartUsageType;
  currency: string;
  destinationGroupIds?: Array<number>;
  serviceabilityData?: INormalizedServiceabilityData[string];
  customerAddress?: AddressDTOType;
  shouldBlockAlteration?: boolean;
}

const CartItem = ({
  data,
  currency,
  destinationGroupIds,
  serviceabilityData,
  customerAddress,
  usage = 'ADD_PRODUCTS',
  shouldBlockAlteration = false,
}: ICartItemProps) => {
  const { componentRef, controls } = useScrollToView({
    createdOn: data.createdOn,
    usage,
    fulfillmentMode: data.fulfillmentMode,
  });

  const isLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);
  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const customer = useAppSelector(store => store.pointOfSale.customer.data);

  const [promiseDate, setPromiseDate] = useState('');

  const [isPersonalizationModalOpen, personalizationModalOpenActions] = useBoolean();

  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);

  const dispatch = useAppDispatch();

  const isMadeToOrder =
    data.fulfillmentMode === 'MTO' ||
    data.fulfillmentMode === 'MTC' ||
    data.fulfillmentMode === 'JIT';

  const isPersonalizationAllowed = isMadeToOrder
    ? data.productVariant.mtcAcceptedByBrand ?? false
    : shouldBlockAlteration
    ? false
    : Boolean(data.productVariant.customizationCategory);

  const shouldShowDestinationSelector =
    data.deliveryMode === 'STORE_PURCHASE' ? !areAllValuesEmptyStrings(data.alterationEntry) : true;

  function onChangeDestination(info: {
    customerDeliveryStoreId?: number;
    deliveryMode?: DeliveryModeType;
    promiseDate?: string;
    callback: (passThrough: boolean) => void;
  }) {
    function doAllWork() {
      if (info.promiseDate) {
        setPromiseDate(info.promiseDate);
      }

      const excessPayload: Record<string, unknown> = {};

      if (info.promiseDate === 'EXPRESS') {
        excessPayload['isExpress'] = true;
        excessPayload['promiseDate'] = getExpressPromiseDate();
      } else {
        excessPayload['isExpress'] = false;
        excessPayload['promiseDate'] = info.promiseDate;
      }

      if (info.deliveryMode === 'HOME_DELIVERY') {
        const latestSelectedAddressId = (store.getState() as RootStateType).pointOfSale
          .selectedAddressId;
        excessPayload['customerDeliveryAddressId'] = latestSelectedAddressId;
      }

      if (destinationGroupIds) {
        dispatch(
          updateMultipleCartItemDestinations({
            cartItemIds: destinationGroupIds,
            ...info,
            ...excessPayload,
          })
        );
        info.callback(true);
        return;
      }

      dispatch(
        updateSingleCartItemDestinations({
          cartItemId: data.id,
          ...info,
          ...excessPayload,
        })
      );

      info.callback(true);
    }

    if (info.deliveryMode === 'HOME_DELIVERY' && selectedAddressId === -1) {
      dispatch(setIsEditCustomerInfoModalOpen(true));
      // info.callback(false);
      dispatch(setCustomerInfoModalUpdateCallback(() => doAllWork()));
      return;
    }

    if (info.deliveryMode === 'STORE_PICKUP' && isEmptyObject(customer)) {
      dispatch(setIsEditCustomerInfoModalOpen(true));
      // info.callback(false);

      dispatch(setCustomerInfoModalUpdateCallback(() => doAllWork()));
      return;
    }

    doAllWork();
  }

  // const { currentStoreAndWarehousesFacilityIds } =
  //   getCurrentStoreAndWarehouseFacilityIds();

  const stringifiedAlterationEntries = getStringifiedAlterationDetails(data.alterationEntry);

  const isAlterationSummaryVisible =
    data.alterationEntry && stringifiedAlterationEntries.length > 0;

  const filteredServiceabilityData = useMemo(() => {
    if (data.fulfillmentMode === 'MTC' || data.fulfillmentMode === 'MTO') {
      return serviceabilityData ?? [];
    }

    const fulfillmentFacilityId =
      data.fulfillmentMode === 'ONHAND'
        ? data.fulfillmentFacilityId
        : selectedSalesChannel?.facilityId;

    return (
      serviceabilityData?.filter(
        s => String(s.from.facilityId) === String(fulfillmentFacilityId)
      ) ?? []
    );
  }, [data, serviceabilityData]);

  useEffect(() => {
    if (data.isExpress) {
      setPromiseDate('EXPRESS');
    } else {
      setPromiseDate(formatToReadableDate(new Date(data.promiseDate ?? '')));
    }
  }, [data.promiseDate, data.isExpress]);

  const isEditMode = usage === 'ADD_PRODUCTS';

  function getDefaultTat(): number {
    if (data.fulfillmentMode === 'MTC') {
      return data.productVariant.mtcTAT ? data.productVariant.mtcTAT + 1 : 6;
    }
    if (data.fulfillmentMode === 'MTO') {
      return data.productVariant.mtoTAT ? data.productVariant.mtoTAT + 1 : 6;
    }

    return 3;
  }

  const currencySymbol =
    CURRENCY_SYMBOLS[(currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  const discount = getDiscountPercentage(data.productVariant.mrp, data.totalSellingPrice);

  return (
    <Styles.Wrapper $isLoading={isLoading} ref={componentRef} animate={controls}>
      <Image
        src={data.productVariant.images?.[0]?.url}
        label={removeUnderscoresAndCapitalize(data.deliveryMode)}
        isMTO={data.fulfillmentMode === 'MTO'}
        isMTC={data.fulfillmentMode === 'MTC'}
      />
      <Styles.Center>
        <Styles.Title>
          <Text fontSize={14} color="var(--cod-gray)" lineHeight={20}>
            {data.productVariant.productName}
          </Text>
          <Styles.PriceWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                height: '100%',
              }}
            >
              {discount > 0 && (
                <Text as="span" fontSize={10} weight="semibold" color="var(--persian-red)">
                  {`${discount}% off`}
                  &nbsp;&nbsp;
                  <Text
                    as="span"
                    fontSize={12}
                    color="var(--alto)"
                    textDecorationLine="line-through"
                  >
                    {currencySymbol}
                    &nbsp;
                    {formatNumberToLocale(data.totalSellingPrice)}
                  </Text>
                </Text>
              )}
              <Text fontSize={16} weight="bold" color="var(--cod-gray)" textAlign="right">
                {currencySymbol} {formatNumberToLocale(data.chargeableAmount)}
              </Text>
            </div>
            {isEditMode && (
              <Counter
                value={data.quantity}
                min={0}
                onDecrement={value => {
                  dispatch(
                    updateQuantityOfCart({
                      cartItemId: data.id,
                      quantity: value,
                    })
                  );
                }}
                onIncrement={value => {
                  dispatch(
                    updateQuantityOfCart({
                      cartItemId: data.id,
                      quantity: value,
                    })
                  );
                }}
              />
            )}
          </Styles.PriceWrapper>
        </Styles.Title>

        <Styles.TextPair fontSize={14} color="var(--dove-gray)" weight="semibold">
          Size:{' '}
          <b>
            {data.productVariant.size === CUSTOM_TAILORED.key
              ? CUSTOM_TAILORED.value
              : data.productVariant.size}
          </b>
        </Styles.TextPair>
        <Styles.TextPair fontSize={14} color="var(--dove-gray)" weight="semibold">
          Color: <b>{data.productVariant.color}</b>
        </Styles.TextPair>
        {usage === 'CHECKOUT' && (
          <Styles.TextPair fontSize={14} color="var(--dove-gray)" weight="semibold">
            Quantity: <b>{data.quantity}</b>
          </Styles.TextPair>
        )}

        {data.deliveryMode !== 'STORE_PURCHASE' && (
          <Styles.TextPair fontSize={14} color="var(--dove-gray)" weight="semibold">
            Est delivery: {promiseDate}
          </Styles.TextPair>
        )}

        {isEditMode && serviceabilityData && (
          <Styles.Actionables>
            {shouldShowDestinationSelector && (
              <Styles.ActionablesLeft>
                <Text fontSize={14} color="var(--dove-gray)" weight="semibold">
                  Delivery Location:
                </Text>
                <DestinationSelector
                  isMTC={data.fulfillmentMode === 'MTC'}
                  isMTO={data.fulfillmentMode === 'MTO'}
                  defaultTat={getDefaultTat()}
                  serviceabilityData={filteredServiceabilityData}
                  isLoading={isLoading}
                  columns={['deliveryDate']}
                  variant={data.productVariant}
                  fulfillmentMode={data.fulfillmentMode}
                  fulfillmentFacilityId={data.fulfillmentFacilityId}
                  onChange={onChangeDestination}
                  deliveryMode={data.deliveryMode}
                  customerDeliveryStoreId={data.customerDeliveryStoreId}
                  customerAddress={customerAddress}
                  // shouldShowOtherFacilities={
                  //   !currentStoreAndWarehousesFacilityIds.includes(
                  //     data.fulfillmentFacilityId
                  //   )
                  // }
                />
              </Styles.ActionablesLeft>
            )}

            <Styles.ActionablesRight>
              {isPersonalizationAllowed && !isAlterationSummaryVisible && (
                <Button
                  onClick={personalizationModalOpenActions.on}
                  sizeVariant="xs"
                  colorScheme={isMadeToOrder ? 'secondary' : 'primary'}
                >
                  Add &nbsp;
                  {isMadeToOrder ? 'Customization' : 'Alteration'}
                </Button>
              )}
              {/* <Button
                onClick={() => {
                  if (['MTO', 'MTC'].includes(data.fulfillmentMode)) {
                    dispatch(setMode('MADE_TO_ORDER'));
                  }

                  if (data.fulfillmentMode === 'ONHAND') {
                    dispatch(setMode('BUY_NOW'));
                  }
                  dispatch(
                    updateCartItemEditCache({
                      id: data.id,
                      isPopulated: true,
                    })
                  );
                  dispatch(
                    getProductBySkuCode({
                      skuCode: data.skuCode,
                    })
                  );
                }}
                sizeVariant="xs"
                colorScheme={isMadeToOrder ? 'secondary' : 'primary'}
                variant="outline"
              >
                Edit
              </Button> */}
            </Styles.ActionablesRight>
          </Styles.Actionables>
        )}

        {isAlterationSummaryVisible && (
          <Styles.PersonalizationSummary>
            <Text fontSize={12}>{truncate(stringifiedAlterationEntries, 50)}</Text>

            {isEditMode && (
              <>
                <IconButton
                  onClick={personalizationModalOpenActions.on}
                  icon="EditIcon"
                  sizeVariant="small"
                />
                <IconButton
                  onClick={() => {
                    if (data.alterationEntry) {
                      dispatch(
                        deletePersonalization({
                          itemId: data.id,
                          alterationEntry: data.alterationEntry,
                        })
                      );
                    }
                  }}
                  icon="DeleteIcon"
                  sizeVariant="small"
                  shapeVariant="square"
                />
              </>
            )}
          </Styles.PersonalizationSummary>
        )}

        {usage === 'CHECKOUT' && <ServiceCharges level="ITEM" cartItem={data} />}
      </Styles.Center>

      {isPersonalizationAllowed && data.productVariant.customizationCategory && (
        <Personalization
          itemId={data.id}
          mode={isMadeToOrder ? 'CUSTOMIZATION' : 'ALTERATION'}
          isOpen={isPersonalizationModalOpen}
          onClose={personalizationModalOpenActions.off}
          customizationCategory={data.productVariant.customizationCategory}
          skuData={data.productVariant}
          alterationEntry={data.alterationEntry}
          serviceabilityData={filteredServiceabilityData}
          cartItemData={data}
        />
      )}
    </Styles.Wrapper>
  );
};

export default CartItem;
