import toast from 'react-hot-toast';
import { z } from 'zod';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, updateArrayByKey } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import {
  BulkCancelOrderItemDTOType,
  BulkReturnOrderItemDTOType,
  LineItemDTOSchema,
  LineItemDTOType,
  OrderDTOSchema,
  OrderDTOType,
  OrdersDTOSchema,
  OrdersDTOType,
} from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { normalizeArray } from '../utils/normalizeArray';
import { searchCustomer } from '../pointOfSale/thunks';
import { ThunkActionType } from '../store';
import { setOrders, setOrdersPagination, setOrderDetails } from './actions';
import {
  addRefundReceiptToOrderApi,
  bulkCancelOrderItemsApi,
  bulkReturnOrderItemsApi,
  getOrdersApi,
  getOrdersDetailsByIdApi,
  upsertSalesStaffInOrderApi,
} from './api';

export const getOrders = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().oms.pagination;
  const search = getState().oms.search;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setOrders({
      isLoading: true,
    })
  );

  const filters = getState().oms.filters;
  const createdOn = getState().oms.createdOn;

  const filterBuilder = new FilterBuilder();

  // filterBuilder.addFilter({
  //   field: 'salesChannelId',
  //   filterType: 'EQ',
  //   value: getState().identity?.selectedSalesChannel?.id ?? '',
  // });

  if (createdOn.from) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'GTE',
      value: createdOn.from,
    });
  }

  if (createdOn.to) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'LTE',
      value: createdOn.to,
    });
  }

  if (search.query.length > 0) {
    if (!Number.isNaN(Number(search.query))) {
      if (search.query.length === 10) {
        filterBuilder.addFilter({
          field: 'customerPhone',
          filterType: 'ICONTAINS',
          value: search.query,
        });
      }

      if (search.query.length > 10) {
        filterBuilder.addFilter({
          field: 'salesChannelOrderId',
          filterType: 'ICONTAINS',
          value: search.query,
        });
      }
    } else {
      filterBuilder.addFilter({
        field: 'customerName',
        filterType: 'ICONTAINS',
        value: search.query,
      });
    }
  }

  const newPage = page + 1;

  const payload = {
    page: newPage,
    size: size,
    filters: [...filterBuilder.build(), ...Object.values(filters)],
  };

  const promise = getOrdersApi(payload);

  const response = await apiResponseHandler<OrdersDTOType>(promise, OrdersDTOSchema);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setOrdersPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setOrders({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage > 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().oms.orders.data.ids, ...ids];
    records = { ...getState().oms.orders.data.records, ...records };
  }

  dispatch(
    setOrders({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setOrdersPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );
};

export const getOrderDetailsById =
  (id: string): ThunkActionType =>
  async dispatch => {
    dispatch(
      setOrderDetails({
        isLoading: true,
      })
    );

    const promise = getOrdersDetailsByIdApi(id);

    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOrderDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    if (response.data.customerId) {
      dispatch(
        searchCustomer({ customerId: String(response.data.customerId) }, () => {
          //
        })
      );
    }

    dispatch(
      setOrderDetails({
        data: response.data,
        isLoading: false,
        isSuccess: true,
      })
    );
  };

export const bulkCancelOrderItems =
  (
    data: BulkCancelOrderItemDTOType,
    setPdfData: (pdfData: Array<LineItemDTOType>) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = bulkCancelOrderItemsApi(data);

    const response = await apiResponseHandler<Array<LineItemDTOType>>(
      promise,
      z.array(LineItemDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
      // return response;
    }

    const orderDetails = getState().oms.details.data;

    dispatch(
      setOrderDetails({
        data: {
          ...orderDetails,
          lineItems: updateArrayByKey(orderDetails.lineItems, response.data, 'id'),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    setPdfData(response.data);
  };

export const bulkReturnOrderItems =
  (
    data: BulkReturnOrderItemDTOType,
    setPdfData: (pdfData: Array<LineItemDTOType>) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = bulkReturnOrderItemsApi(data);

    const response = await apiResponseHandler<Array<LineItemDTOType>>(
      promise,
      z.array(LineItemDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
    }

    const orderDetails = getState().oms.details.data;

    dispatch(
      setOrderDetails({
        data: {
          ...orderDetails,
          lineItems: updateArrayByKey(orderDetails.lineItems, response.data, 'id'),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    setPdfData(response.data);
  };

export const addRefundReceiptToOrder =
  (
    refundId: number,
    asset: {
      id: number;
      url: string;
    },
    callback: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = addRefundReceiptToOrderApi({
      refundId,
      asset,
    });

    const response = await apiResponseHandler(promise);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
    }

    // dispatch(setProcessedOrder({ data: response.data }));

    console.log({ response });

    callback();
  };

export const upsertSalesStaffInOrder =
  (orderId: number, salesStaffId: number): ThunkActionType =>
  async dispatch => {
    dispatch(
      setOrderDetails({
        isLoading: true,
      })
    );

    const promise = upsertSalesStaffInOrderApi({
      orderId,
      salesStaffId,
    });

    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOrderDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    // if (response.data.customerId) {
    //   dispatch(
    //     searchCustomer({ customerId: String(response.data.customerId) }, () => {
    //       //
    //     })
    //   );
    // }

    dispatch(
      setOrderDetails({
        data: response.data,
        isLoading: false,
        isSuccess: true,
      })
    );
  };
