import { IconPropsType } from '../types';

const LargeGreenCheckIcon = ({ stroke = 'var(--hippie-green)', ...props }: IconPropsType) => (
  <svg
    width={126}
    height={126}
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={63} cy={63} r={62} stroke={stroke} strokeWidth={2} />
    <path
      d="m31.866 60.068 21.9 21.9 41.367-41.366"
      stroke={stroke}
      strokeWidth={3}
      strokeLinecap="square"
    />
  </svg>
);

export default LargeGreenCheckIcon;
