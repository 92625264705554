import {
  DashboardIcon,
  PointOfSaleIcon,
  CartCompactIcon,
  StarIcon,
  SearchIcon,
  ReturnsIcon,
} from '@point-of-sale/components';

export const NAVIGATION_ITEMS = [
  {
    section: 'Main Menu',
    visitables: [
      {
        title: 'Home',
        link: '/',
        icon: DashboardIcon,
      },
      {
        title: 'Point of sale',
        link: '/point-of-sale',
        icon: PointOfSaleIcon,
      },
      {
        title: 'Inventory search',
        link: '/products',
        icon: SearchIcon,
      },
      {
        title: 'Open carts',
        link: '/open-carts',
        icon: CartCompactIcon,
      },
      {
        title: 'Orders management',
        link: '/orders',
        icon: ReturnsIcon,
      },
      {
        title: 'Pending tasks',
        link: '/pending-tasks',
        icon: StarIcon,
      },
    ],
  },
  // {
  //   section: 'Admin',
  //   visitables: [ReturnsIcon
  //     {
  //       title: 'Back Office',
  //       link: '/back-office',
  //       icon: UserCircleIcon,
  //     },
  //     {
  //       title: 'Settings',
  //       link: '/settings',
  //       icon: SettingsIcon,
  //     },
  //   ],
  // },
] as const;
