import { useCallback, useEffect, useRef, useState } from 'react';
import OTPInput from 'react-otp-input';
import toast from 'react-hot-toast';
import { Button, Text } from '@point-of-sale/components';
import { getOtp, useAppDispatch, verifyOtp } from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import * as Styles from './styles';

const RESEND_COUNTER_TIME = 30;

interface IInputOtpProps {
  setStep: (step: number) => void;
  email: string;
}

const InputOtp = ({ setStep, email }: IInputOtpProps) => {
  const [isLoading, loadingActions] = useBoolean();
  const [isVerifyingOtp, verifyingOtpActions] = useBoolean();

  const [otp, setOtp] = useState('');

  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  const [counter, setCounter] = useState(RESEND_COUNTER_TIME);
  // const [isResendVisible, resendVisibleActions] = useBoolean();

  const rafId = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  const startCounter = useCallback(() => {
    setCounter(RESEND_COUNTER_TIME);
    startTimeRef.current = null;

    const tick = (timestamp: number) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsed = (timestamp - startTimeRef.current) / 1000;
      const remainingTime = Math.max(RESEND_COUNTER_TIME - Math.floor(elapsed), 0);

      setCounter(remainingTime);

      if (remainingTime > 0) {
        rafId.current = requestAnimationFrame(tick);
      }
    };

    rafId.current = requestAnimationFrame(tick);
  }, []);

  useEffect(() => {
    startCounter();
    return () => {
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [startCounter]);

  const handleResend = () => {
    loadingActions.on();
    dispatch(
      getOtp(email, {
        successCallback: () => {
          loadingActions.off();
          if (rafId.current) {
            cancelAnimationFrame(rafId.current);
          }
          startCounter();
        },
        failureCallback: (message?: string) => {
          loadingActions.off();
          toast.error('Failed to get OTP');

          if (message) {
            setError(`Cannot Proceed; Reason: ${message}`);
          }
        },
      })
    );
  };

  return (
    <Styles.FormWrapper>
      <Text>Enter Your OTP</Text>

      <Styles.OTPInputWrapper>
        <OTPInput
          inputType="number"
          value={otp}
          onChange={value => setOtp(value)}
          renderInput={props => <Styles.StyledOtpInput {...props} />}
          shouldAutoFocus
        />
      </Styles.OTPInputWrapper>
      {error && (
        <Text color="var(--alizarin-crimson)" wordBreak="break-all">
          {error}
        </Text>
      )}

      <Button
        variant="ghost"
        sizeVariant="xs"
        isDisabled={counter > 0}
        isLoading={isLoading}
        onClick={() => {
          handleResend();
        }}
      >
        {counter > 0 ? `Resend OTP in ${counter} seconds` : 'Resend OTP'}
      </Button>

      <Styles.ButtonGroup>
        <Button
          colorScheme="dark"
          variant="outline"
          isFullWidth
          onClick={() => {
            setStep(1);
          }}
        >
          Back
        </Button>
        <Button
          colorScheme="dark"
          isFullWidth
          isLoading={isVerifyingOtp}
          isDisabled={otp.length < 4}
          onClick={() => {
            if (otp.length < 4) {
              return;
            }

            verifyingOtpActions.on();

            function successCallback() {
              verifyingOtpActions.off();
              setStep(3);
            }

            dispatch(
              verifyOtp(otp, {
                successCallback,
                failureCallback: (message?: string) => {
                  verifyingOtpActions.off();
                  if (message) {
                    setError(`Cannot Proceed; Reason: ${message}`);
                  }
                },
              })
            );
          }}
        >
          Submit
        </Button>
      </Styles.ButtonGroup>
    </Styles.FormWrapper>
  );
};

export default InputOtp;
