import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useBoolean } from '@point-of-sale/hooks';
import ImageGallery from '../../ImageGallery';
import { ProductVariantDTOType } from '@point-of-sale/schemas';
import {
  Button,
  Capsule,
  ColorSelector,
  IconButton,
  SizeSelector,
  Text,
} from '@point-of-sale/components';
import { getVariantFilterOptions } from '../../Product/utils';
import useGetQuantityForSizeByColor from '../../Product/VariantSelection/useGetQuantityForSizeByColor';
import InventoryTable from '../InventoryTable';
import {
  setSelectedColor,
  setSelectedSize,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { copyToClipboard, formatNumberToLocale, isEmptyObject } from '@point-of-sale/utils';
import * as Styles from './styles';
import * as TableStyles from '../../Table/styles';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';

interface ITableRowProps {
  id: number;
  lastItemRef: any;
}

const TableRow = ({ id, lastItemRef }: ITableRowProps) => {
  const data = structuredClone(
    useAppSelector(store => store.pointOfSale.products.data.records[id])
  );
  const buyingWithoutInventoryAllowedForBarcode = useAppSelector(
    store => store.pointOfSale.buyingWithoutInventoryAllowedForBarcode
  );

  const [isExpanded, expandedActions] = useBoolean();

  const [selectedVariant, setSelectedVariant] = useState<ProductVariantDTOType | null>(null);

  const { getQuantityForSizeByColor } = useGetQuantityForSizeByColor(data);

  const [enabledSizes, setEnabledSizes] = useState<Array<string>>([]);

  const dispatch = useAppDispatch();

  const allOptions = data.options.sort((a, b) => b.position - a.position);
  const allColorOptions = getVariantFilterOptions(allOptions, 'COLOR');
  const allSizeOptions = getVariantFilterOptions(allOptions, 'SIZE').map((item, index) => ({
    id: index + 1,
    name: item.displayName,
    quantity: getQuantityForSizeByColor(item.displayName),
  }));

  useEffect(() => {
    if (!data.selectedSize || !data.selectedColor) {
      setSelectedVariant(null);
      return;
    }

    const variant =
      data.variants.find(
        variant => variant.size === data.selectedSize && variant.color === data.selectedColor
      ) ?? null;

    if (!variant || variant.skuCode === selectedVariant?.skuCode) {
      return;
    }

    setSelectedVariant(variant);
  }, [data.selectedColor, data.selectedSize, data.variants, selectedVariant?.skuCode]);

  useEffect(() => {
    // * this flag indicated that the user searched via barcode
    // * and the product was not found in the inventory
    if (buyingWithoutInventoryAllowedForBarcode) {
      expandedActions.on();
    }
  }, [buyingWithoutInventoryAllowedForBarcode]);

  const currencySymbol =
    CURRENCY_SYMBOLS[(selectedVariant?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']?.code;

  return (
    <>
      <TableStyles.TableRow $isSelected={false} ref={lastItemRef} $haveHoverEffect={false}>
        <TableStyles.TableData>
          <ImageGallery
            heading="Product Gallery"
            images={
              data.images?.map(item => ({
                src: item.url,
                subText: data.brand.name,
                text: data.name,
              })) ?? []
            }
          />
        </TableStyles.TableData>
        <TableStyles.TableData>
          <Text fontSize={12}>{data.brand.name}</Text>
          <br />
          <Text fontSize={14}>{data.name}</Text>
        </TableStyles.TableData>
        <TableStyles.TableData>
          {selectedVariant && (
            <Text fontSize={14}>
              {currencySymbol}
              &nbsp;
              {formatNumberToLocale(selectedVariant.mrp)}
            </Text>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>{data.components}</TableStyles.TableData>
        <TableStyles.TableData>
          {data.productType?.parent?.name && `${data.productType.parent?.name} > `}
          {data.productType?.name}
        </TableStyles.TableData>

        <TableStyles.TableData>
          {selectedVariant && (
            <>
              <Capsule
                essence="light"
                complexion={selectedVariant?.mtoAcceptedByBrand ? 'success' : 'danger'}
                size="small"
                content="MTO"
                style={{ margin: 4 }}
                iconName={selectedVariant?.mtoAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                iconProps={{ size: 24 }}
              />
              <Text textAlign="center" display="inline-block">
                {selectedVariant?.mtoAcceptedByBrand && `${selectedVariant?.mtoTAT} Days`}
              </Text>
            </>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          {selectedVariant && (
            <>
              <Capsule
                essence="light"
                complexion={selectedVariant?.mtcAcceptedByBrand ? 'success' : 'danger'}
                size="small"
                content="MTC"
                style={{ margin: 4 }}
                iconName={selectedVariant?.mtcAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                iconProps={{ size: 24 }}
              />
              <Text textAlign="center" display="inline-block">
                {selectedVariant?.mtcAcceptedByBrand && `${selectedVariant?.mtcTAT} Days`}
              </Text>
            </>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          <ColorSelector
            availableColors={allColorOptions.map(item => ({
              id: item.displayName,
              name: item.displayName,
              colorCodes: item.colorCodes ?? [],
            }))}
            enabledColors={allColorOptions.map(item => item.displayName)}
            selectedColor={data.selectedColor}
            onSelectColor={newColor => {
              const filteredSizes = data.variants
                .filter(variant => variant.color === newColor.name)
                .map(variant => variant.size);

              setEnabledSizes(filteredSizes);

              if (data.selectedSize && !filteredSizes.includes(data.selectedSize)) {
                // setSelectedSize('');

                dispatch(
                  setSelectedSize({
                    id: data.id,
                    value: null,
                  })
                );
              } else {
                // setSelectedColor(newColor.name);

                dispatch(
                  setSelectedColor({
                    id: data.id,
                    value: newColor.name,
                  })
                );
              }
            }}
          />
        </TableStyles.TableData>
        <TableStyles.TableData>
          <SizeSelector
            boxSize="COMPACT"
            shouldShowCustomTailored={false}
            availableSizes={allSizeOptions}
            enabledSizes={enabledSizes}
            selectedSize={data.selectedSize}
            onSelectSize={newSize => {
              const filteredColors = data.variants
                .filter(variant => variant.size === newSize.name)
                .map(variant => variant.color);

              if (data.selectedColor && !filteredColors.includes(data.selectedColor)) {
                // setSelectedColor('');
                dispatch(
                  setSelectedColor({
                    id: data.id,
                    value: null,
                  })
                );
              } else {
                // setSelectedSize(newSize.name);
                dispatch(
                  setSelectedSize({
                    id: data.id,
                    value: newSize.name,
                  })
                );
              }
            }}
          />
          {selectedVariant && (
            <Styles.SkuInfoRow>
              <Styles.SkuInfo>
                <Text color="var(--dove-gray)" fontSize={12} width={'fit-content'}>
                  SKU: &nbsp;
                  <Text as="span" color="var(--cod-gray)" fontSize={12} textTransform="uppercase">
                    {selectedVariant.skuCode}
                  </Text>
                </Text>
                <IconButton
                  icon="CopyIcon"
                  sizeVariant="extraSmall"
                  shapeVariant="circle"
                  onClick={() => {
                    copyToClipboard(selectedVariant.skuCode);
                  }}
                />
              </Styles.SkuInfo>
              <Styles.SkuInfo>
                <Text color="var(--dove-gray)" fontSize={12} width={'fit-content'}>
                  Barcode: &nbsp;
                  <Text as="span" color="var(--cod-gray)" fontSize={12}>
                    {selectedVariant.barcode}
                  </Text>
                </Text>
                <IconButton
                  icon="CopyIcon"
                  sizeVariant="extraSmall"
                  shapeVariant="circle"
                  onClick={() => {
                    copyToClipboard(selectedVariant.barcode);
                  }}
                />
              </Styles.SkuInfo>
            </Styles.SkuInfoRow>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          <Button
            sizeVariant="xs"
            colorScheme="dark"
            variant="outline"
            onClick={expandedActions.toggle}
            isDisabled={
              !selectedVariant?.facilityInventory ||
              isEmptyObject(selectedVariant?.facilityInventory)
            }
          >
            {isExpanded ? 'Less' : 'More'}
          </Button>
        </TableStyles.TableData>
      </TableStyles.TableRow>

      <AnimatePresence>
        {isExpanded && (
          <Styles.ExpandedRow>
            <TableStyles.TableData colSpan={8}></TableStyles.TableData>
            <InventoryTable inventory={selectedVariant?.facilityInventory} />
            <TableStyles.TableData></TableStyles.TableData>
          </Styles.ExpandedRow>
        )}
      </AnimatePresence>
    </>
  );
};

export default TableRow;
